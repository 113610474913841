import * as React from 'react';
import {StyleSheet} from 'react-native';
import {inject, observer} from 'mobx-react';
import autobind from 'autobind-decorator';
import {NewsGalleryItemModel} from 'app/api/Models';
import NewsGalleryListItem from './NewsGalleryListItem';
import InjectedApiServiceProps from 'app/api/InjectedApiServiceProps';
import {ADFlatList, ADScreen, DPPlayerScreen, DPZoomImageModal, INavigationService, InjectedNavigationServiceProps} from '@dp/react-native-core';
import {AppToolbar} from '../../../common/AppToolbar';
import {i18n} from '../../../../i18n/i18n';

type Params = { items: Array<NewsGalleryItemModel> }
type Props = Params &
    InjectedApiServiceProps &
    InjectedNavigationServiceProps

@inject('$api', '$navigation')
@observer
export default class NewsGalleryListScreen extends React.Component<Props> {
    static RouteName = 'NewsGalleryListScreen'

    static start(nav: INavigationService, params: Params) {
        nav.navigate(NewsGalleryListScreen.RouteName, params)
    }

    state = {
        fullScreenImage: undefined,
        isFullScreenImageVisible: false
    }

    render() {

        return (
            <ADScreen>
                <AppToolbar
                    theme={'light'}
                    title={i18n.strings.screens.news_gallery.title}/>
                <ADFlatList
                    data={this.props.items}
                    style={{flex: 1}}
                    ItemSeparatorComponent={null}
                    numColumns={2}
                    renderItem={this.renderItem}
                />

                <DPZoomImageModal
                    source={{uri: this.state.fullScreenImage}}
                    isVisible={this.state.isFullScreenImageVisible}
                    onVisibilityChanged={(isFullScreenImageVisible) => {
                        this.setState({
                            isFullScreenImageVisible
                        })
                    }}/>

            </ADScreen>
        )
    }

    @autobind
    protected renderItem(p: { item: NewsGalleryItemModel, index: number }) {
        return <NewsGalleryListItem item={p.item} onPress={this.handleItemPress}/>
    }

    @autobind
    protected handleItemPress(item: NewsGalleryItemModel) {
        if (item.video)
            DPPlayerScreen.start(this.props.$navigation, {
                mediaUri: item.video,
                coverUri: item.image
            });
        else if (item.image) {
            this.setState({
                fullScreenImage: item.image,
                isFullScreenImageVisible: true
            })
        }
    }
}
const styles = StyleSheet.create({})