import * as React from "react";
import { inject, observer } from "mobx-react";
import {
  ADButton,
  ADFab,
  ADFlatListLoading,
  ADImage,
  ADRemoteView,
  ADScreen,
  ADText,
  ADToast,
  Col,
  DPConfig,
  DPLinkingUtil,
  DPLocationUtil,
  DPMapMarker,
  DPMapView,
  DPScrollView,
  DPSelectUtil,
  DPStyleSheet,
  IDType,
  INavigationService,
  InjectedNavigationServiceProps,
  Row,
  Touchable,
} from "@dp/react-native-core";
import InjectedApiServiceProps from "app/api/InjectedApiServiceProps";
import {
  ImageSourcePropType,
  LayoutAnimation,
  Linking,
  Platform,
  StyleSheet,
  View,
} from "react-native";
import ProjectStore from "./ProjectStore";
import autobind from "autobind-decorator";
import TechnicalScreen from "../../technical/TechnicalScreen";
import AppToolbar from "app/screens/common/AppToolbar";
import { ProjectConfirmModal } from "./ProjectConfirmModal";
import { i18n } from "app/i18n/i18n";
import ProjectCommentsScreen from "../comments/ProjectCommentsScreen";
import TimesScreen from "../../times/TimesScreen";
import FilesScreen from "../files/FilesScreen";

export type ProjectDetailsScreenParams = {
  projectId: IDType;
};

type ProjectDetailsScreenProps = ProjectDetailsScreenParams &
  InjectedNavigationServiceProps &
  InjectedApiServiceProps;

type State = {
  showInfo: boolean;
};

@inject("$navigation", "$api")
@observer
export default class ProjectDetailsScreen extends React.Component<
  ProjectDetailsScreenProps,
  State
> {
  static RouteName = "ProjectDetailsScreen";

  static start(nav: INavigationService, param: ProjectDetailsScreenParams) {
    nav.navigate(ProjectDetailsScreen.RouteName, param);
  }

  store: ProjectStore;
  state: State = {
    showInfo: true,
  };

  protected apiReloadCount = 1;
  protected toast = React.createRef<ADToast>();

  constructor(p) {
    super(p);
    this.store = new ProjectStore(this.props);
    this.store.projectInfoApi.addListener((api) => {
      if (api.isSuccess) this.apiReloadCount++;
    });
  }

  render() {
    let data = this.store.projectInfoApi.data;
    data.location = DPLocationUtil.parse(data.location);
    data.zoom_level = data.zoom_level || "area";
    data.pin_enabled = data.pin_enabled === undefined ? true : data.pin_enabled;
    let zoom = DPSelectUtil.select(data.zoom_level, {
      street: 14,
      area: 12.2,
      city: 10,
      province: 8,
    });

    let pan = DPSelectUtil.select(data.zoom_level, {
      street: 0.007,
      area: 0.02,
      city: 0.05,
      province: 0.3,
    });

    let location =
      !!data.location && !!data.location.latitude && !!data.location.longitude
        ? {
            ...data.location,
            latitudeDelta: pan * 3,
            longitudeDelta: pan * 3,
            // latitudeDelta: pan,
            // longitudeDelta: pan,
          }
        : undefined;

    let hasFiles = false;

    if (data.advice && data.advice.sale && data.advice.tech) {
      const sale = data.advice.sale;
      const tech = data.advice.tech;
      hasFiles =
        (sale && sale.files.length > 0) || (tech && tech.files.length > 0);
    }
    return (
      <ADScreen onFocus={this.store.projectInfoApi.reload}>
        <DPScrollView
          api={this.store.projectInfoApi}
          scrollEnabled={Platform.OS !== "ios"}
        >
          <ADRemoteView
            api={this.store.projectInfoApi}
            alwaysRenderSuccess={this.store.projectInfoApi.isLoading}
          >
            <Col style={styles.container}>
              <View
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                }}
                onTouchStart={() => {
                  if (this.state.showInfo) {
                    this.setState({ showInfo: false });
                  }
                }}
                onTouchEnd={() => {
                  if (!this.state.showInfo) {
                    // force to get back to init location
                    if (Platform.OS === "ios") this.apiReloadCount++;
                    this.setState({ showInfo: true });
                  }
                }}
                // pointerEvents={'none'}
              >
                <DPMapView
                  key={"map-" + this.apiReloadCount}
                  style={{
                    flex: 1,
                  }}
                  zoom={zoom}
                  initialRegion={
                    location
                      ? ({
                          ...location,
                          latitude:
                            location.latitude -
                            (this.state.showInfo ? pan : pan / 2),
                        } as any)
                      : undefined
                  }
                >
                  {!!location && !!data.pin_enabled && (
                    <DPMapMarker
                      id={"marker"}
                      //@ts-ignore native map only
                      pinColor={DPConfig.colors.accentColor}
                      coordinate={location}
                    />
                  )}
                </DPMapView>
              </View>
              <View
                style={{
                  width: "100%",
                }}
              >
                {!!location && (
                  <Row
                    style={{
                      height: 64,
                      marginHorizontal:
                        DPConfig.dimens.screen.horizontalPadding,
                      marginTop:
                        DPConfig.dimens.toolbarHeight *
                        Platform.select({
                          default: 3,
                          ios: 1.5,
                        }),
                    }}
                  >
                    <ADFab
                      icon={"map"}
                      onPress={() => {
                        DPLinkingUtil.openMap(location as any);
                      }}
                      style={{
                        position: undefined,
                        start: undefined,
                        bottom: undefined,
                        marginHorizontal: 16,
                      }}
                    />
                    <ADFab
                      icon={{
                        icon: "comment-multiple-outline",
                        type: "MaterialCommunityIcons",
                      }}
                      color={DPConfig.colors.primaryColor}
                      style={{
                        position: undefined,
                        start: undefined,
                        bottom: undefined,
                      }}
                      onPress={this.handleCommentPress}
                    />
                    {hasFiles && (
                      <ADFab
                        icon={{
                          icon: "file-outline",
                          type: "MaterialCommunityIcons",
                        }}
                        color={DPConfig.colors.primaryColor}
                        style={{
                          position: undefined,
                          start: undefined,
                          bottom: undefined,
                          marginStart: 16,
                        }}
                        onPress={this.handleFilesPress}
                      />
                    )}
                  </Row>
                )}
                {this.renderInfoBox()}
              </View>
            </Col>
          </ADRemoteView>
        </DPScrollView>
        {this.store.projectInfoApi.isLoading && (
          <View
            style={[
              StyleSheet.absoluteFill,
              {
                elevation: DPConfig.styles.card.elevation,
                backgroundColor: "rgba(255,255,255,.85)",
              },
            ]}
          >
            <ADFlatListLoading api={this.store.projectInfoApi} />
          </View>
        )}
        <ADImage
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
            height:
              DPConfig.dimens.toolbarHeight *
              Platform.select({
                default: 3,
                ios: 1.5,
              }),
          }}
          source={{
            colors: [
              "rgba(255,255,255,.95)",
              "rgba(255,255,255,.8)",
              "rgba(255,255,255,0)",
            ],
            locations: [0.2, 0.4, 1],
          }}
        />
        <AppToolbar
          theme={"light"}
          style={{ position: "absolute", top: 0, width: "100%" }}
          title={i18n.strings.screens.project_details.title}
          color={this.store.projectInfoApi.isSuccess ? "transparent" : "#fff"}
          textColor={"#000"}
          shadow={false}
        />
        <ProjectConfirmModal store={this.store} />
        <ADToast ref={this.toast} />
      </ADScreen>
    );
  }

  @autobind
  showToast(message: string, duration?: 950) {
    message && this.toast.current && this.toast.current.show(message, duration);
  }

  @autobind
  handleTechnicalPress() {
    TechnicalScreen.start(this.props.$navigation, {
      projectId: this.props.projectId,
      onSuccess: () => {
        this.store.projectInfoApi.reload();
      },
    });
  }

  @autobind
  handleTimesPress() {
    TimesScreen.start(this.props.$navigation, {
      projectId: this.props.projectId,
      onSuccess: () => {
        this.store.projectInfoApi.reload();
      },
    });
  }

  @autobind
  handleCommentPress() {
    ProjectCommentsScreen.start(this.props.$navigation, {
      projectId: this.props.projectId,
      projectDetailsApi: this.store.projectInfoApi,
    });
  }

  @autobind
  handleFilesPress() {
    FilesScreen.start(this.props.$navigation, {
      projectId: this.props.projectId,
      projectDetailsApi: this.store.projectInfoApi,
    });
  }

  @autobind
  handleShowDetailsPress() {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    this.setState({
      showInfo: true,
    });
  }

  @autobind
  private renderInfoBox() {
    let data = this.store.projectInfoApi.data;
    return (
      <Col
        style={[styles.infoBox, !this.state.showInfo && styles.collapsedBox]}
      >
        {!this.state.showInfo && (
          <Row>
            <ADButton
              borderLess
              block
              accent
              style={styles.show_details}
              onPress={this.handleShowDetailsPress}
              title={i18n.strings.screens.project_details.show_details}
            />
          </Row>
        )}
        <Col style={!this.state.showInfo && { height: 0, flex: 0 }}>
          {!!data.project_info &&
            data.project_info.map((info, index) => (
              <Info info={info} index={index} />
            ))}
          {!!data.project_info &&
            !!data.project_info.length &&
            !!data.customer_info &&
            !!data.customer_info.length && (
              <Row>
                <View style={styles.separator} />
              </Row>
            )}
          {!!data.customer_info &&
            data.customer_info.map((info, index) => (
              <Info info={info} index={index} />
            ))}
          {!!this.store.nextState && (
            <Row style={{ marginTop: 16 }}>
              {this.store.nextState === "accepted" && (
                <ADButton
                  filled
                  block
                  accent
                  confirm
                  confirmDelay={CONFIRM_DELAY}
                  onConfirm={this.store.handleAcceptPress}
                  onConfirmCanceled={() => {
                    this.showToast(
                      i18n.strings.screens.project_details.accept.confirm
                    );
                  }}
                  confirmTitle={
                    i18n.strings.screens.project_details.accept.confirm
                  }
                  title={i18n.strings.screens.project_details.accept.title}
                />
              )}
              {this.store.nextState === "started" && (
                <ADButton
                  filled
                  block
                  primary
                  confirm
                  confirmDelay={CONFIRM_DELAY}
                  onConfirmCanceled={() => {
                    this.showToast(
                      i18n.strings.screens.project_details.start.confirm
                    );
                  }}
                  onConfirm={this.store.handleStartPress}
                  confirmTitle={
                    i18n.strings.screens.project_details.start.confirm
                  }
                  title={i18n.strings.screens.project_details.start.title}
                />
              )}
              {this.store.nextState === "technical" && (
                <ADButton
                  filled
                  block
                  color={"#468632"}
                  confirm
                  confirmDelay={CONFIRM_DELAY}
                  onConfirm={this.handleTechnicalPress}
                  onConfirmCanceled={() => {
                    this.showToast(
                      i18n.strings.screens.project_details.technical.confirm
                    );
                  }}
                  confirmTitle={
                    i18n.strings.screens.project_details.technical.confirm
                  }
                  title={i18n.strings.screens.project_details.technical.title}
                />
              )}
              {this.store.nextState === "times" && (
                <ADButton
                  filled
                  block
                  color={"#468632"}
                  confirm
                  confirmDelay={CONFIRM_DELAY}
                  onConfirm={this.handleTimesPress}
                  onConfirmCanceled={() => {
                    this.showToast(
                      i18n.strings.screens.project_details.times.confirm
                    );
                  }}
                  confirmTitle={
                    i18n.strings.screens.project_details.technical.confirm
                  }
                  title={i18n.strings.screens.project_details.times.title}
                />
              )}
              {this.store.nextState === "done" && (
                <ADButton
                  filled
                  block
                  primary
                  confirm
                  confirmDelay={CONFIRM_DELAY}
                  onConfirm={this.store.handleDonePress}
                  onConfirmCanceled={() => {
                    this.showToast(
                      i18n.strings.screens.project_details.done.confirm
                    );
                  }}
                  confirmTitle={
                    i18n.strings.screens.project_details.done.confirm
                  }
                  title={i18n.strings.screens.project_details.done.title}
                />
              )}
            </Row>
          )}
        </Col>
      </Col>
    );
  }
}

function Info(p: {
  index;
  info: {
    label: string;
    value: string;
    url?: string;
    icon?: ImageSourcePropType;
  };
}) {
  let isLink = !!p.info.url;
  return (
    <Touchable
      withoutFeedback={!isLink}
      hitSlop={{
        bottom: 8,
        top: 8,
      }}
      onPress={() => {
        p.info.url && Linking.openURL(p.info.url);
      }}
    >
      <Row key={p.index}>
        {!!p.info.icon && (
          <ADImage source={p.info.icon} style={{ marginEnd: 8 }} size={18} />
        )}
        <ADText
          style={{ marginEnd: 8 }}
          title={p.info.label}
          bold
          fontSize={14}
        />
        <ADText
          style={[
            DPConfig.styles.growWidth,
            isLink && { color: DPConfig.colors.accentColor },
          ]}
          title={p.info.value}
          fontSize={14}
        />
      </Row>
    </Touchable>
  );
}

const CONFIRM_DELAY = 1800;

const styles = DPStyleSheet.create(({ colors, dimens }) => ({
  container: {
    width: "100%",
    flex: 1,
  },
  infoBox: {
    ...DPConfig.styles.card,
    padding: dimens.screenHorizontalPadding,
    marginHorizontal: dimens.screenHorizontalPadding,
    marginTop: dimens.screenVerticalPadding,
    marginBottom: dimens.screenVerticalPadding + dimens.safeAreaInsets.bottom,
  },
  collapsedBox: {
    marginHorizontal: 0,
    marginTop: 0,
    marginBottom: 0,
    padding: 0,
  },
  separator: {
    height: 1.5,
    flex: 1,
    backgroundColor: "#9e9e9e",
    marginVertical: 16,
  },
  show_details: {
    backgroundColor: "#fff",
    borderRadius: 0,
    paddingVertical:
      DPConfig.dimens.screenVerticalPadding +
      DPConfig.dimens.safeAreaInsets.bottom,
  },
}));
