import * as React from 'react';
import {inject, Observer, observer} from 'mobx-react';
import autobind from 'autobind-decorator';
import {
    ADButton,
    ADForm,
    ADFormStore,
    ADHttpRequestFile,
    ADIcon,
    ADRequiredValidator,
    ADScreen,
    ADText,
    ADTextInput,
    ADTouchable, AlertDialog,
    Col, DPProgressBar,
    DPScrollView,
    DPStyleSheet,
    DPUploadFiles,
    INavigationService,
    InjectedNavigationServiceProps,
    InjectedPickerServiceProps,
    Row
} from '@dp/react-native-core';
import {AppToolbar} from 'app/screens/common/AppToolbar';
import {i18n} from 'app/i18n/i18n';
import InjectedApiServiceProps from 'app/api/InjectedApiServiceProps';
import PortfolioListScreen from '../list/PortfolioListScreen';


type Params = {}

type PortfolioScreenProps =
    Params &
    InjectedNavigationServiceProps &
    InjectedApiServiceProps &
    InjectedPickerServiceProps

type State = {}

@inject('$navigation', '$api', '$picker')
@observer
export default class CreatePortfolioScreen extends React.Component<PortfolioScreenProps, State> {
    static RouteName = 'PortfolioScreen';

    static start(nav: INavigationService, param?: Params) {
        nav.navigate(CreatePortfolioScreen.RouteName, param)
    }

    form = new ADFormStore<{ images: Array<ADHttpRequestFile> }>();
    tempProjectFactorInputValidationForm = new ADFormStore();
    pickerOptions = {
      // compressImageQuality: 0.4,
      compressImageMaxWidth: 720,
      compressImageMaxHeight: 720,
      includeExif: true,
    };

    render() {
        let trans = i18n.strings.screens.portfolio_add
        let hasImages = (this.form.data.get('images') || []).length > 0
        return (
            <ADForm store={this.form}>
                <ADScreen>
                    <AppToolbar theme={'dark'} title={trans.title}/>
                    <DPScrollView>
                        {!!hasImages &&
                        <DPUploadFiles
                            options={{
                                title: trans.picker.title,
                                camera: trans.picker.camera,
                                gallery: trans.picker.gallery,
                                multiple: true,
                                options: this.pickerOptions
                            }}
                            $id={'images'}
                            labelStyle={{
                                color: '#fff',
                                marginBottom: 8,
                                display: 'none'
                            }}
                            addTitle={trans.add}

                            itemProps={({file, index}) => {
                                // use just for validation as we save file info in another form
                                let captionId = 'caption-' + file.uri;

                                return {
                                    style: {
                                        backgroundColor: '#fff',
                                        borderRadius: 4,
                                        padding: 16
                                    },
                                    onRemovePress: () => {
                                        this.tempProjectFactorInputValidationForm.data.delete(captionId);
                                        this.tempProjectFactorInputValidationForm.validators.delete(captionId);
                                    },
                                    children: (
                                        <ADTextInput
                                            numberOfLines={1}
                                            onChangeText={text => {
                                                // change file name but keep file extension
                                                let parts = file.name.split('.');
                                                let ext = parts.pop();
                                                file.name = encodeURIComponent(text + '.' + ext);
                                            }}
                                            $id={captionId}
                                            $form={this.tempProjectFactorInputValidationForm}
                                            $validators={[new ADRequiredValidator()]}
                                            style={{
                                                flex: 1,
                                                marginHorizontal: 8,
                                                borderRadius: 2,
                                            }}
                                            fontSize={14}
                                            label={trans.input_label}
                                        />
                                    )
                                }
                            }}
                        />
                        }
                        {
                            !hasImages &&
                            <Col style={{flex: 1, alignItems: 'stretch'}}>
                                <ADTouchable onPress={this.openCamera} circle>
                                    <Col style={styles.pickerBtn}>
                                        <ADIcon
                                            size={72}
                                            icon={'photo-camera'}
                                        />
                                        <ADText
                                            fontSize={14}
                                            label={trans.picker.camera}/>
                                    </Col>
                                </ADTouchable>
                                <Row style={styles.separator}/>
                                <ADTouchable onPress={this.openGallery} circle>
                                    <Col style={styles.pickerBtn}>
                                        <ADIcon
                                            size={72}
                                            icon={'photo-library'}
                                        />
                                        <ADText
                                            fontSize={14}
                                            label={trans.picker.gallery}/>
                                    </Col>
                                </ADTouchable>
                            </Col>
                        }

                    </DPScrollView>
                    {
                        !!hasImages &&
                        <Row style={styles.submitRow}>
                            <ADButton
                                title={trans.save}
                                filled
                                block
                                accent
                                onSubmit={this.handleSubmit}
                            />
                        </Row>
                    }
                </ADScreen>
            </ADForm>
        )
    }

    @autobind
    openGallery() {
        this.props.$picker.showGalleryPicker({
            options: this.pickerOptions,
            multiple: true,
            onPicked: p => {
                this.form.data.set('images', p.files)
            }
        })
    }

    @autobind
    openCamera() {
        this.props.$picker.showCameraPicker({
            options: this.pickerOptions,
            multiple: true,
            onPicked: p => {
                this.form.data.set('images', p.files)
            }
        })
    }

    @autobind
    handleSubmit(data) {
        if (!this.tempProjectFactorInputValidationForm.validate()) return;

        let trans = i18n.strings.screens.portfolio_add.add_success;
        let form = new ADFormStore<{ progress: number }>();

        let loading = new AlertDialog.Builder()
            .setLoadingEnabled(true)
            .setCancelable(false)
            .setContent(
                <Observer>
                    {() => (
                        <DPProgressBar value={form.data.get('progress') as number} style={{backgroundColor: '#d1d1d1'}}/>
                    )}
                </Observer>
            )
            .small()
            .create();

        this.props.$api.addPortfolios(
            data,
            progressInfo => {
                form.setData('progress', progressInfo.percent)
            })
            .defaultError()
            .onLoading(enabled => {
                if (enabled)
                    loading.show();
                else
                    loading.hide()
            })
            .defaultLoading(false)
            .onSuccess(response => {
                this.props.$api.portfolioListApi.loadFirstPage();
                new AlertDialog.Builder()
                    .setTitle(trans.title)
                    .setMessage(response.message || trans.message)
                    .setPositiveButton(trans.ok)
                    .setOnVisibilityChanged(visible => {
                        if (!visible)
                            PortfolioListScreen.start(this.props.$navigation)
                    })
                    .setCancelable(true)
                    .create()
                    .show()
            })
            .enqueue()
    }

}


const styles = DPStyleSheet.create(({dimens}) => ({
    submitRow: {
        marginVertical: 16,
        marginHorizontal: dimens.screenHorizontalPadding,
    },
    pickerBtn: {padding: 16, justifyContent: 'center', alignItems: 'center', flex: 1},
    separator: {
        height: 1.5,
        width: '80%',
        backgroundColor: '#f1f1f1',
        alignSelf: 'center'
    }
}));
