import * as React from 'react';
import {inject, observer} from 'mobx-react';
import {
    ADButton,
    ADIcon,
    ADImage,
    ADImageSourceType,
    ADResponsiveUtil,
    ADScreen,
    ADText,
    Col,
    DPConfig,
    DPStyleSheet,
    DPSwiper,
    INavigationService,
    InjectedAuthServiceProps,
    InjectedNavigationServiceProps,
    InjectedStorageServiceProps,
    Row,
    SyncStorageService
} from '@dp/react-native-core';
import autobind from 'autobind-decorator';
import Assets from '../../assets/Assets';
import {Pagination} from 'react-native-snap-carousel';
import {LayoutAnimation, View} from 'react-native';
import LoginScreen from '../auth/login/LoginScreen';
import MainScreen from '../dashboard/main/MainScreen';
import {i18n} from '../../i18n/i18n';

type Params = {}

type IntroScreenProps =
    Params &
    InjectedAuthServiceProps &
    InjectedStorageServiceProps &
    InjectedNavigationServiceProps

type State = {
    activeIndex: number
}

type Slide = {
    title: string
    description: string
    image: ADImageSourceType
    backgroundColor: string
}


@inject('$navigation', '$auth', '$storage')
@observer
export default class IntroScreen extends React.Component<IntroScreenProps, State> {
    static RouteName = 'IntroScreen'
    private static KEY_IS_DONE = '__intro_screen_is_done__';

    static start(nav: INavigationService, param?: Params) {
        nav.navigate(IntroScreen.RouteName, param)
    }

    static resetTo(nav: INavigationService, param?: Params) {
        nav.resetTo(IntroScreen.RouteName, param)
    }

    get slides() {
        let trans = i18n.strings.screens.intro;
        return [
            {
                title: trans.slide1.title,
                description: trans.slide1.description,
                image: Assets.images.intro_step_1,
                backgroundColor: '#59b2ab',
            },
            {
                title: trans.slide2.title,
                description: trans.slide2.description,
                image: Assets.images.intro_step_2,
                backgroundColor: '#febe29',
            },
            {
                title: trans.slide3.title,
                description: trans.slide3.description,
                image: Assets.images.intro_step_3,
                backgroundColor: '#febe29',
            },
            {
                title: trans.slide4.title,
                description: trans.slide4.description,
                image: Assets.images.intro_step_4,
                backgroundColor: '#22bcb5',
            }
        ].reverse();
    }

    state = {
        activeIndex: this.slides.length - 1
    };

    static isIntroDone($storage: SyncStorageService) {
        return !!$storage.get(this.KEY_IS_DONE, false);
    }

    static showOnNextStart($storage: SyncStorageService, show = true) {
        return $storage.set(this.KEY_IS_DONE, !show);
    }

    sliderRef;

    render() {
        // if (slides.length === 0) return false;
        let trans=i18n.strings.screens.intro;
        return (
            <ADScreen style={{backgroundColor: '#fff'}}>
                <Col style={{flex: 1}}>
                    <DPSwiper
                        ref={ref => this.sliderRef = ref}
                        index={this.state.activeIndex}
                        onIndexChanged={activeIndex => {
                            LayoutAnimation.configureNext(LayoutAnimation.Presets.spring)
                            this.setState({activeIndex})
                        }}
                        style={{}}
                        controlsWrapperStyle={{
                            position: undefined
                        }}
                    >
                        {
                            this.slides.map((item, index) => {
                                return this.renderItem({index, item})
                            })
                        }
                    </DPSwiper>
                </Col>
                <Pagination
                    dotsLength={this.slides.length}
                    activeDotIndex={
                        this.state.activeIndex
                    }
                    carouselRef={this.sliderRef}
                    containerStyle={{
                        paddingVertical: 8
                    }}
                    inactiveDotStyle={styles.inactiveDot}
                    dotStyle={styles.dot}
                    inactiveDotOpacity={1}
                    inactiveDotScale={1}
                />
                <Row style={styles.buttonRow}>
                    {
                        this.state.activeIndex > 0 &&
                        <ADButton
                            style={{width: '100%', maxWidth: 500}}
                            borderLess
                            color={'#727272'}
                            title={trans.next}
                            onPress={this.handleNext}
                            before={
                                <ADIcon
                                    color={DPConfig.colors.secondaryColor}
                                    icon={'arrow-forward'}
                                />
                            }
                        />
                    }
                    {
                        this.state.activeIndex === 0 &&
                        <ADButton
                            style={{width: '100%', maxWidth: 500}}
                            accent
                            filled
                            onPress={this.handleDone}
                            title={trans.done}
                        />
                    }
                </Row>
            </ADScreen>
        )
    }

    @autobind
    renderItem({item, index}: { item: Slide, index: number }) {
        return (
            <Col key={index}
                 style={{flex: 1, alignItems: 'center', paddingBottom: 16}}>
                <Col style={{
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <ADImage
                        style={styles.background}
                        resizeMode={'cover'}
                        source={Assets.images.intro_back}
                    />

                    <ADImage
                        style={styles.logo}
                        resizeMode={'contain'}
                        source={Assets.images.logo}
                    />
                    <ADImage
                        style={styles.image}
                        resizeMode={'contain'}
                        source={item.image}
                    />
                </Col>
                <Row style={{flexWrap: 'wrap', paddingHorizontal: DPConfig.dimens.screenHorizontalPadding}}>
                    <ADText
                        bold
                        style={[DPConfig.styles.growWidth, {textAlign: 'center'}]}
                        fontSize={14}
                        title={item.title}/>
                </Row>
                <View style={styles.titleUnderline}/>
                <Row style={{flexWrap: 'wrap', paddingHorizontal: DPConfig.dimens.screenHorizontalPadding}}>
                    <ADText
                        fontSize={14}
                        style={[DPConfig.styles.growWidth, {textAlign: 'center'}]}
                        title={item.description}/>
                </Row>
            </Col>
        )
    }

    @autobind
    private handleDone() {
        this.props.$storage.set(IntroScreen.KEY_IS_DONE, true);
        this.props.$auth.isLoggedIn ?
            MainScreen.resetToDashboardNavigator(this.props.$navigation) :
            LoginScreen.resetTo(this.props.$navigation)

    }

    @autobind
    private handleNext() {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.spring)
        this.setState({
            activeIndex: this.state.activeIndex + 1
        }, () => {
            this.sliderRef &&
            this.sliderRef.moveUpDown(true)
        })
    }
}

const LOGO_SIZE = ADResponsiveUtil.width * .5;

const styles = DPStyleSheet.create(({dimens, styles, colors}) => ({
    logo: {
        width: LOGO_SIZE,
        maxHeight: LOGO_SIZE * 238 / 514,
        flex: 1,
        marginTop: dimens.toolbarHeight + dimens.screenVerticalPadding
    },
    image: {
        width: ADResponsiveUtil.width * .7,
        // height: ADResponsiveUtil.width * .85,
        flex: 2,
    },
    background: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    titleUnderline: {
        ...styles.boxShadowBottom,
        // shadowColor: colors.secondaryColor,
        // backgroundColor: colors.secondaryColor,
        width: '100%',
        borderRadius: 4,
        height: 4,
        maxWidth: 120,
        marginVertical: 8
    },
    inactiveDot: {
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: colors.accentColor,
        borderWidth: 2,
        width: 6,
        height: 6,
        borderRadius: 3,
    },
    dot: {
        ...styles.boxShadowBottom,
        // shadowColor: colors.secondaryColor,
        borderColor: 'rgba(0,0,0,0)',
        // backgroundColor: colors.secondaryColor,
        borderWidth: 0,
        borderRadius: 3,
        width: 12,
        height: 6
    },
    buttonRow: {
        paddingBottom: dimens.safeAreaInsets.bottom + dimens.screenVerticalPadding,
        paddingHorizontal: dimens.screenHorizontalPadding,
        paddingTop: 16,
        justifyContent: 'center'
    }
}));
