import {
  ADResponsiveUtil,
  ADText,
  Col,
  DPConfig,
  Row,
} from "@dp/react-native-core";
import { AdviceFile } from "app/api/Models";
import { observer } from "mobx-react";
import * as React from "react";
import { Linking, StyleSheet, TouchableOpacity, View } from "react-native";

export interface ConversationListItemProps {
  item: AdviceFile;
}

@observer
export default class FilesItem extends React.Component<
  ConversationListItemProps
> {
  render() {
    let { item } = this.props;
    return (
      <TouchableOpacity
        onPress={() => {
          Linking.openURL(item.url);
        }}
        activeOpacity={0.8}
      >
        <Col
          style={[
            styles.container,
            {
              marginHorizontal: DPConfig.dimens.screen.horizontalPadding,
            },
          ]}
        >
          <Row
            style={{
              alignItems: "center",
              paddingVertical: 8,
            }}
          >
            <ADText
              style={[
                styles.filename,
                {
                  textDecorationLine: "underline",
                },
              ]}
            >
              {item.display_name}
            </ADText>
            <View
              style={{
                backgroundColor: "#efefef",
                borderRadius: 10,
                overflow: "hidden",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ADText
                style={[
                  styles.filename,
                  {
                    fontSize: ADResponsiveUtil.plus(12, 2),
                    margin: 8,
                  },
                ]}
              >
                {item.extension}
              </ADText>
            </View>
          </Row>
        </Col>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    borderColor: "#efefef",
    borderWidth: 1,
    paddingHorizontal: 8,
    // paddingTop: 16,
    backgroundColor: "#fff",
    marginVertical: 8,
    paddingBottom: 8,
    paddingTop: 8,
    borderRadius: 16,
  },
  description: {
    flexGrow: 1,
    width: 0,
    textAlign: "left",
    color: "#727272",
    fontSize: ADResponsiveUtil.plus(14, 2),
  },

  filename: {
    flexGrow: 1,
    marginStart: 8,
    fontSize: ADResponsiveUtil.plus(14, 2),
  },

  date: {
    fontSize: ADResponsiveUtil.plus(9, 2),
    textAlign: "left",
    flexGrow: 1,
    width: 0,
    // marginBottom: 16,
    color: "#9e9e9e",
  },
});
