import * as React from 'react';
import {observer} from 'mobx-react';
import {I18nManager, StyleProp, View, ViewStyle} from 'react-native';
import autobind from 'autobind-decorator';
import {CommentItemModel} from 'app/api/Models';
import {
    ADButton,
    ADFlatList, ADImage,
    ADResponsiveUtil,
    ADText,
    ADTextInput,
    AlertDialog,
    Col,
    DPConfig,
    DPImage,
    DPStyleSheet,
    DPTab,
    Row
} from '@dp/react-native-core';
import Dash from 'react-native-dash';
import {i18n} from '../../../../i18n/i18n';

interface Props {
    comments?: Array<CommentItemModel>
    onSendComment: (comment: string, onSuccess: (message?: string) => void) => void
    isSendCommentEnabled: boolean
    style?: StyleProp<ViewStyle>
}


@observer
export default class CommentBoxComponent extends React.Component<Props, { comment: string }> {
    state = {
        comment: ''
    }

    render() {

        return (
            <View style={[styles.container, this.props.style]}>
                <Col style={{paddingVertical: DPConfig.dimens.screenVerticalPadding}}>
                    <ADTextInput
                        multiline
                        onChangeText={(comment) => this.setState({comment})}
                        // textStyle={{textAlign: I18nManager.isRTL ? 'right' : 'left'}}
                        defaultValue={this.state.comment}
                        style={{marginVertical: 16, flex: 1}}
                        label={i18n.strings.screens.news_details.comment.input_label}
                    />
                    <ADButton
                        filled
                        accent
                        title={i18n.strings.screens.news_details.comment.submit}
                        onPress={this.handleSend}/>
                </Col>
                <ADFlatList
                    style={{flexGrow: 1, flex: undefined}}
                    data={this.props.comments || []}
                    emptyMessage={i18n.strings.screens.news_details.comment.no_comments}
                    ItemSeparatorComponent={null}
                    renderItem={this.renderItem}
                />
            </View>
        )
    }

    handleSend = () => {
        if (this.state.comment)
            this.props.onSendComment(this.state.comment, (message?) => {
                this.setState({
                        comment: ''
                    }
                );
                // if (message) {
                new AlertDialog.Builder()
                    .setTitle(i18n.strings.screens.news_details.comment.submit_success.title)
                    .setMessage(message || i18n.strings.screens.news_details.comment.submit_success.message)
                    .setPositiveButton(i18n.strings.screens.news_details.comment.submit_success.ok)
                    .create()
                    .show()
                // }
            })
    }

    @autobind
    renderItem({item, index}: { item: CommentItemModel, index: number }) {
        return (
            <Row>
                <View style={styles.avatarWrapper}>
                    <ADImage
                        // previewEnabled={!!item.image && item.image.length > 0}
                        source={
                            item.image ||
                            {icon: 'person', color: '#d1d1d1', size: AVATAR_SIZE * .7}
                        }
                        size={AVATAR_SIZE}
                        // borderColor={'#000'}
                        borderRadius={AVATAR_SIZE / 2}
                    />
                </View>
                <Col style={styles.content}>
                    <ADText style={styles.author} bold>
                        {
                            item.user_name
                        }
                    </ADText>
                    <ADText style={styles.comment}>{item.comment || item.description}</ADText>
                    <ADText style={styles.date}>
                        {
                            item.jalali_updated_at || item.jalali_created_at || item.updated_at || item.created_at
                        }
                    </ADText>
                    <Dash style={styles.separator} dashColor={'#f1f1f1'}/>
                </Col>
            </Row>
        )
    }
}

const AVATAR_SIZE = ADResponsiveUtil.dimen(28, 32, 48)
const styles = DPStyleSheet.create(({dimens, colors}) => ({
    container: {
        // flex: 1,
        paddingHorizontal: dimens.screenHorizontalPadding,
    },
    content: {
        flex: 1,
        marginStart: 16,
        alignItems: 'flex-start'
    },
    separator: {
        height: 1,
        width: '100%',
        marginVertical: 8
    },
    author: {
        fontSize: ADResponsiveUtil.plus(14, 2),
        textAlign: 'left',
        // marginTop: 8
    },
    comment: {
        fontSize: ADResponsiveUtil.plus(14, 2),
        lineHeight: ADResponsiveUtil.plus(14, 2) * 1.8,
        textAlign: 'left'
    },

    date: {
        fontSize: ADResponsiveUtil.plus(10, 2),
        color: '#9e9e9e'
    },
    avatarWrapper: {
        width: AVATAR_SIZE + 4,
        height: AVATAR_SIZE + 4,
        borderColor: '#d1d1d1',
        borderWidth: 2,
        borderRadius: AVATAR_SIZE / 2 + 2,
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 8
    }
}))