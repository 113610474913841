import ReactPlayer from 'react-player';
import React from "react";
import autobind from "autobind-decorator";

class Video extends React.Component {
    state = {
        playing: false
    }


    render() {
        return (
            <ReactPlayer
                url={this.props.url}
                className='react-player'
                playing={this.state.playing}
                controls={true}
                {...this.props}
            />
        )
    }

    @autobind
    BackHandler() {
        this.setState({
            playing: false
        })
    }

    @autobind
    pause() {
        this.setState({
            playing: false
        })
    }
}

export default Video;
