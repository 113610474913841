import {inject, observer} from 'mobx-react';
import * as React from 'react';
import {
    ADButton,
    ADFormStore,
    ADIcon,
    ADRequiredValidator,
    ADResponsiveUtil,
    ADSelectInput,
    ADTextInput,
    Col,
    DPConfig,
    Row
} from '@dp/react-native-core';
import {View} from 'react-native';
import autobind from 'autobind-decorator';

type Props = {
    $form: ADFormStore<any>
    selectId: number | string
    selectLabel: string
    inputId: number | string
    color: string
    inputPlaceholder: string
}


@inject('$form')
@observer
export class SelectInputItem extends React.Component<Props> {

    constructor(p) {
        super(p);
        this.addInitItems()
    }

    render() {
        let {$form, selectId, inputId, selectLabel, inputPlaceholder, color} = this.props;
        if (!$form) return false;

        let selectItems = $form.staticData.get(selectId) || [];
        let selectsData = $form.data.get(selectId) || [];
        let inputsData: Array<any> = $form.data.get(inputId) as any || [];

        return (
            <Col>
                <Col>
                    {
                        [...new Array(inputsData.length)]
                            .map((_, index) => {
                                return (
                                    <Row key={index} style={DPConfig.styles.card}>
                                        <Row style={{
                                            flex: 1,
                                            paddingHorizontal: 8,
                                            paddingVertical: 12
                                        }}
                                        >
                                            <ADSelectInput
                                                {...SmallInputProps}
                                                style={{flex: 5}}
                                                placeholder={selectLabel}
                                                items={selectItems}

                                                selectedItems={selectItems.filter(_ => _.id === selectsData[index])}
                                                $validators={[new ADRequiredValidator('خالی است')]}
                                                onSelected={items => {
                                                    selectsData[index] = items[0].id;
                                                    $form.data.set(selectId, selectsData);
                                                    this.forceUpdate()
                                                }}
                                            />
                                            <View style={{width: 16}}/>
                                            <ADTextInput
                                                {...SmallInputProps}
                                                fontSize={12}
                                                style={{flex: 3}}
                                                $validators={[new ADRequiredValidator('خالی است')]}
                                                keyboardType={'numeric'}
                                                placeholder={inputPlaceholder}
                                                value={inputsData[index]}
                                                onChangeText={text => {
                                                    inputsData[index] = text;
                                                    $form.data.set(inputId, [...inputsData])
                                                }}
                                            />
                                        </Row>
                                        <ADButton
                                            circle
                                            onPress={() => this.removeItem(index)}
                                            hitSlop={{
                                                right: 8,
                                                left: 8,
                                                bottom: 8,
                                                top: 8
                                            }}
                                            style={{
                                                // backgroundColor: DPConfig.colors.errorColor,
                                                position: 'absolute',
                                                top: 0,
                                                end: 0,
                                                borderRadius: 16,
                                                marginEnd: 8,
                                                marginTop: 8
                                            }}
                                        >
                                            <ADIcon
                                                style={{
                                                    backgroundColor: DPConfig.colors.errorColor,
                                                    borderRadius: 12,
                                                }}
                                                size={18}
                                                color={'#fff'}
                                                icon={'close'}/>
                                        </ADButton>
                                    </Row>
                                )
                            })
                    }
                </Col>
                <Row>
                    <ADButton
                        onPress={this.addItem}
                        before={
                            <ADIcon icon={'add-circle-outline'} color={color}/>
                        }
                        color={color}
                        textColor={DPConfig.colors.defaultTextColor}
                        borderLess
                        small
                        title={'افزودن سطر جدید'}/>
                </Row>
            </Col>
        )
    }

    @autobind
    removeItem(index: number) {
        let {$form, selectId, inputId} = this.props;
        let selectsData = $form.data.get(selectId) || [];
        let inputsData: Array<any> = $form.data.get(inputId) as any || [];

        $form.setData(selectId, selectsData.filter((_, i) => i !== index));
        $form.setData(inputId, inputsData.filter((_, i) => i !== index));

    }

    @autobind
    addItem() {
        let {$form, selectId, inputId} = this.props;
        let selectsData = $form.data.get(selectId) || [];
        let inputsData: Array<any> = $form.data.get(inputId) as any || [];

        $form.setData(selectId, selectsData.concat([undefined]))
        $form.setData(inputId, inputsData.concat([undefined]))

    }

    @autobind
    addInitItems() {
        let {$form, selectId, inputId} = this.props;
        let selectsData = $form.data.get(selectId) || [];
        let inputsData: Array<any> = $form.data.get(inputId) as any || [];
        if (selectsData.length === 0)
            $form.setData(selectId, selectsData.concat([undefined]))
        if (inputsData.length === 0)
            $form.setData(inputId, inputsData.concat([undefined]))

    }
}

const FONT_SIZE_12 = ADResponsiveUtil.dimen(12, 12, 14, 16);
const SmallInputProps = {
        labelHeight: 0,
        textStyle: {
            // backgroundColor: '#f1f1f1',
            padding: 4,
            minHeight: 32,
            borderRadius: 8
        },
        // lineWidth: 0,
        fontSize: FONT_SIZE_12,
        clearEnabled: false
    }
;