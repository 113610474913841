import {
  ADHttpRequestFile,
  ADResponsiveUtil,
  BaseService,
  DPConfig,
  DPRestService,
  IDType,
} from "@dp/react-native-core";
import { TechnicalInfoSubmitModel } from "app/screens/dashboard/technical/TechnicalScreen";
import { TimesStateModel } from "app/screens/dashboard/times/TimesScreen";
import autobind from "autobind-decorator";
import { ProjectFilterRequest } from "../screens/dashboard/project/project-list/ProjectListScreen";
import { CreateTicketModel } from "../screens/dashboard/tickets/create/CreateTicketScreen";
import ApiResponseModel from "./ApiResponseModel";
import ListApiResponseModel from "./ListApiResponseModel";
import {
  AppGlobalConfigModel,
  DashboardModel,
  NewsDetailsModel,
  NewsItemModel,
  OfferListModel,
  OfferRequestScreenInfoModel,
  PortfolioItemModel,
  ProfileModel,
  ProfilePerformanceHistoryModel,
  ProfileScoreModel,
  ProfileUpdateModel,
  ProjectDetailsModel,
  ProjectListModel,
  ProjectStateType,
  ProjectUpdateStateDataModel,
  SettingModel,
  TechnicalFormInfoModel,
  TicketDetailsModel,
  TicketItemModel,
  TicketPriorityItem,
  UpdateSettingModel,
} from "./Models";

@autobind
export default class ApiService extends BaseService<{ $rest: DPRestService }> {
  readonly ServiceName = "$api";

  requestSmsToken(p: { mobile: string; otp_support?: boolean }) {
    return super.$services.$rest.post("request", p);
  }

  login(p: { mobile: string; code: string }) {
    return super.$services.$rest.post<
      ApiResponseModel<
        { access_token: string; refresh_token?: string } | string
      >
    >("activation", p);
  }

  //  ---------------------------------------------------------------------------------------------------
  getGlobalConfig() {
    return super.$services.$rest.post<ApiResponseModel<AppGlobalConfigModel>>(
      "global-config"
    );
  }

  //  ---------------------------------------------------------------------------------------------------
  public readonly dashboardApi = DPConfig.http.createResource<
    {},
    DashboardModel
  >((p) => {
    return this.getDashboardInfo(p);
  });

  getDashboardInfo(p) {
    return super.$services.$rest.get<ApiResponseModel<DashboardModel>>(
      "dashboard",
      p
    );
    // return super.$services.$rest.fake().get<ApiResponseModel<DashboardModel>>('dashboard', p)
  }

  //  ---------------------------------------------------------------------------------------------------
  public readonly newsListApi = DPConfig.http.createListResource<
    { page: number },
    NewsItemModel
  >((p) => {
    return this.getAllNews({ ...p });
  });

  getAllNews(p: { page: number }) {
    return super.$services.$rest.get<ListApiResponseModel<NewsItemModel>>(
      "news"
    );
  }

  getNewsById(p: { news_id: IDType }) {
    return (
      super.$services.$rest
        // .fake().get<ApiResponseModel<NewsDetailsModel>>(`news/details`, p)
        .get<ApiResponseModel<NewsDetailsModel>>(`news/${p.news_id}`, p)
    );
  }

  sendNewsComment(p: { news_id: IDType; comment: string }) {
    return super.$services.$rest.post<ApiResponseModel<any>>(
      `news/${p.news_id}/comment`,
      p
    );
  }

  likeNewsById(p: { news_id: IDType; like: boolean }) {
    return super.$services.$rest.put<ApiResponseModel<NewsDetailsModel>>(
      `news/${p.news_id}/like`,
      p
    );
  }

  //  ---------------------------------------------------------------------------------------------------

  getSettingInfo(p = {}) {
    return super.$services.$rest.get<ApiResponseModel<SettingModel>>(
      "users/setting",
      p
    );
    // .fake().get<ApiResponseModel<SettingModel>>('setting/info', p);
  }

  public readonly userSettingApi = DPConfig.http.createResource<
    {},
    SettingModel
  >((p) => {
    return this.getSettingInfo(p);
  });

  updateSetting(p: UpdateSettingModel) {
    return super.$services.$rest.put<ApiResponseModel>("users/setting", p);
  }

  //  ---------------------------------------------------------------------------------------------------

  getProfileInfo(p) {
    return super.$services.$rest.get<ApiResponseModel<ProfileModel>>(
      "users/profile"
    );
  }

  //  ---------------------------------------------------------------------------------------------------

  getProfileScore(p) {
    return super.$services.$rest.get<ApiResponseModel<ProfileScoreModel>>(
      "users/performance/score"
    );
  }

  //  ---------------------------------------------------------------------------------------------------

  getProfilePerformanceHistory(p) {
    return super.$services.$rest.get<
      ApiResponseModel<ProfilePerformanceHistoryModel>
    >("users/performance/history");
  }

  //  --------------------------------------------------------------------------------------------------
  updateProfile(p: ProfileUpdateModel) {
    return super.$services.$rest.put<ApiResponseModel<ProfileModel>>(
      "users/profile",
      p
    );
  }

  //  ---------------------------------------------------------------------------------------------------

  public readonly offerListApi = DPConfig.http.createListResource<
    { page: number },
    OfferListModel
  >((p) => {
    return this.getOfferListScreenItems({ ...p });
  });

  getOfferListScreenItems(p: { page: number }) {
    return super.$services.$rest.get<ListApiResponseModel<OfferListModel>>(
      "offers",
      p
    );
  }

  getOfferScreenInfo(p) {
    return super.$services.$rest.get<
      ApiResponseModel<OfferRequestScreenInfoModel>
    >("offers/form");
  }

  convertScoreToOfferCode() {
    return super.$services.$rest.post<
      ApiResponseModel<OfferRequestScreenInfoModel>
    >("offers/convert");
  }

  //  ---------------------------------------------------------------------------------------------------

  getProjectListItems(p: ProjectFilterRequest) {
    return super.$services.$rest.get<ListApiResponseModel<ProjectListModel>>(
      "projects",
      p
    );
  }

  getProjectInfo(p: { projectId: IDType }) {
    return super.$services.$rest.get<ApiResponseModel<ProjectDetailsModel>>(
      `projects/${p.projectId}`,
      p
    );
    // .fake().get<ApiResponseModel<ProjectDetailsModel>>(`project/details`, p);
  }

  getProjectSearchFormInfo(p) {
    return super.$services.$rest.get<ApiResponseModel<SettingModel>>(
      "users/setting",
      p
    );
  }

  updateProjectState(p: {
    project_id: IDType;
    state: ProjectStateType;
    confirm_code?: string;
  }) {
    return super.$services.$rest.put<
      ApiResponseModel<ProjectUpdateStateDataModel>
    >(`projects/${p.project_id}`, p);
    // .fake().put<ApiResponseModel<ProjectUpdateStateDataModel>>(`project/details`, p);
  }

  addCommentToProject(p: { project_id: IDType; comment: string }) {
    return super.$services.$rest.post<ApiResponseModel<ProjectDetailsModel>>(
      `projects/${p.project_id}/comment`,
      p
    );
  }

  //  ---------------------------------------------------------------------------------------------------
  getTechnicalFormInfo(p: { project_id: IDType }) {
    return super.$services.$rest.get<ApiResponseModel<TechnicalFormInfoModel>>(
      "projects/technical/form",
      p
    );
  }

  sendTechnicalFormInfo(
    p: TechnicalInfoSubmitModel,
    onUploadProgress: (p: {
      percent: number;
      uploaded: number;
      total: number;
    }) => void
  ) {
    return super.$services.$rest.post<ApiResponseModel<TechnicalFormInfoModel>>(
      `projects/technical/${p.project_id}`,
      p,
      {
        config: {
          timeout: ADResponsiveUtil.size(
            Object.keys(p.images || {}).length * 60000,
            60000,
            Number.MAX_SAFE_INTEGER
          ),
          onUploadProgress: function (progressEvent) {
            let percent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onUploadProgress &&
              onUploadProgress({
                percent,
                total: progressEvent.total,
                uploaded: progressEvent.loaded,
              });
          },
        },
      }
    );
  }

  setProjectTimesState(
    p: TimesStateModel & { project_id: string },
    onUploadProgress: (p: {
      percent: number;
      uploaded: number;
      total: number;
    }) => void
  ) {
    return super.$services.$rest.put<ApiResponseModel<TechnicalFormInfoModel>>(
      `projects/${p.project_id}`,
      { ...p, state: "times" },
      {
        config: {
          onUploadProgress: function (progressEvent) {
            let percent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onUploadProgress &&
              onUploadProgress({
                percent,
                total: progressEvent.total,
                uploaded: progressEvent.loaded,
              });
          },
        },
      }
    );
  }

  //  ---------------------------------------------------------------------------------------------------
  //  ---------------------------------------------------------------------------------------------------
  public readonly ticketListApi = DPConfig.http.createListResource<
    {},
    TicketItemModel
  >((p) => {
    return this.getTickets(p);
  });

  protected getTickets(p: { page: number }) {
    return super.$services.$rest.get<ListApiResponseModel<TicketItemModel>>(
      "tickets",
      p
    );
  }

  public readonly ticketPrioritiesListApi = DPConfig.http.createResource<
    {},
    Array<TicketPriorityItem>
  >((p) => {
    return this.getTicketsPriorities();
  });

  protected getTicketsPriorities() {
    return super.$services.$rest.get<
      ApiResponseModel<Array<TicketPriorityItem>>
    >("tickets/priorities");
  }

  getTicketById(p: { ticket_id: IDType }) {
    return super.$services.$rest.get<ApiResponseModel<TicketDetailsModel>>(
      `tickets/${p.ticket_id}`
    );
  }

  sendTicketComment(p: { ticket_id: IDType; text: string }) {
    return super.$services.$rest.post<ApiResponseModel<null>>(
      `tickets/${p.ticket_id}/comments`,
      p
    );
  }

  updateTicketStatus(p: { ticket_id: IDType; status_id: string }) {
    return super.$services.$rest.put<ApiResponseModel<TicketDetailsModel>>(
      `tickets/${p.ticket_id}`,
      p
    );
  }

  createTicket(p: CreateTicketModel) {
    return super.$services.$rest.post<ApiResponseModel<TicketDetailsModel>>(
      "tickets",
      p
    );
  }

  //  ---------------------------------------------------------------------------------------------------

  //$$dp-cli-merge$$
  //  ---------------------------------------------------------------------------------------------------

  public readonly portfolioListApi = DPConfig.http.createListResource<
    {},
    PortfolioItemModel
  >((p) => {
    return this.getPortfolioListItems({ ...p });
  });

  getPortfolioListItems(p) {
    // if (__DEV__)
    //     return super.$services.$rest.fake().get<ListApiResponseModel<PortfolioItemModel>>('portfolio', p);
    return super.$services.$rest.get<ListApiResponseModel<PortfolioItemModel>>(
      "portfolio",
      p
    );
  }

  removePortfolio(p: { portfolio_id: IDType }) {
    return super.$services.$rest.delete<ApiResponseModel>(
      `portfolio/${p.portfolio_id}`,
      p
    );
  }

  addPortfolios(
    p: { images: Array<ADHttpRequestFile> },
    onUploadProgress: (p: {
      percent: number;
      uploaded: number;
      total: number;
    }) => void
  ) {
    return super.$services.$rest.post<ApiResponseModel>(`portfolio`, p, {
      config: {
        timeout: ADResponsiveUtil.size(
          Object.keys(p.images || {}).length * 60000,
          60000,
          Number.MAX_SAFE_INTEGER
        ),
        onUploadProgress: function (progressEvent) {
          let percent = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          onUploadProgress &&
            onUploadProgress({
              percent,
              total: progressEvent.total,
              uploaded: progressEvent.loaded,
            });
        },
      },
    });
  }

  editPortfolios(p: { portfolio_id: IDType; title: string }) {
    return super.$services.$rest.put<ApiResponseModel>(
      `portfolio/${p.portfolio_id}`,
      p
    );
  }

  //  ---------------------------------------------------------------------------------------------------
}
