import * as React from 'react';
import {inject, observer} from 'mobx-react';
import autobind from 'autobind-decorator';
import {
    ADFab,
    ADFlatList,
    ADResponsiveUtil,
    ADScreen,
    DPConfig,
    DPStyleSheet,
    INavigationService,
    InjectedNavigationServiceProps
} from '@dp/react-native-core';
import PortfolioListItem from './PortfolioListItem';
import {AppToolbar} from 'app/screens/common/AppToolbar';
import {i18n} from 'app/i18n/i18n';
import InjectedApiServiceProps from 'app/api/InjectedApiServiceProps';
import CreatePortfolioScreen from '../create/CreatePortfolioScreen';
import EditPortfolioScreen from '../edit/EditPortfolioScreen';
import {PortfolioItemModel} from '../../../../api/Models';


type Params = {}

type PortfolioListScreenProps =
    Params &
    InjectedNavigationServiceProps &
    InjectedApiServiceProps

type State = {}

@inject('$navigation', '$api')
@observer
export default class PortfolioListScreen extends React.Component<PortfolioListScreenProps, State> {
    static RouteName = 'PortfolioListScreen';

    static start(nav: INavigationService, param?: Params) {
        nav.navigate(PortfolioListScreen.RouteName, param)
    }

    render() {
        let trans = i18n.strings.screens.portfolio_list
        return (
            <ADScreen>
                <AppToolbar theme={'light'}
                            title={trans.title}/>
                <ADFlatList
                    resource={this.props.$api.portfolioListApi}
                    renderItem={this.renderItem}
                    numColumns={COLUMN_COUNT}
                    autoLoad={'no-data'}
                    ItemSeparatorComponent={null}
                />
                <ADFab icon={'add'} onPress={this.handleFabPress}/>
            </ADScreen>
        )
    }

    @autobind
    renderItem(p: { item, index }) {
        let isStartOfRow = p.index % COLUMN_COUNT === 0;
        return (
            <PortfolioListItem
                item={p.item}
                style={{
                    marginEnd: DPConfig.dimens.screen.horizontalPadding,
                    marginStart: isStartOfRow ? DPConfig.dimens.screen.horizontalPadding : 0,
                }}
                onPress={() => this.handleItemPress(p)}
            />
        )
    }

    @autobind
    handleItemPress(p: { item: PortfolioItemModel, index }) {
        EditPortfolioScreen.start(this.props.$navigation, {
            portfolio: p.item
        })
    }

    @autobind
    handleFabPress() {
        CreatePortfolioScreen.start(this.props.$navigation)
    }
}

const COLUMN_COUNT = ADResponsiveUtil.dimen(1, 2, 3, 4)
const styles = DPStyleSheet.create(() => ({}));
