import * as React from 'react';
import {observer} from 'mobx-react';
import autobind from 'autobind-decorator';
import {
    ADResponsiveUtil,
    ADText,
    Col,
    DPConfig,
    DPI18n,
    DPInject,
    DPStyleSheet,
    INavigationService,
    InjectedAppLoaderServiceProps,
    InjectedAuthServiceProps,
    InjectedNavigationServiceProps,
    InjectedStorageServiceProps
} from '@dp/react-native-core';
import {I18nManager, Platform, StyleSheet, ViewStyle} from 'react-native';
import LoginScreen from '../auth/login/LoginScreen';
import InjectedApiServiceProps from 'app/api/InjectedApiServiceProps';
import MainScreen from '../dashboard/main/MainScreen';
import {Image, View} from 'react-native-animatable';
import IntroScreen from '../intro/IntroScreen';
import Assets from '../../assets/Assets';
import {i18n} from '../../i18n/i18n';
import {AppGlobalConfigModel} from '../../api/Models';
import TicketDetailsScreen from '../dashboard/tickets/conversation/TicketDetailsScreen';

type Params = {}

type SplashProps =
    Params &
    InjectedNavigationServiceProps &
    InjectedAppLoaderServiceProps &
    InjectedStorageServiceProps &
    InjectedAuthServiceProps &
    InjectedApiServiceProps

@DPInject('$navigation', '$api', '$auth', '$loader', '$storage')
@observer
export default class SplashScreen extends React.Component<SplashProps> {
    static RouteName = 'SplashScreen';

    static start(nav: INavigationService, params: Params) {
        nav.navigate(SplashScreen.RouteName, params)
    }


    protected animationFinished = Platform.OS === 'web'
    protected configLoaded = false
    startTime

    componentDidMount() {
        this.startTime = new Date().getTime();
        this.gotoNext();
    }

    @autobind
    async gotoNext() {


        if (!this.props.$loader.isReady) {
            setTimeout(this.gotoNext, 100);
            return
        }

        await this.loadConfig();

        let loadingTime = (new Date().getTime() - this.startTime);

        setTimeout(() => {
            if (this.props.$auth.isLoggedIn) {
                if (IntroScreen.isIntroDone(this.props.$storage))
                    MainScreen.resetToDashboardNavigator(this.props.$navigation);
                else
                    IntroScreen.resetTo(this.props.$navigation);
            } else {
                if (IntroScreen.isIntroDone(this.props.$storage))
                    LoginScreen.resetTo(this.props.$navigation);
                else
                    IntroScreen.resetTo(this.props.$navigation);
            }

        }, ADResponsiveUtil.size(1700 - loadingTime, 0, 1700))
    }

    @autobind
    protected applyConfig(config?: AppGlobalConfigModel) {
        if (config) {
            if (config.theme && config.theme.colors) {
                DPConfig.merge({
                    colors: config.theme.colors
                });
            }
            if (config.locales) {
                i18n.extends(config.locales);
            }
        }
    }

    @autobind
    protected async loadConfig() {
        return new Promise(resolve => {
            this.props.$api.getGlobalConfig()
                .onSuccess(async (response) => {
                    let config;
                    let remoteConfig = response.data || undefined;
                    // update cache
                    if (remoteConfig) {
                        config = remoteConfig;
                        this.props.$storage.set('$config/global', remoteConfig);
                    } else {
                        config = this.props.$storage.get('$config/global');
                    }

                    // apply config
                    this.applyConfig(config)

                    resolve()
                })
                .cancelable(false)
                .defaultError()
                .onError(error => {
                    let config = this.props.$storage.get('$config/global');
                    this.applyConfig(config)
                    resolve()
                })
                .enqueue();
        })
    }

    render() {
        let theme = 1;

        return (
            <Col style={{alignItems: 'center', flex: 1, backgroundColor: '#fff',}}>
                <View style={styles.container}>
                    {
                        theme === 1 &&
                        <View style={[StyleSheet.absoluteFill, {alignItems: 'center'}]}>
                            {this.renderLogo()}
                            <Col style={{alignItems: 'center'}}>
                                <Image
                                    animation={'slideInUp'}
                                    source={Assets.images.splash_1_back}
                                    resizeMode={'contain'}
                                    style={{
                                        width: VIEWPORT_WIDTH,
                                        height: VIEWPORT_WIDTH * 612 / 1082
                                    }}/>
                                <Image
                                    animation={'slideInUp'}
                                    delay={150}
                                    source={Assets.images.splash_1_tree}
                                    resizeMode={'contain'}
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        width: VIEWPORT_WIDTH * .8,
                                        height: VIEWPORT_WIDTH * .8 * 129 / 838
                                    }}/>
                                <Image
                                    delay={300}
                                    animation={'slideInUp'}
                                    source={Assets.images.splash_1_phone}
                                    resizeMode={'contain'}
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        width: VIEWPORT_WIDTH * .28,
                                        height: VIEWPORT_WIDTH * .28 * 539 / 301
                                    }}/>
                                <Image
                                    source={Assets.images.splash_1_worker}
                                    resizeMode={'contain'}
                                    onAnimationEnd={() => {
                                        this.animationFinished = true;
                                        this.gotoNext()
                                    }}
                                    animation={'slideInUp'}
                                    delay={450}
                                    style={{
                                        position: 'absolute',
                                        bottom: -5,
                                        start: '26%',
                                        width: VIEWPORT_WIDTH * .22,
                                        height: VIEWPORT_WIDTH * .22 * 477 / 276,
                                    }}
                                />
                            </Col>
                        </View>
                    }

                    {/* {
                        theme === 2 &&
                        <View style={[StyleSheet.absoluteFill, {alignItems: 'center'}]}>
                            <Image
                                source={Assets.images.splash_2_back}
                                resizeMode={'cover'}
                                animation={'fadeIn'}
                                delay={500}
                                style={{

                                    position: 'absolute',
                                    width: VIEWPORT_WIDTH,
                                    height: ADResponsiveUtil.height
                                }}/>
                            <View style={styles.overlay}/>
                            {this.renderLogo(theme)}

                        </View>
                    }*/}
                </View>
            </Col>
        )
    }

    @autobind
    private renderLogo(theme = 1 | 2) {

        let color = theme === 2 ? '#fff' : '#d1d1d1'
        return (
            <Col style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                {
                    //@ts-ignore
                    <Image
                        duration={300}
                        animation={'fadeIn'}
                        useNativeDriver
                        style={{
                            width: LOGO_WIDTH,
                            height: LOGO_HEIGHT,
                        }}
                        resizeMode={'contain'}
                        tintColor={theme === 2 ? '#fff' : undefined}
                        //@ts-ignore
                        source={Assets.images.logo}
                    />
                }

                <View
                    animation={'fadeIn'}

                    delay={DPConfig.app.min_splash_time * .70}
                    style={{
                        // transform: [{rotateX: '45deg'}],
                        position: 'absolute',
                        bottom: ADResponsiveUtil.screenVerticalPadding / 2,
                        left: ADResponsiveUtil.screenHorizontalPadding,
                        right: ADResponsiveUtil.screenHorizontalPadding,
                        alignItems: 'center'
                    }}>
                    <ADText style={{
                        fontSize: ADResponsiveUtil.plus(10, 2),
                        color: color,
                        borderRadius: 4
                    }}>

                        {DPI18n.strings.$dialog_loading_message}
                    </ADText>
                    <ADText
                        style={{
                            marginTop: 8,
                            fontSize: ADResponsiveUtil.plus(10, 2),
                            color: color,
                            padding: 2,
                            borderRadius: 4
                        }}>
                        {DPConfig.app.version_name}
                    </ADText>
                </View>
            </Col>
        )
    }
}

const VIEWPORT_WIDTH = Math.min(
    DPConfig.dimens.screen.width,
    DPConfig.dimens.screen.height,
);

const LOGO_WIDTH = ADResponsiveUtil.size(VIEWPORT_WIDTH * .7, 100, 300);
const LOGO_HEIGHT = LOGO_WIDTH * 238 / 514;
const styles = DPStyleSheet.create(({colors, fonts}) => ({
    container: {
        flexGrow: 1,
        width: VIEWPORT_WIDTH,
    } as ViewStyle,
    text: {
        ...fonts.defaultFont,
        fontSize: ADResponsiveUtil.plus(12, 2),
        marginTop: -LOGO_HEIGHT * .15,
        alignSelf: (I18nManager.isRTL ? 'flex-end' : 'flex-start'),
    },
    overlay: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: colors.accentColor,
        opacity: .8
    } as ViewStyle
}))