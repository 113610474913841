import {
  ADButton,
  ADDrawerItemProps,
  ADDrawerList,
  ADIcon,
  ADImage,
  ADImageSourceType,
  ADResponsiveUtil,
  ADText,
  ADTouchable,
  AlertDialog,
  Col,
  DPConfig,
  DPCopyright,
  DPProgressBar,
  DPStyleSheet,
  InjectedAuthServiceProps,
  InjectedNavigationServiceProps,
  InjectedStorageServiceProps,
  Row,
} from "@dp/react-native-core";
import InjectedApiServiceProps from "app/api/InjectedApiServiceProps";
import { ProfileScoreModel } from "app/api/Models";
import Assets from "app/assets/Assets";
import ProfileScreen from "app/screens/dashboard/profile/ProfileScreen";
import autobind from "autobind-decorator";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import * as React from "react";
import {
  BackHandler,
  Linking,
  Platform,
  StyleProp,
  View,
  ViewStyle,
} from "react-native";
import { Image } from "react-native-animatable";
import RNExit from "react-native-exit-app";
import { i18n } from "../../../i18n/i18n";
import Avatar from "../../common/avatar/Avatar";
import IntroScreen from "../../intro/IntroScreen";
import NewsDetailsScreen from "../news/news-details/NewsDetailsScreen";
import NewsGalleryListScreen from "../news/news-gallery/NewsGalleryListScreen";
import NewsListScreen from "../news/news-list/NewsListScreen";
import OfferListScreen from "../offer/offer-list/OfferListScreen";
import OfferRequestScreen from "../offer/offer-request/OfferRequestScreen";
import PortfolioListScreen from "../portfolio/list/PortfolioListScreen";
import ProjectDetailsScreen from "../project/project-details/ProjectDetailsScreen";
import ProjectListScreen from "../project/project-list/ProjectListScreen";
import SettingScreen from "../setting/SettingScreen";
import TechnicalScreen from "../technical/TechnicalScreen";
import TicketListScreen from "../tickets/tickets/TicketListScreen";
import PerformanceLabel from "../profile/PerformanceLabel";

type Props = InjectedAuthServiceProps &
  InjectedNavigationServiceProps &
  InjectedStorageServiceProps &
  InjectedApiServiceProps;

@inject("$navigation", "$auth", "$api", "$storage")
@observer
export default class Drawer extends React.Component<Props> {
  @observable
  public performance_title? = "";

  protected apiProfileScore = DPConfig.http.createResource<
    {},
    ProfileScoreModel
  >((p) => {
    return this.props.$api.getProfileScore(p).onSuccess((response) => {
      this.performance_title = response.data.performance_title;
    });
  });

  componentDidMount() {
    this.apiProfileScore.load({});
  }

  @autobind
  private renderHeader() {
    let info = this.props.$api.dashboardApi.data;

    return (
      <ADTouchable onPress={this.handleHeaderPress}>
        <Col style={{ marginBottom: 0, direction: "rtl" }}>
          <Image
            source={Assets.images.drawer_header_back}
            resizeMode={"cover"}
            style={DPConfig.styles.absoluteFill as any}
          />
          <Row style={styles.header}>
            <Col>
              <Avatar
                $api={this.props.$api}
                style={{
                  marginEnd: 16,
                }}
              />
            </Col>
            <Col
              style={{
                justifyContent: "center",
                alignItems: "stretch",
                flex: 1,
              }}
            >
              <Row>
                <ADText
                  numberOfLines={2}
                  bold
                  style={[
                    styles.title,
                    {
                      fontSize:
                        info.user_name && info.user_name.length > 16
                          ? ADResponsiveUtil.plus(12, 2)
                          : ADResponsiveUtil.plus(16, 2),
                    },
                  ]}
                >
                  {info.user_name}
                </ADText>
              </Row>
              <Row>
                <View
                  style={{
                    backgroundColor: "#fff",
                    height: 2,
                    width: 48,
                    borderRadius: 2,
                    marginVertical: 8,
                  }}
                />
              </Row>
              <Row style={{ alignItems: "center" }}>
                <ADText
                  numberOfLines={1}
                  color={"#fff"}
                  fontSize={10}
                  title={i18n.strings.drawer.header.exist_score}
                />
                <ADText
                  bold
                  fontSize={12}
                  color={"#fff"}
                  numberOfLines={1}
                  title={info.score_exist_count}
                />
              </Row>
              <Row style={{ width: "100%", alignItems: "center" }}>
                <ADText
                  numberOfLines={1}
                  color={"#fff"}
                  fontSize={10}
                  title={i18n.strings.drawer.header.received_score}
                />
                <ADText
                  fontSize={12}
                  bold
                  numberOfLines={1}
                  color={"#fff"}
                  title={info.score_receive_count}
                />
              </Row>
              <Row style={{ width: "100%", alignItems: "center" }}>
                <ADText
                  numberOfLines={1}
                  color={"#fff"}
                  fontSize={10}
                  title={i18n.strings.drawer.header.admission_rate}
                />
                <ADText
                  fontSize={12}
                  bold
                  numberOfLines={1}
                  color={"#fff"}
                  title={info.admission_rate}
                />
              </Row>
              <Row style={{ marginTop: 8 }}>
                <PerformanceLabel
                  title={this.performance_title}
                  height={26}
                  clickable
                />
              </Row>
            </Col>
          </Row>
          <Col style={styles.headerProgressBox}>
            {!!info.user_next_level_label && !!info.user_pre_level_label && (
              <Row style={styles.progressLabelBox}>
                <ADText
                  numberOfLines={1}
                  color={"#fff"}
                  fontSize={10}
                  title={info.user_pre_level_label}
                  textAlign={"start"}
                />
                <ADText
                  fontSize={10}
                  numberOfLines={1}
                  color={"#fff"}
                  title={info.user_current_level_value}
                  textAlign={"center"}
                />
                <ADText
                  fontSize={10}
                  numberOfLines={1}
                  color={"#fff"}
                  title={info.user_next_level_label}
                  textAlign={"end"}
                />
              </Row>
            )}
            {!!info.user_current_level_value &&
              !!info.user_pre_level_value &&
              !!info.user_current_level_value &&
              !!info.user_next_level_value && (
                <DPProgressBar
                  style={styles.progress}
                  value={
                    info.user_current_level_value - info.user_pre_level_value
                  }
                  max={info.user_next_level_value - info.user_pre_level_value}
                  color={"green"}
                />
              )}
          </Col>
          <ADButton circle onPress={this.closeDrawer} style={styles.closeBtn}>
            <ADIcon icon={"close"} color={"#fff"} />
          </ADButton>
        </Col>
      </ADTouchable>
    );
  }

  render() {
    let activeRouteName = this.props.$navigation.state.screen.routeName;
    let items: Array<Partial<ADDrawerItemProps> | false> = [
      // {
      //     title: 'صفحه اصلی',
      //     icon: {icon: 'home', color: DPConfig.colors.defaultTextColorLight},
      //     routeName: MainScreen.RouteName,
      // },
      {
        title: i18n.strings.drawer.items.user_projects,
        icon: Assets.images.ic_user_project,
        routeName: ProjectListScreen.RouteName,
        isSelected:
          activeRouteName === ProjectListScreen.RouteName ||
          activeRouteName === TechnicalScreen.RouteName ||
          activeRouteName === ProjectDetailsScreen.RouteName,
      },
      {
        title: i18n.strings.drawer.items.news,
        icon: Assets.images.ic_news,
        routeName: NewsListScreen.RouteName,
        isSelected:
          activeRouteName === NewsListScreen.RouteName ||
          activeRouteName === NewsGalleryListScreen.RouteName ||
          activeRouteName === NewsDetailsScreen.RouteName,
      },
      {
        title: i18n.strings.drawer.items.profile,
        icon: {
          icon: "person",
          color: DPConfig.colors.defaultTextColorLight,
        },
        routeName: ProfileScreen.RouteName,
      },
      {
        title: i18n.strings.drawer.items.offer,
        icon: {
          icon: "md-pricetag",
          type: ADIcon.Types.Ionicons,
          color: DPConfig.colors.defaultTextColorLight,
        },
        routeName: OfferListScreen.RouteName,
        isSelected:
          activeRouteName === OfferListScreen.RouteName ||
          activeRouteName === OfferRequestScreen.RouteName,
      },
      {
        title: i18n.strings.drawer.items.tickets,
        icon: {
          icon: "live-help",
          color: DPConfig.colors.defaultTextColorLight,
        },
        routeName: TicketListScreen.RouteName,
      },
      {
        title: i18n.strings.drawer.items.portfolio,
        icon: {
          icon: "business-center",
          color: DPConfig.colors.defaultTextColorLight,
        },
        routeName: PortfolioListScreen.RouteName,
      },
      {
        title: i18n.strings.drawer.items.setting,
        icon: {
          icon: "settings",
          color: DPConfig.colors.defaultTextColorLight,
        },
        routeName: SettingScreen.RouteName,
      },
      (!!this.props.$api.dashboardApi.data.support_url || __DEV__) && {
        title: i18n.strings.drawer.items.support,
        icon: Assets.images.ic_support,
        onPress: this.handleSupportPress,
      },
      {
        title: i18n.strings.drawer.items.exit,
        icon: {
          icon: "exit-to-app",
          color: DPConfig.colors.defaultTextColorLight,
          mirror: "auto",
        },
        onPress: this.handleExitPress,
      },
    ].filter(Boolean);

    return (
      <Col style={{ flex: 1, marginStart: -1 }}>
        {this.renderHeader()}
        <Col style={{ flexGrow: 1, flexShrink: 0 }}>
          <Col
            style={{
              position: "absolute",
              start: 16,
              top: 0,
              bottom: 0,
              width: 1.5,
              backgroundColor: "#f1f1f1",
            }}
          />

          <ADDrawerList
            contentContainerStyle={{
              flexGrow: 1,
              flexShrink: 0,
              paddingTop: 16,
            }}
            style={{
              backgroundColor: undefined,
            }}
            ItemSeparatorComponent={null}
            itemProps={{
              selectedStyle: {
                borderStartColor: DPConfig.colors.accentColor,
                borderStartWidth: 3,
              },
              style: {
                paddingHorizontal: 16,
                marginStart: 16 - 1,
                backgroundColor: undefined,
              },
            }}
            items={items as any}
          />
          <Row
            style={{
              paddingHorizontal: 16,
              marginStart: 16,
              justifyContent: "space-around",
            }}
          >
            {!!this.props.$api.dashboardApi.data.socials &&
              this.props.$api.dashboardApi.data.socials.map((item, index) => (
                <SocialButton key={index} icon={item.icon} url={item.url} />
              ))}
          </Row>
          <Row>
            <ADTouchable onPress={this.handleDadehpardazPress} withoutFeedback>
              <Row
                style={{
                  marginStart: 16,
                  paddingHorizontal: 16,
                  paddingVertical: DPConfig.dimens.screen.verticalPadding,
                }}
              >
                <DPCopyright />
              </Row>
            </ADTouchable>
          </Row>
        </Col>
      </Col>
    );
  }

  @autobind
  handleExitPress() {
    this.props.$navigation.closeDrawer();
    new AlertDialog.Builder()
      .setTitle(i18n.strings.drawer.exit_confirm.title)
      .setMessage(i18n.strings.drawer.exit_confirm.message)
      .setPositiveButton(i18n.strings.drawer.exit_confirm.confirm, () => {
        (async () => {
          try {
            await IntroScreen.showOnNextStart(this.props.$storage);
          } catch (e) {}
          await this.props.$auth.logout();
          if (Platform.OS === "web") BackHandler.exitApp();
          else RNExit.exitApp();
          return;
        })();
      })
      .setNegativeButton(i18n.strings.drawer.exit_confirm.cancel)
      .create()
      .show();
  }

  @autobind
  private closeDrawer() {
    this.props.$navigation.closeDrawer();
  }

  @autobind
  private handleHeaderPress() {
    this.props.$navigation.closeDrawer();
    ProfileScreen.start(this.props.$navigation);
  }

  @autobind
  private handleDadehpardazPress() {
    Linking.openURL("http://www.dadehpardaz.com").catch(console.warn);
  }

  @autobind
  private handleSupportPress() {
    let supportUrl = this.props.$api.dashboardApi.data.support_url;
    if (supportUrl) Linking.openURL(supportUrl).catch(console.warn);
  }
}

const SocialButton = (p: {
  url: string;
  icon: ADImageSourceType;
  style?: StyleProp<ViewStyle>;
}) => {
  let size = 36;
  return (
    <ADButton
      circle
      style={[
        {
          borderWidth: 1,
          borderColor: DPConfig.colors.accentColor,
          borderRadius: size / 2,
          width: size,
          height: size,
          alignItems: "center",
          justifyContent: "center",
        },
        p.style,
      ]}
      onPress={() => {
        p.url && Linking.openURL(p.url).catch(console.warn);
      }}
    >
      <ADImage
        source={p.icon}
        color={DPConfig.colors.accentColor}
        style={{ width: size * 0.6, height: size * 0.6 }}
        size={size * 0.6}
      />
    </ADButton>
  );
};

let avatarSize = ADResponsiveUtil.dimen(50, 60, 70);

const styles = DPStyleSheet.create(({ styles, dimens, colors }) => ({
  header: {
    paddingHorizontal: 16,

    paddingTop: dimens.safeAreaInsets.top + dimens.screenVerticalPadding * 2,
    alignItems: "stretch",
    // backgroundColor: DPColorUtil.fade(colors.accentColor, .4),
  },
  headerProgressBox: {
    paddingHorizontal: 16,
    paddingBottom: dimens.screenVerticalPadding * 2,
    alignItems: "stretch",
  },
  // headerImageWrapperIn: {
  //     backgroundColor: '#fff',
  //     width: avatarSize,
  //     height: avatarSize,
  //     borderRadius: avatarSize / 2
  // },
  // headerImageWrapper: {
  //     width: avatarSize + 8,
  //     height: avatarSize + 8,
  //     // backgroundColor: colors.primaryColorDark,// DPColorUtil.lighten(colors.primaryColor, .5),
  //     marginEnd: 16,
  //     borderWidth: 4,
  //     borderColor: DPColorUtil.lighten(colors.accentColor, .6),
  //     borderRadius: (avatarSize + 8) / 2,
  //     alignItems: 'center',
  //     justifyContent: 'center'
  // },
  title: {
    ...styles.growWidth,
    color: "#fff",
    textAlignVertical: "center",
  },
  closeBtn: {
    marginEnd: 0,
    marginTop: dimens.statusBarHeight,
    minHeight: 48,
    minWidth: 48,
    position: "absolute",
    end: 0,
    top: 0,
  },
  progress: {
    width: "100%",
    backgroundColor: "rgba(0,0,0,.2)",
    borderRadius: 2,
  },
  progressLabelBox: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 16,
  },
}));
