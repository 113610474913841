import * as React from 'react';
import {inject, observer} from 'mobx-react';
import {BackHandler, Image, View, ViewStyle} from 'react-native';
import {
    ADButton,
    ADForm,
    ADRequiredValidator,
    ADResponsiveUtil,
    ADScreen,
    ADText,
    ADTextInput,
    Col,
    DPConfig,
    DPI18n,
    DPScrollView,
    DPStyleSheet,
    DPToolbar,
    INavigationService,
    InjectedAuthServiceProps,
    InjectedNavigationServiceProps,
    InjectedStorageServiceProps,
    Row
} from '@dp/react-native-core';
import InjectedApiServiceProps from 'app/api/InjectedApiServiceProps';
import VerificationScreenStore from './VerificationScreenStore';
import Assets from 'app/assets/Assets';
import autobind from 'autobind-decorator';
import {i18n} from '../../../i18n/i18n';


type VerificationScreenProps = InjectedNavigationServiceProps &
    InjectedAuthServiceProps &
    InjectedStorageServiceProps &
    InjectedApiServiceProps & {
    mobile: string
}

@inject('$navigation', '$api', '$auth', '$config', '$storage')
@observer
export default class VerificationScreen extends React.Component<VerificationScreenProps> {

    protected store: VerificationScreenStore
    static RouteName = 'VerificationScreen'

    static start(navigation: INavigationService, mobile: string) {
        navigation.navigate(VerificationScreen.RouteName, {
            mobile
        })
    }

    constructor(p, c) {
        super(p, c);
        this.store = new VerificationScreenStore({
            $api: this.props.$api,
            $auth: this.props.$auth,
            mobile: this.props.mobile,
            $navigation: this.props.$navigation,
            $storage: this.props.$storage,
            counterTime: 60
        })
    }

    componentDidMount() {
        this.store.timer.start()
    }

    render() {
        let trans = i18n.strings.screens.verification;

        return (
            <ADScreen
                style={{backgroundColor: '#fff'}}
                backConfirmMessage={trans.back_to_login_confirm}>
                <DPToolbar onBackPress={this.handleBackPress} transparent={true} shadow={false}/>
                <ADForm store={this.store.form}>
                    <DPScrollView contentContainerStyle={{flexGrow: 1, alignItems: 'center'}}>
                        <Col style={styles.container}>
                            <Col style={{}}/>
                            <Image
                                style={styles.logo}
                                resizeMode={'contain'}
                                source={Assets.images.logo}
                            />
                            <Col style={{flex: 2}}/>
                            <ADText
                                title={i18n.t(trans.note, {mobile: this.props.mobile})}
                                color={DPConfig.colors.defaultTextColor}
                                textAlign={'start'}
                                style={{
                                    alignSelf: 'flex-start',
                                }}
                            />
                            <Row style={{flexShrink: 0, alignSelf: 'stretch'}}>
                                <ADTextInput
                                    $id={'code'}
                                    $validators={[new ADRequiredValidator(trans.code_required)]}
                                    testID={'verification_text_note'}
                                    label={trans.code}
                                    autoFocus={false}
                                    keyboardType={'numeric'}
                                    clearColor={DPConfig.colors.defaultTextColor}
                                    baseColor={DPConfig.colors.defaultTextColor}
                                    tintColor={DPConfig.colors.defaultTextColor}
                                    blurOnSubmit={true}
                                    onSubmitEditing={this.store.login}
                                    textStyle={{
                                        // minHeight: 32,
                                        color: DPConfig.colors.defaultTextColor,
                                        textAlign: 'left'
                                    }}
                                    style={{marginVertical: 16, flex: 1}}
                                />
                            </Row>
                            <Row style={{flexShrink: 0, alignSelf: 'stretch'}}>
                                <ADButton
                                    testID={'verification_button_login'}
                                    onPress={this.store.login}
                                    title={trans.btn_login}
                                    textStyle={{
                                        // minHeight: 48,
                                        fontSize: ADResponsiveUtil.plus(16, 2),
                                    }}
                                    block
                                    primary
                                    filled
                                    style={{borderRadius: 32}}/>
                            </Row>
                            <Row style={{flexShrink: 0, alignSelf: 'stretch'}}>
                                <ADButton
                                    testID={'verification_button_resend'}
                                    onPress={() => this.store.timer.isFinished && this.store.resend(this.props.mobile)}
                                    title={
                                        this.store.timer.current > 0 ?
                                            i18n.t(trans.resend_timer, {second: this.store.timer.current}) :
                                            trans.resend
                                    }
                                    block
                                    accent
                                    borderLess
                                    textStyle={{
                                        color: this.store.timer.isFinished ?
                                            DPConfig.colors.primaryColor :
                                            DPConfig.colors.defaultIconColor,
                                        fontSize: ADResponsiveUtil.plus(16, 2),
                                    }}
                                    style={{
                                        borderRadius: 32
                                    }}/>
                            </Row>
                            <View style={styles.bottomGap}/>
                        </Col>
                    </DPScrollView>
                </ADForm>
            </ADScreen>
        )
    }

    @autobind
    handleBackPress() {
        if (this.props.$navigation.state.canGoBack) {
            this.props.$navigation.back();
            return true;
        }
        BackHandler.exitApp();
        return false
    }

}
const LOGO_WIDTH = ADResponsiveUtil.size(ADResponsiveUtil.width * .7, 100, 300);
const LOGO_HEIGHT = LOGO_WIDTH * 238 / 514;
const styles = DPStyleSheet.create(({colors, dimens}) => {
    return {
        container: {
            flexGrow: 1,
            alignItems: 'center',
            paddingHorizontal: dimens.screenHorizontalPadding,
            paddingTop: dimens.screenVerticalPadding,
            maxWidth: 500
        } as ViewStyle,
        logo: {
            width: LOGO_WIDTH,
            height: LOGO_HEIGHT,
            flexShrink: 1,
            flexGrow: 1,
            marginVertical: dimens.screenVerticalPadding
        },
        bottomGap: {
            height: dimens.screenVerticalPadding + dimens.safeAreaInsets.bottom,
            flexGrow: 1
        }
    }
});