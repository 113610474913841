import {
  ADButton,
  ADDateInput,
  ADForm,
  ADFormStore,
  ADHttpRequestFile,
  ADResponsiveUtil,
  ADScreen,
  ADText,
  AlertDialog,
  Col,
  DPConfig,
  DPProgressBar,
  DPScrollView,
  DPStyleSheet,
  IDType,
  INavigationService,
  InjectedNavigationServiceProps,
  Row,
} from "@dp/react-native-core";
import DPDefaultStyles from "@dp/react-native-core/dist/dpcoresrc/config/DPDefaultStyles";
import InjectedApiServiceProps from "app/api/InjectedApiServiceProps";
import autobind from "autobind-decorator";
import { inject, Observer, observer } from "mobx-react";
import * as React from "react";
import { i18n } from "../../../i18n/i18n";
import { AppToolbar } from "../../common/AppToolbar";
import { ViewStyle } from "react-native";
import { from } from "jalali-moment";
import FormSection from "./FormSection";

type Params = {
  projectId: IDType;
  onSuccess?: () => void;
};

export type TimesStateModel = {
  substruction_time: number;
  paneling_time: number;
  sealing_time: number;
};

type TechnicalScreenProps = Params &
  InjectedApiServiceProps &
  InjectedNavigationServiceProps;

type State = {};

@inject("$navigation", "$api")
@observer
export default class TimesScreen extends React.Component<
  TechnicalScreenProps,
  State
> {
  static RouteName = "TimesScreen";

  static start(nav: INavigationService, param: Params) {
    nav.navigate(TimesScreen.RouteName, param);
  }

  protected readonly form = new ADFormStore<TimesStateModel>();

  constructor(props: Readonly<TechnicalScreenProps>) {
    super(props);
    this.form.validators.set("substruction_time", [
      {
        check: (value: number) => {
          return !!value;
        },
        message: i18n.strings.screens.times.validation.substruction_time,
      },
    ]);
    this.form.validators.set("paneling_time", [
      {
        check: (value: number) => {
          return !!value;
        },
        message: i18n.strings.screens.times.validation.paneling_time,
      },
    ]);
    this.form.validators.set("sealing_time", [
      {
        check: (value: number) => {
          return !!value;
        },
        message: i18n.strings.screens.times.validation.sealing_time,
      },
    ]);
  }

  render() {
    let trans = i18n.strings.screens.times;
    return (
      <ADScreen
        style={{ backgroundColor: "#fff" }}
        backConfirmMessage={trans.close_confirm}
        onBackConfirm={this.props.$navigation.back}
      >
        <AppToolbar theme={"light"} title={trans.title} />
        <ADForm store={this.form}>
          <DPScrollView
            extraScrollHeight={ADResponsiveUtil.size(
              DPConfig.dimens.screen.height / 4,
              50,
              150
            )}
          >
            <FormSection
              style={{ marginTop: DPConfig.dimens.screenVerticalPadding }}
              label={i18n.strings.screens.times.inputs.substruction_time}
              color={DPConfig.colors.secondaryColor}
            >
              <ADDateInput
                placeholder={i18n.strings.screens.times.select_date}
                $id={"substruction_time"}
                style={{ flex: 1, marginTop: 8 }}
                fontSize={14}
              />
            </FormSection>
            <FormSection
              style={{ marginTop: 16 }}
              label={i18n.strings.screens.times.inputs.paneling_time}
              color={DPConfig.colors.secondaryColor}
            >
              <ADDateInput
                placeholder={i18n.strings.screens.times.select_date}
                $id={"paneling_time"}
                style={{ flex: 1 }}
                fontSize={14}
              />
            </FormSection>
            <FormSection
              style={{ marginTop: 16 }}
              label={i18n.strings.screens.times.inputs.sealing_time}
              color={DPConfig.colors.secondaryColor}
            >
              <ADDateInput
                placeholder={i18n.strings.screens.times.select_date}
                $id={"sealing_time"}
                style={{ flex: 1 }}
                fontSize={14}
              />
            </FormSection>
            <Row style={[styles.row, { marginVertical: 16 }]}>
              <ADButton
                title={trans.save}
                filled
                block
                accent
                onSubmit={this.handleSubmit}
              />
            </Row>
          </DPScrollView>
        </ADForm>
      </ADScreen>
    );
  }

  @autobind
  private handleSubmit(data) {
    let saveConfirm = i18n.strings.screens.times.save_confirm;
    let saveSuccess = i18n.strings.screens.times.save_success;

    let form = new ADFormStore<{ progress: number }>();
    let loading = new AlertDialog.Builder()
      .setLoadingEnabled(true)
      .setCancelable(false)
      .setContent(
        <Observer>
          {() => (
            <DPProgressBar
              value={form.data.get("progress") as number}
              style={{ backgroundColor: "#d1d1d1" }}
            />
          )}
        </Observer>
      )
      .small()
      .create();

    new AlertDialog.Builder()
      .setTitle(saveConfirm.title)
      .setMessage(saveConfirm.message)
      .setNegativeButton(saveConfirm.cancel)
      .setPositiveButton(saveConfirm.confirm, () => {
        this.props.$api
          .setProjectTimesState(
            {
              ...data,
              project_id: this.props.projectId,
            },
            (p) => {
              form.setData("progress", p.percent);
            }
          )
          .defaultError()
          .onLoading((enabled) => {
            if (enabled) loading.show();
            else loading.hide();
          })
          .onSuccess((response, call) => {
            new AlertDialog.Builder()
              .setTitle(saveSuccess.title)
              .setMessage(response.message || saveSuccess.message)
              .setPositiveButton(saveSuccess.ok)
              .setOnVisibilityChanged((isVisible) => {
                if (!isVisible) {
                  this.props.$navigation.back();
                  this.props.onSuccess && this.props.onSuccess();
                }
              })
              .setCancelable(false)
              .create()
              .show();
          })
          .enqueue();
      })
      .create()
      .show();
  }

  @autobind
  private showHelp(help: string) {
    new AlertDialog.Builder()
      .setTitle(i18n.strings.screens.technical.help.title)
      .setMessage(help)
      .setPositiveButton(i18n.strings.screens.technical.help.ok)
      .create()
      .show();
  }
}

const styles = DPStyleSheet.create(({ dimens, colors }) => ({
  row: {
    marginHorizontal: dimens.screenHorizontalPadding,
  },
  sale_agent_sub_item: {
    marginHorizontal: dimens.screenHorizontalPadding,
  },
  question: {
    backgroundColor: "rgba(0,173,239,.05)",
    padding: 8,
    borderColor: colors.accentColor,
    borderWidth: 1,
    borderRadius: 2,
    marginTop: 16,
  },
  uploads_images: {
    backgroundColor: colors.primaryColor,
    padding: 8,
    borderRadius: 2,
    marginTop: 16,
  },
  uploads_factors: {
    backgroundColor: colors.secondaryColor,
    padding: 8,
    borderRadius: 2,
    marginTop: 16,
  },
}));
