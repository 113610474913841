export default {
    fonts : {},
    images : {
        dashboard_header_back : require('./images/dashboard-header-back.png'),
        dashboard_project_back : require('./images/dashboard-project-back.png'),
        drawer_header_back : require('./images/drawer-header-back.png'),
        ic_location_blue : require('./images/ic-location-blue.png'),
        ic_mobile : require('./images/ic-mobile.png'),
        ic_news : require('./images/ic-news.png'),
        ic_offer_gift : require('./images/ic-offer-gift.png'),
        ic_offer : require('./images/ic-offer.png'),
        ic_support : require('./images/ic-support.png'),
        ic_toolbar_menu_black : require('./images/ic-toolbar-menu-black.png'),
        ic_toolbar_menu : require('./images/ic-toolbar-menu.png'),
        ic_upload : require('./images/ic-upload.png'),
        ic_user_project : require('./images/ic-user-project.png'),
        intro_back : require('./images/intro-back.png'),
        intro_step_1 : require('./images/intro-step-1.png'),
        intro_step_2 : require('./images/intro-step-2.png'),
        intro_step_3 : require('./images/intro-step-3.png'),
        intro_step_4 : require('./images/intro-step-4.png'),
        logo : require('./images/logo.png'),
        profile_background : require('./images/profile-background.png'),
        project_filter_back : require('./images/project-filter-back.png'),
        setting_background : require('./images/setting-background.png'),
        splash_1_back : require('./images/splash-1-back.png'),
        splash_1_phone : require('./images/splash-1-phone.png'),
        splash_1_tree : require('./images/splash-1-tree.png'),
        splash_1_worker : require('./images/splash-1-worker.png'),
        splash_2_back : require('./images/splash-2-back.jpg'),
    },
    sounds : {},
  }