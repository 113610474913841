import * as React from 'react';
import {inject, observer} from 'mobx-react';
import autobind from 'autobind-decorator';
import {
    ADButton,
    ADImage,
    ADRemoteView,
    ADResponsiveUtil,
    ADScreen,
    ADText,
    AlertDialog,
    Col,
    DPColorUtil,
    DPConfig,
    DPScrollView,
    DPStyleSheet,
    INavigationService,
    InjectedConfigServiceProps,
    InjectedNavigationServiceProps,
    Row
} from '@dp/react-native-core';
import Assets from 'app/assets/Assets';
import {AppToolbar} from 'app/screens/common/AppToolbar';
import {OfferRequestScreenInfoModel} from 'app/api/Models';
import InjectedApiServiceProps from 'app/api/InjectedApiServiceProps';
import {ViewStyle} from 'react-native';
import OfferListScreen from '../offer-list/OfferListScreen';
import {i18n} from '../../../../i18n/i18n';

type Params = {}

type OfferRequestScreenProps =
    Params &
    InjectedApiServiceProps &
    InjectedConfigServiceProps &
    InjectedNavigationServiceProps

type State = {}

@inject('$navigation', '$api', '$config')
@observer
export default class OfferRequestScreen extends React.Component<OfferRequestScreenProps, State> {
    static RouteName = 'OfferRequestScreen'

    static start(nav: INavigationService, param?: Params) {
        nav.navigate(OfferRequestScreen.RouteName, param)
    }


    protected api = DPConfig.http.createResource<{ id }, OfferRequestScreenInfoModel>((p) => {
        return this.props.$api.getOfferScreenInfo({...p})
    });

    render() {
        let trans = i18n.strings.screens.discount_request;
        return (
            <ADScreen>
                <AppToolbar
                    theme={'light'}
                    title={trans.title}/>
                <ADRemoteView api={this.api}>
                    <DPScrollView
                        api={this.api}
                        contentContainerStyle={styles.container}>

                        <ADImage
                            source={Assets.images.ic_offer_gift}
                            style={{
                                width: IMAGE_WIDTH,
                                height: IMAGE_HEIGHT,
                                marginTop: DPConfig.dimens.screenVerticalPadding,
                                marginBottom: 32
                            }}
                        />
                        {/* <ADIcon
                            icon={'star-border'}
                            color={DPConfig.colors.secondaryColor}
                            size={40}
                        />*/}
                        <Col style={styles.scoreBox}>
                            <Row style={styles.scoreRect}>
                                <ADText
                                    style={styles.score}
                                    bold
                                    fontSize={14}
                                    color={'#000'}
                                >
                                    {trans.your_score}
                                    {this.api.data.total_score}
                                    {trans.your_score_suffix}
                                </ADText>
                            </Row>
                            <Row style={styles.scoreTopArrow}/>
                        </Col>
                        <Row style={{alignSelf: 'stretch'}}>
                            <ADText
                                fontSize={14}
                                style={DPConfig.styles.growWidth}
                                title={this.api.data.note}/>
                        </Row>
                        <Row style={{marginVertical: 16}}>
                            <ADButton
                                filled
                                block
                                primary={!!this.api.data.enabled}
                                color={this.api.data.enabled ? undefined : '#d1d1d1'}
                                disabled={!this.api.data.enabled}
                                shadow={!!this.api.data.enabled}
                                title={trans.convert}
                                onPress={this.handleReceivePress}
                            />
                        </Row>
                        <Row style={{marginBottom: DPConfig.dimens.screenVerticalPadding}}>
                            <ADButton
                                accent
                                filled
                                block
                                title={trans.show_list}
                                onPress={this.handleViewPress}
                            />
                        </Row>
                    </DPScrollView>
                </ADRemoteView>
            </ADScreen>
        )
    }


    @autobind
    handleReceivePress() {
        let trans = i18n.strings.screens.discount_request.confirm;
        new AlertDialog.Builder()
            .setTitle(trans.title)
            .setMessage(i18n.t(trans.message, {score: this.api.data.total_score}))
            .setPositiveButton(trans.convert, () => {
                this.props.$api.convertScoreToOfferCode()
                    .onSuccess((response) => {
                        this.api.onSuccess(response, this.api.lastCall);
                        this.props.$api.offerListApi.loadFirstPage();
                        new AlertDialog.Builder()
                            .setTitle(trans.convert_success.title)
                            .setMessage(response.message || trans.convert_success.message)
                            .setPositiveButton(trans.convert_success.ok)
                            .setOnVisibilityChanged(isVisible => {
                                if (!isVisible) {
                                    OfferListScreen.start(this.props.$navigation)
                                }
                            })
                            .create()
                            .show()
                    })
                    .defaultLoading()
                    .defaultError()
                    .enqueue()

            })
            .setNegativeButton(trans.cancel)
            .create()
            .show()

    }

    @autobind
    handleViewPress() {
        OfferListScreen.start(this.props.$navigation);
    }
}

const IMAGE_WIDTH = ADResponsiveUtil.size(ADResponsiveUtil.width * .7, 100, 300);
const IMAGE_HEIGHT = IMAGE_WIDTH * 445 / 656
const ArrowSize = 12;
const styles = DPStyleSheet.create(({colors, dimens, styles}) => ({
    container: {
        paddingHorizontal: dimens.screenHorizontalPadding,
        paddingVertical: dimens.screenVerticalPadding + dimens.safeAreaInsets.bottom,
        alignItems: 'center'
    },
    scoreBox: {
        alignSelf: 'stretch',
        alignItems: 'center',
        marginTop: 8,
        marginBottom: 16,
    },
    scoreRect: {
        alignSelf: 'stretch',
        justifyContent: 'center',
        padding: 32,
        backgroundColor: DPColorUtil.lighten(colors.secondaryColor, .8),
        borderColor: colors.secondaryColor,
        borderWidth: 2,
        borderRadius: 4,
        marginTop: ArrowSize / 2
    },
    scoreTopArrow: {
        // width: ArrowSize, height: ArrowSize,
        // backgroundColor: DPColorUtil.lighten(colors.secondaryColor, .8),
        // borderTopColor: colors.secondaryColor,
        // borderLeftColor: colors.secondaryColor,
        // borderTopWidth: 2,
        // borderStartWidth: 2,
        // borderRadius: 2,
        // transform: [
        //     {
        //         rotate: '-45deg'
        //     },
        // ],
        // position: 'absolute',
        // top: 0,
    } as ViewStyle,
    score: {}
}));
