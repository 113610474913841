import ApiResponseModel from './ApiResponseModel';
import autobind from 'autobind-decorator';
import {ADResource, ADCall, ResourceParsedResponse} from '@dp/react-native-core';

@autobind
export default class DPResource<RequestType, DataType, ResponseType = ApiResponseModel<DataType>>
    extends ADResource<RequestType, ResponseType, DataType> {

    constructor(callMaker: (p?: RequestType) => ADCall<ApiResponseModel<DataType>>) {
        // @ts-ignore
        super(callMaker);
    }

    // @ts-ignore
    protected parseResponse(response: ApiResponseModel<DataType>): ResourceParsedResponse<DataType> {
        return {
            data: !!response ? response.data : undefined,
            message: response.message
        };
    }

    clone() {
        // @ts-ignore
        return new DPResource(super._callMaker);
    }
}