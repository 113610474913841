/**
 * react-native-web empty polyfill.
 */
import json from '../../package.json'

function getFirstBrowserLanguage() {
    let nav = window.navigator,
        browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
        i,
        language;

    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages)) {
        for (i = 0; i < nav.languages.length; i++) {
            language = nav.languages[i];
            if (language && language.length) {
                return language;
            }
        }
    }

    // support for other well known properties in browsers
    for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
        language = nav[browserLanguagePropertyKeys[i]];
        if (language && language.length) {
            return language;
        }
    }

    return null;
};
let locale = getFirstBrowserLanguage() || 'fa';
const DeviceInfo = {
    getBatteryLevel: () => Promise.resolve(0),
    getAPILevel: () => Promise.resolve(0),
    getBuildNumber: () => (json.buildnumber || 0),
    getVersion: () => (json.version || '0.0'),
    getDeviceLocale: () => locale,
    getUniqueID: function () {
        return '';
    },
    getInstanceID: function () {
        return '';
    },
    getSerialNumber: function () {
        return  '';
    },
    getIPAddress: function () {
        return  '';
    },
    getMACAddress: function () {
        return '';
    },
    getDeviceId: function () {
        return '';
    },
    getManufacturer: function () {
        return  '';
    },
    getModel: function () {
        return '';
    },
    getBrand: function () {
        return  '';
    },
    getSystemName: function () {
        return  '';
    },
    getSystemVersion: function () {
        return  0;
    },

    getBundleId: function () {
        return  '';
    },
    getApplicationName: function () {
        return  '';
    },

    getReadableVersion: function () {
        return (json.version || '0.0') + '.' + (json.buildnumber || 0);
    },
    getDeviceName: function () {
        return '';
    },
    getUserAgent: function () {
        return '';
    },
    getDeviceCountry: function () {
        return '';
    },
    getTimezone: function () {
        return '';
    },
    getFontScale: function () {
        return false;
    },
    isEmulator: function () {
        return false;
    },
    isTablet: function () {
        return false;
    },
    is24Hour: function () {
        return false;
    },

    getFirstInstallTime: function () {
        return ''
    },
    getInstallReferrer: function () {
        return '';
    },
    getLastUpdateTime: function () {
        return 0;
    },
    getPhoneNumber: function () {
        return '';
    },
    getCarrier: function () {
        return '';
    },
    getTotalMemory: function () {
        return 0;
    },
    getMaxMemory: function () {
        return 0;
    },
    getTotalDiskCapacity: function () {
        return 0;
    },
    getFreeDiskStorage: function () {
        return 0;
    },
};

export default DeviceInfo;