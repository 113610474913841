import {ADIcon, ADResponsiveUtil, DPConfig, DPToolbar, DPToolbarProps, InjectedNavigationServiceProps} from '@dp/react-native-core';
import * as React from 'react';
import Assets from '../../assets/Assets';
import {View} from 'react-native-animatable';
import {inject} from 'mobx-react';
import InjectedApiServiceProps from '../../api/InjectedApiServiceProps';
import ProjectListScreen from '../dashboard/project/project-list/ProjectListScreen';
import MainScreen from '../dashboard/main/MainScreen';

type Props = DPToolbarProps &
    Partial<InjectedNavigationServiceProps> &
    Partial<InjectedApiServiceProps> &
    {
        theme: 'light' | 'dark',
        homeEnabled?: boolean
    }

@inject('$api', '$navigation')
export class AppToolbar extends React.Component<Props> {
    static defaultProps = {
        homeEnabled: true
    }

    render() {
        let props = this.props;

        let isLight = props.theme === 'light';
        let actions = props.actions || [];
        let color = isLight ? '#000' : DPConfig.colors.accentTextColor;
        let notificationBadgedEnabled;
        let homeColor = isLight ? DPConfig.colors.accentColor : DPConfig.colors.accentTextColor;

        if (props.$api) {
            notificationBadgedEnabled = props.$api.dashboardApi.data.notification_unread_count &&
                props.$api.dashboardApi.data.notification_unread_count > 0;
        }
        if (notificationBadgedEnabled)
            actions.push({
                Icon: (
                    <NotificationIcon color={color} active={notificationBadgedEnabled}/>
                ),
                onPress: () => {
                    this.props.$navigation &&
                    ProjectListScreen.start(this.props.$navigation)
                }
            });
        if (this.props.homeEnabled)
            actions.push({
                Icon: <ADIcon icon={'home-outline'}
                              type={'MaterialCommunityIcons'}
                              color={homeColor}/>,
                onPress: () => {
                    this.props.$navigation &&
                    MainScreen.start(this.props.$navigation)
                }
            });
        return (
            <DPToolbar
                textColor={color}
                shadow={!props.transparent}
                color={isLight ? DPConfig.colors.screenBackgroundColorDark : DPConfig.colors.accentColor}
                menuIcon={
                    isLight ? Assets.images.ic_toolbar_menu_black : Assets.images.ic_toolbar_menu
                }
                // backIcon={{
                //     icon: 'arrow-back',
                //     mirror:false
                // }}
                menuEnabled
                titleStyle={{
                    fontSize: ADResponsiveUtil.plus(18, 2),
                    lineHeight: ADResponsiveUtil.plus(18, 2) * 2,
                }}
                {...props}
                notificationBadgedEnabled={false}
                actions={actions}
            />
        )
    }
}

export default AppToolbar;

const NotificationIcon = ({color, active}) => (
    <View animation={active ? 'swing' : undefined}
          iterationDelay={1000}
          iterationCount={'infinite'}>
        <ADIcon
            icon={'notifications-none'}
            color={color}
        />
        {
            !!active &&
            <View
                animation={active ? 'zoomIn' : undefined}
                delay={1000}
                style={{
                    width: 12,
                    height: 12,
                    borderRadius: 6,
                    borderWidth: 2,
                    borderColor: color,
                    position: 'absolute',
                    backgroundColor: DPConfig.colors.secondaryColor,
                    top: 2,
                    start: 2
                }}
            />
        }
    </View>
)

