import autobind from 'autobind-decorator';
import {
    ADForm,
    ADRequiredValidator,
    ADTextInput,
    AlertDialog,
    DPConfig,
    DPSelectUtil,
    InjectedNavigationServiceProps,
    Row,
    ADFormStore
} from '@dp/react-native-core';
import InjectedApiServiceProps from 'app/api/InjectedApiServiceProps';
import {ProjectConfirmForm, ProjectDetailsModel, ProjectStateType} from 'app/api/Models';
import {ProjectDetailsScreenParams} from './ProjectDetailsScreen';
import * as React from 'react';
import {ProjectConfirmModal} from './ProjectConfirmModal';
import {action, observable} from 'mobx';
import {i18n} from '../../../../i18n/i18n';

type Services = InjectedNavigationServiceProps &
    InjectedApiServiceProps &
    ProjectDetailsScreenParams

@autobind
export default class ProjectStore {
    private $: Services;
    public readonly projectInfoApi = DPConfig.http.createResource<{ id }, ProjectDetailsModel>((p) => {
        return this.$.$api.getProjectInfo({...p, projectId: this.$.projectId})
    });

    constructor(s: Services) {
        this.$ = s
    }

    get nextState(): ProjectStateType | undefined {
        let data = this.projectInfoApi.data;
        if (data.next_state)
            return data.next_state;
        let state = data.state;

        return DPSelectUtil.select(state, {
            'pending': 'accepted',
            'accepted': 'started',
            'started': 'technical',
            'technical': 'done',
            'done': undefined
        }) as ProjectStateType;
    }

    handleAcceptPress() {
        this.updateState('accepted');
    }

    handleStartPress() {
        this.updateState('started');
    }

    handleDonePress() {
        this.updateState('done');
    }


    @observable.ref
    confirmFormInfo: ProjectConfirmForm = {};

    @observable
    isConfirmFormVisible = false;

    @observable
    protected pendingConfirmState: ProjectStateType

    @action
    hideConfirmModal() {
        this.isConfirmFormVisible = false;
    }

    handleSubmitConfirm(code: string) {
        this.updateState(this.pendingConfirmState, code)
    }

    @action
    showConfirmModal(state: ProjectStateType, p: ProjectConfirmForm) {
        this.confirmFormInfo = p;
        this.pendingConfirmState = state;
        this.isConfirmFormVisible = true;


        // new AlertDialog.Builder()
        //     .setTitle(p.title)
        //     .setMessage(p.message)
        //     .setContent(
        //         <ADForm store={this.confirmForm}>
        //             <Row>
        //                 <ADTextInput
        //                     style={{flex: 1}}
        //                     label={'کد تایید'}
        //                     help={p.help || 'کد برای مشتری پیامک شده است.'}
        //                     $validators={[new ADRequiredValidator()]}
        //                     $id={'code'}/>
        //             </Row>
        //         </ADForm>
        //     )
        //     .setNegativeButton('انصراف', () => {
        //         this.confirmForm.clear();
        //         resolve(undefined)
        //     })
        //     .setPositiveButton(p.button || 'ارسال', () => {
        //         if (!this.confirmForm.validate())
        //             return true; // prevent close
        //         let code = this.confirmForm.data.get('code');
        //         this.confirmForm.clear();
        //         resolve(code)
        //         return;
        //     })
        //     .setCancelable(false)
        //     .create()
        //     .show();
    }

    protected updateState(state: ProjectStateType, code?: string) {
        this.$.$api.updateProjectState({project_id: this.$.projectId, state: state, confirm_code: code})
            .onSuccess(async (response, call) => {
                let confirm = response.data.confirm;
                // when we have confirm we dont have project as response
                if (confirm) {
                    this.showConfirmModal(state, confirm);
                } else {
                    this.hideConfirmModal();
                    this.projectInfoApi.onSuccess(response, call)
                    if(response.message){
                        new AlertDialog.Builder()
                            .setTitle(response['title'])
                            .setMessage(response.message)
                            .setPositiveButton(i18n.strings.screens.project_details.success.ok)
                            .create()
                            .show()
                    }
                }
            })
            .defaultLoading()
            .defaultError()
            .enqueue();
    }

    //
    // protected requestConfirmCode(state: 'started' | 'done'): Promise<false | { message?: string, }> {
    //     return new Promise((resolve, reject) => {
    //         this.$.$api.requestProjectConfirmCode({project_id: this.$.projectId, new_state: state})
    //             .defaultLoading()
    //             .defaultError()
    //             .onSuccess(response => resolve(response))
    //             .onError(error => resolve(false))
    //             .enqueue();
    //     })
    // }

}