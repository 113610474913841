import React, {Fragment} from 'react'
import renderHTML from 'react-render-html';

const x = '<p>test</p>'
export default function (props/*:{value}*/) {
    props.value = x
    return (
        //{renderHTML(props.value || props.children || props.html || '')}
        <div>
            {renderHTML(props.value || props.children || props.html || '')}
        </div>
    )
}
