import * as React from "react";
import { inject, observer } from "mobx-react";
import autobind from "autobind-decorator";
import {
  ADButton,
  ADForm,
  ADFormStore,
  ADIcon,
  ADImage,
  ADRemoteView,
  ADResponsiveUtil,
  ADScreen,
  ADText,
  ADTextInput,
  AlertDialog,
  Col,
  DPCheckBoxInput,
  DPColorUtil,
  DPConfig,
  DPScrollView,
  DPStyleSheet,
  INavigationService,
  InjectedNavigationServiceProps,
  Row,
} from "@dp/react-native-core";
import InjectedApiServiceProps from "app/api/InjectedApiServiceProps";
import {
  ProfileModel,
  ProfileUpdateModel,
  ProfileScoreModel,
} from "app/api/Models";
import { AppToolbar } from "app/screens/common/AppToolbar";
import {
  Animated,
  LayoutChangeEvent,
  View,
  Text,
  TouchableOpacity,
} from "react-native";
import Assets from "../../../assets/Assets";
import { View as AnimatedView } from "react-native-animatable";
import { i18n } from "../../../i18n/i18n";
import ScoreHistoryScreen from "../score-history/ScoreHistoryScreen";
import PerformanceLabel from "./PerformanceLabel";

type Params = {
  // id: IDType
};

type ProfileScreenProps = Params &
  InjectedNavigationServiceProps &
  InjectedApiServiceProps;

type State = {
  headerHeight?: number;
};

@inject("$navigation", "$api")
@observer
export default class ProfileScreen extends React.Component<
  ProfileScreenProps,
  State
> {
  static RouteName = "ProfileScreen";

  static start(nav: INavigationService, param?: Params) {
    nav.navigate(ProfileScreen.RouteName, param);
  }

  state = {
    headerHeight: ADResponsiveUtil.height / 4,
  };
  protected form = new ADFormStore<ProfileUpdateModel>();
  protected api = DPConfig.http.createResource<{ id }, ProfileModel>((p) => {
    return this.props.$api.getProfileInfo({ ...p }).onSuccess((response) => {
      this.form.clear();
      this.form.setData("mobile", response.data.mobile);
      if (response.data.address)
        this.form.setData("address", response.data.address);

      this.form.setStaticData("courses", response.data.courses_items);
      this.form.setData("courses", response.data.courses);
      this.apiProfileScore.load({});
    });
  });
  protected apiProfileScore = DPConfig.http.createResource<
    {},
    ProfileScoreModel
  >((p) => {
    return this.props.$api.getProfileScore(p).onSuccess((response) => {
      this.form.setData("performance_title", response.data.performance_title);
      this.forceUpdate();
    });
  });
  scrollValue = new Animated.Value(0);

  render() {
    let data: ProfileModel = this.api.data;
    return (
      <ADForm store={this.form}>
        <ADScreen>
          <ADImage
            style={styles.back}
            resizeMode={"contain"}
            source={Assets.images.profile_background}
          />
          <DPScrollView
            style={{ backgroundColor: "#009EEE" }}
            api={this.api}
            animatedScrollValue={this.scrollValue}
          >
            <Col
              style={{
                flexGrow: 1,
                backgroundColor: DPConfig.colors.screenBackgroundColor,
              }}
            >
              {this.renderHeader(data)}
              <ADRemoteView api={this.api} autoLoad>
                <Col style={styles.form}>
                  <Row style={{ alignItems: "flex-start" }}>
                    <ADImage
                      source={Assets.images.ic_mobile}
                      style={{ width: 24, marginEnd: 8, height: "100%" }}
                      resizeMode={"contain"}
                    />
                    <ADTextInput
                      label={i18n.strings.screens.profile.mobile}
                      $id={"mobile"}
                      $nextFocusId={"address"}
                      maxLength={12}
                      clearEnabled={false}
                      textStyle={{
                        textAlign: "left",
                        color: "#9e9e9e",
                      }}
                      editable={false}
                      style={{ flex: 1 }}
                    />
                  </Row>

                  <Row style={{ alignItems: "flex-end" }}>
                    <ADImage
                      source={Assets.images.ic_location_blue}
                      style={{ width: 24, marginEnd: 8, height: 48 }}
                      resizeMode={"contain"}
                    />
                    <ADTextInput
                      label={i18n.strings.screens.profile.address}
                      $id={"address"}
                      multiline
                      validateOnTextChange
                      style={{ flex: 1 }}
                      // textColor={'#fff'}
                      // tintColor={'#222'}
                      // baseColor={'#f1f1f1'}
                    />
                  </Row>
                  {(data.courses_items || []).length > 0 &&
                    this.renderCourses(data)}
                </Col>
              </ADRemoteView>
              <Row
                style={{
                  marginHorizontal: DPConfig.dimens.screenHorizontalPadding,
                  marginTop: 16,
                  marginBottom:
                    DPConfig.dimens.safeAreaInsets.bottom +
                    DPConfig.dimens.screenVerticalPadding,
                }}
              >
                <ADButton
                  secondary
                  filled
                  block
                  medium
                  onSubmit={this.handleSubmit}
                  title={i18n.strings.screens.profile.update}
                />
              </Row>
            </Col>
          </DPScrollView>

          <AppToolbar
            theme={"dark"}
            title={i18n.strings.screens.profile.title}
            textColor={DPConfig.colors.accentTextColor}
            backgroundOpacity={this.scrollValue.interpolate({
              inputRange: [
                0,
                DPConfig.dimens.toolbarHeight +
                  DPConfig.dimens.toolbarPaddingTop,
              ],
              outputRange: [0, 1],
            })}
            shadow={false}
            style={{ position: "absolute", top: 0, left: 0, right: 0 }}
          />
        </ADScreen>
      </ADForm>
    );
  }

  @autobind
  private renderCourses(data: ProfileModel) {
    return (
      <Col style={[styles.section, { marginTop: 16 }]}>
        <Row
          style={{
            alignItems: "center",
            borderBottomWidth: 2,
            borderBottomColor: "#dedede",
            paddingBottom: 8,
          }}
        >
          <ADIcon
            style={{ marginEnd: 8 }}
            icon={"radio-button-checked"}
            color={DPConfig.colors.secondaryColor}
            size={18}
          />
          <ADText fontSize={16} title={i18n.strings.screens.profile.courses} />
          <View style={styles.sectionHeaderUnderLine} />
        </Row>
        {(data.courses_items || []).map((course, index) => (
          <AnimatedView
            animation={"zoomIn"}
            duration={300}
            useNativeDriver
            delay={index * 150}
          >
            <DPCheckBoxInput
              key={index}
              $id={"courses"}
              $groupValue={course.id}
              editable={false}
              label={course.title}
              iconOn={{
                icon: "checkbox-marked-outline",
                type: ADIcon.Types.MaterialCommunityIcons,
              }}
              iconOff={{
                icon: "checkbox-blank-outline",
                type: ADIcon.Types.MaterialCommunityIcons,
              }}
              iconColor={"#727272"}
              labelStyle={{
                color: "#727272",
              }}
              multiSelect
            />
          </AnimatedView>
        ))}
      </Col>
    );
  }

  @autobind
  private renderHeader(data: ProfileModel) {
    return (
      <Col
        style={{
          alignItems: "stretch",
        }}
        onLayout={(e: LayoutChangeEvent) => {
          this.setState({
            headerHeight: e.nativeEvent.layout.height,
          });
        }}
      >
        <ADImage
          resizeMode={"stretch"}
          source={Assets.images.dashboard_header_back}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        />

        <Row
          style={{
            height:
              DPConfig.dimens.toolbarHeight + DPConfig.dimens.statusBarHeight,
          }}
        />

        <Col style={{ alignItems: "center" }}>
          <AnimatedView
            animation={"fadeIn"}
            delay={1000}
            useNativeDriver
            style={styles.headerImageWrapper}
          >
            <View
              style={[styles.headerImageWrapperIn, { alignItems: "center" }]}
            >
              <ADImage
                size={AVATAR_SIZE}
                borderRadius={AVATAR_SIZE / 2}
                style={{
                  borderRadius: AVATAR_SIZE / 2,
                }}
                resizeMode={"cover"}
                source={
                  !!data.image ? data.image : DPConfig.get().images.ic_profile
                }
              />
            </View>
          </AnimatedView>

          <Col style={{ justifyContent: "center", marginTop: 16 }}>
            <Row style={{ marginBottom: 28 }}>
              <Row style={styles.nameBox}>
                <ADText
                  color={"#fff"}
                  numberOfLines={1}
                  style={{ paddingVertical: 2, paddingHorizontal: 8 }}
                  title={data.name || "------"}
                />
              </Row>
              <PerformanceLabel
                title={this.apiProfileScore.data.performance_title}
                clickable
              />
            </Row>
          </Col>
        </Col>
      </Col>
    );
  }

  @autobind
  handleSubmit(data: ProfileUpdateModel) {
    delete data.courses;
    let trans = i18n.strings.screens.profile.update_success;
    this.props.$api
      .updateProfile(data)
      .onSuccess((response) => {
        this.form.error.clear();
        new AlertDialog.Builder()
          .setTitle(trans.title)
          .setMessage(response.message || trans.message)
          .setPositiveButton(trans.ok)
          .create()
          .show();
      })
      .onError((error) => {
        this.form.error.merge(error.validations);
      })
      .defaultLoading()
      .defaultError()
      .enqueue();
  }
}

let AVATAR_SIZE = ADResponsiveUtil.dimen(60, 70, 80);

const styles = DPStyleSheet.create(({ dimens, styles, colors }) => ({
  back: {
    position: "absolute",
    end: -DPConfig.dimens.screen.width * 0.1,
    bottom: -8,
    opacity: 0.5,
    width: DPConfig.dimens.screen.width * 0.4,
    height: ((DPConfig.dimens.screen.width * 0.4) / 389) * 392,
  },
  nameBox: {
    borderRadius: 16,
    borderColor: "#fff",
    borderWidth: 1,
    justifyContent: "center",
    alignItems: "center",
    minWidth: AVATAR_SIZE,
    marginEnd: 2,
  },
  form: {
    marginHorizontal: dimens.screenHorizontalPadding,
    flexGrow: 1,
    alignItems: "stretch",
  },
  section: {
    ...styles.boxShadowBottom,
    backgroundColor: "#fff",
    padding: 16,
    borderRadius: 3,
  },
  headerImageWrapperIn: {
    backgroundColor: "#fff",
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
    borderRadius: AVATAR_SIZE / 2,
  },
  headerImageWrapper: {
    width: AVATAR_SIZE + 8,
    height: AVATAR_SIZE + 8,
    borderWidth: 4,
    borderColor: DPColorUtil.lighten(colors.accentColor, 0.6),
    borderRadius: (AVATAR_SIZE + 8) / 2,
    alignItems: "center",
    justifyContent: "center",
  },
  sectionHeaderUnderLine: {
    position: "absolute",
    start: 0,
    bottom: -2,
    width: 100,
    height: 3,
    backgroundColor: DPConfig.colors.accentColor,
  },
}));
