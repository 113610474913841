import autobind from 'autobind-decorator';
import {action} from 'mobx'
import {
    ADFormStore,
    AndroidSmsReceiverUtil,
    DPConfig,
    DPCounter,
    InjectedAuthServiceProps,
    InjectedNavigationServiceProps,
    InjectedStorageServiceProps,
    SmsParserUtil
} from '@dp/react-native-core';
import InjectedApiServiceProps from 'app/api/InjectedApiServiceProps';
import MainScreen from '../../dashboard/main/MainScreen';
import IntroScreen from '../../intro/IntroScreen';

type S = InjectedAuthServiceProps &
    InjectedNavigationServiceProps &
    InjectedStorageServiceProps &
    InjectedApiServiceProps &
    { mobile: string, counterTime?: number }
@autobind
export default class VerificationScreenStore {
    private $: S;
    public readonly timer
    public readonly form = new ADFormStore<{ code: string, mobile: string }>();

    constructor(s: S) {
        this.$ = s;
        AndroidSmsReceiverUtil.preferOtp = false;
        AndroidSmsReceiverUtil.startListen((message) => {
            let code = SmsParserUtil.findFirstNumberAfter(message.body, ':')
            if (code) {
                this.form.setData('code', code)
            }
        });
        this.timer = new DPCounter(this.$.counterTime || (DPConfig.app.debug ? 5 : 120))
        this.form.setData('mobile', s.mobile);

    }

    @autobind
    login() {
        if (!this.form.validate())
            return;

        this.$.$api.login(this.form.data.toJSON() as any)
            .defaultError(true)
            .defaultLoading(true)
            .onSuccess((response, call) => {
                AndroidSmsReceiverUtil.stopListen();
                let data = response.data;
                if (typeof data === 'object')
                    this.$.$auth.login({accessToken: data.access_token, refreshToken: data.refresh_token});
                else
                    this.$.$auth.login(data + '');
                if (this.$.$auth.isLoggedIn) {
                    IntroScreen.showOnNextStart(this.$.$storage, false);
                    MainScreen.resetToDashboardNavigator(this.$.$navigation);
                }
            })
            .enqueue()
    }

    @action
    resend(mobile: string) {
        this.form.data.delete('code');
        this.timer.reset()
        this.$.$api.requestSmsToken({mobile: mobile})
            .defaultError(true)
            .defaultLoading(true)
            .onSuccess((r) => {
                this.timer.start()
            })
            .enqueue()
    }
}