import * as React from 'react';
import {inject, observer} from 'mobx-react';
import {ViewStyle} from 'react-native';
import autobind from 'autobind-decorator';
import {
    ADButton,
    ADForm,
    ADFormStore,
    ADRemoteView,
    ADResponsiveUtil,
    ADSelectInput,
    ADTextInput,
    AlertDialog,
    Col,
    DPScrollView,
    DPStyleSheet,
    INavigationService,
    InjectedNavigationServiceProps
} from '@dp/react-native-core';
import InjectedApiServiceProps from 'app/api/InjectedApiServiceProps';
import AppToolbar from 'app/screens/common/AppToolbar';
import {i18n} from 'app/i18n/i18n';


type Params = {}

type CreateTicketScreenProps =
    Params &
    InjectedNavigationServiceProps &
    InjectedApiServiceProps

export type CreateTicketModel = { subject, content, priority_id }
@inject('$navigation', '$api')
@observer
export default class CreateTicketScreen extends React.Component<CreateTicketScreenProps, { comment: string }> {
    static RouteName = 'CreateTicketScreen'

    static start(nav: INavigationService) {
        nav.navigate(CreateTicketScreen.RouteName)
    }

    state = {
        comment: ''
    }
    form = new ADFormStore<CreateTicketModel>()

    render() {
        let _items = this.props.$api.ticketPrioritiesListApi.hasData ?
            this.props.$api.ticketPrioritiesListApi.data :
            [];
        let items = _items.map(_ => {
            return {..._, title: _.name}
        });
        let trans=i18n.strings.screens.ticket_create;
        return (
            <Col style={styles.container}>
                <AppToolbar theme={'dark'} title={trans.title}/>
                <ADRemoteView resource={this.props.$api.ticketPrioritiesListApi}>
                    <DPScrollView>
                        <ADForm store={this.form}>
                            <Col style={{paddingHorizontal: ADResponsiveUtil.screenHorizontalPadding}}>

                                <ADSelectInput
                                    label={trans.priority}
                                    $id={'priority_id'}
                                    items={items}/>

                                <ADTextInput
                                    label={trans.subject}
                                    $id={'subject'}
                                />

                                <ADTextInput
                                    label={trans.content}
                                    multiline
                                    $id={'content'}/>

                                <ADButton
                                    filled
                                    style={{marginVertical: 16}}
                                    title={trans.create}
                                    onSubmit={this.submit}
                                />
                            </Col>
                        </ADForm>
                    </DPScrollView>
                </ADRemoteView>
            </Col>
        )
    }

    @autobind
    submit(data: CreateTicketModel & any) {
        let trans=i18n.strings.screens.ticket_create.success
        this.props.$api.createTicket(data)
            .defaultLoading(true)
            .defaultError(true)
            .onSuccess((response, call) => {
                this.form.data.clear();
                this.props.$api.ticketListApi.items.unshift(response.data);
                new AlertDialog.Builder()
                    .setTitle(trans.title)
                    .setMessage(response.message ||trans.message)
                    .setPositiveButton(trans.ok)
                    .setOnVisibilityChanged((isVisible) => {
                        if (!isVisible) {
                            this.props.$navigation.back()
                        }
                    })
                    .create()
                    .show()
            })
            .enqueue()
    }

}

const styles = DPStyleSheet.create(({colors}) => ({
    container: {
        flexGrow: 1,
        backgroundColor: colors.screenBackgroundColor
    } as ViewStyle,
}))