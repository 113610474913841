import * as React from "react";
import {
  ADText,
  ADTouchable,
  Col,
  DPColorUtil,
  DPStyleSheet,
  Row,
  DPConfig,
} from "@dp/react-native-core";
import { StyleProp, View, ViewStyle } from "react-native";
import DPDefaultStyles from "@dp/react-native-core/dist/dpcoresrc/config/DPDefaultStyles";

export interface SectionHeaderProps {
  label?: string;
  color: string;
  style: ViewStyle;
  children: React.ReactChild;
}

export default function FormSection(props: SectionHeaderProps) {
  return (
    <Col
      style={[
        {
          borderStartWidth: 4,
          paddingHorizontal: DPConfig.dimens.screenHorizontalPadding,
          paddingVertical: 8,
          backgroundColor: DPConfig.colors.screenBackgroundColorDark,
        },
        { borderStartColor: props.color },
        props.style,
      ]}
    >
      <Row>
        <ADText
          fontSize={14}
          style={{
            ...DPDefaultStyles.growWidth,
            flex: 1,
          }}
          title={props.label}
        />
      </Row>
      {props.children}
    </Col>
  );
}

const styles = DPStyleSheet.create(({ styles, dimens, colors }) => ({
  container: {
    borderStartWidth: 4,
    paddingHorizontal: dimens.screenHorizontalPadding,
    paddingVertical: 8,
    backgroundColor: colors.screenBackgroundColorDark,
  },
  label: {
    ...styles.growWidth,
    flex: 1,
  },
  helpBox: {
    borderWidth: 1,
    borderRadius: 10,
    height: 24,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 8,
  },
}));
