import * as React from 'react';
import {inject, observer} from 'mobx-react';
import {ADText, ADTouchable, Col, CurrencyUtil, DPConfig, DPStyleSheet, InjectedNavigationServiceProps, Row,} from '@dp/react-native-core';
import {OfferListModel} from 'app/api/Models';
import {ViewStyle} from 'react-native';
import {i18n} from '../../../../i18n/i18n';

export type OfferListScreenItemProps =
    InjectedNavigationServiceProps &
    {
        item: OfferListModel
        onPress?: () => void
    }

type State = {}

@inject('$navigation')
@observer
export default class OfferListScreenItem extends React.Component<OfferListScreenItemProps, State> {
    state = {};

    render() {
        let {item, onPress} = this.props;
        let trans=i18n.strings.screens.discount_list.item;
        return (
            <ADTouchable onPress={onPress} withoutFeedback>
                <Row style={styles.container}>
                    <Col style={styles.ribbonWrapper}>
                        <Row style={[styles.ribbon, item.consumed && styles.ribbonDisabled]}>
                            <ADText
                                title={item.consumed ? trans.consumed : trans.not_consumed}
                                style={[styles.ribbonText, item.consumed && styles.ribbonTextDisabled]}
                            />
                        </Row>
                    </Col>
                    <Col style={{flex: 1, flexGrow: 1, paddingVertical: 16}}>
                        <Row>
                            <Row style={{flex: 1}}>
                                <ADText
                                    fontSize={12}
                                    title={trans.code}
                                    numberOfLines={1}
                                    bold
                                    color={
                                        // item.consumed ?
                                        //     DPConfig.colors.defaultTextColorLight :
                                            DPConfig.colors.primaryColor
                                    }
                                />
                                <ADText
                                    fontSize={12}
                                    title={item.code}
                                    bold
                                    style={DPConfig.styles.growWidth}
                                    numberOfLines={1}
                                    color={
                                        // item.consumed ?
                                        //     DPConfig.colors.defaultTextColorLight :
                                            DPConfig.colors.primaryColor
                                    }
                                />
                            </Row>
                            <Row style={{flex: 1}}>
                                <ADText
                                    color={
                                        // item.consumed ?
                                        //     DPConfig.colors.defaultTextColorLight :
                                            DPConfig.colors.accentColor
                                    }
                                    fontSize={12}
                                    bold
                                    numberOfLines={1}
                                    title={trans.credit}
                                >

                                </ADText>
                                <ADText
                                    fontSize={12}
                                    bold
                                    style={DPConfig.styles.growWidth}
                                    color={
                                        // item.consumed ?
                                        //     DPConfig.colors.defaultTextColorLight :
                                            DPConfig.colors.accentColor
                                    }>
                                    {item.score}
                                    {trans.credit_suffix}
                                </ADText>
                            </Row>
                        </Row>

                        <Row style={{marginVertical: 8, alignItems: 'flex-start'}}>
                            <Row style={{flex: 1}}>
                                <ADText
                                    fontSize={12}
                                    color={
                                        // item.consumed ?
                                        //     DPConfig.colors.defaultTextColorLight :
                                            DPConfig.colors.defaultTextColor
                                    }
                                    numberOfLines={1}
                                    title={trans.value}/>
                                <ADText
                                    fontSize={12}
                                    color={
                                        // item.consumed ?
                                        //     DPConfig.colors.defaultTextColorLight :
                                            DPConfig.colors.defaultTextColor
                                    }
                                    numberOfLines={1}
                                    style={DPConfig.styles.growWidth}
                                    title={CurrencyUtil.formatCurrency(item.value)}/>
                            </Row>
                            <Row style={{flex: 1,}}>
                                {
                                    !!item.store &&
                                    <Row>
                                        <ADText
                                            numberOfLines={2}
                                            fontSize={12}
                                            title={trans.operator}/>
                                        <ADText
                                            numberOfLines={2}
                                            fontSize={12}
                                            style={DPConfig.styles.growWidth}
                                            title={item.store}/>
                                    </Row>
                                }
                            </Row>
                        </Row>

                        <Row>
                            <ADText
                                fontSize={10}
                                color={DPConfig.colors.defaultTextColorLight}
                                numberOfLines={1}
                                title={trans.date}/>
                            <ADText
                                fontSize={10}
                                style={DPConfig.styles.growWidth}
                                color={DPConfig.colors.defaultTextColorLight}
                                numberOfLines={1}
                                title={item.date}/>
                        </Row>
                    </Col>
                </Row>
            </ADTouchable>
        )
    }


}

const styles = DPStyleSheet.create(({dimens, colors, fonts, styles}) => ({
    container: {
        ...styles.card,
        alignItems: 'stretch',
        marginHorizontal: dimens.screenHorizontalPadding,
        marginBottom: 8,
        backgroundColor: '#fff'
    },
    ribbonWrapper: {
        width: 48,
        alignItems: 'center',
        overflow: 'hidden',
        borderBottomEndRadius: 12,
        borderBottomStartRadius: 12,
    },
    ribbon: {
        backgroundColor: colors.primaryColor,
        top: 0,
        position: 'absolute',
        transform: [{rotateZ: '270deg'}, {translateX: -15}],
        borderBottomEndRadius: 12,
        borderTopEndRadius: 12,
        overflow: 'hidden'
    } as ViewStyle,
    ribbonDisabled: {
        backgroundColor: '#b7b7b7',
    },
    ribbonText: {
        fontSize: 10,
        color: colors.primaryTextColor,
        lineHeight: 24,
        textAlign: 'center',
        textAlignVertical: 'center',
        height: 24,
        width: 70,
    },
    ribbonTextDisabled: {
        color: '#919191',
    }
}));
