import * as React from "react";
import Config from "./Config";
import ApiService from "./api/ApiService";
import MainScreen from "./screens/dashboard/main/MainScreen";
import ProjectListScreen from "./screens/dashboard/project/project-list/ProjectListScreen";
import ProjectDetailsScreen from "./screens/dashboard/project/project-details/ProjectDetailsScreen";
import NewsListScreen from "./screens/dashboard/news/news-list/NewsListScreen";
import NewsDetailsScreen from "./screens/dashboard/news/news-details/NewsDetailsScreen";
import OfferListScreen from "./screens/dashboard/offer/offer-list/OfferListScreen";
import OfferRequestScreen from "./screens/dashboard/offer/offer-request/OfferRequestScreen";
import ProfileScreen from "./screens/dashboard/profile/ProfileScreen";
import SettingScreen from "./screens/dashboard/setting/SettingScreen";
import Drawer from "./screens/dashboard/drawer/Drawer";
import TechnicalScreen from "./screens/dashboard/technical/TechnicalScreen";
import NewsGalleryListScreen from "./screens/dashboard/news/news-gallery/NewsGalleryListScreen";
import LoginScreen from "./screens/auth/login/LoginScreen";
import VerificationScreen from "./screens/auth/verification/VerificationScreen";
import SplashScreen from "./screens/splash/SplashScreen";
import IntroScreen from "./screens/intro/IntroScreen";
import InjectedApiServiceProps from "./api/InjectedApiServiceProps";
import autobind from "autobind-decorator";
// import CodePush from "react-native-code-push";
import { Platform } from "react-native";
import TicketListScreen from "./screens/dashboard/tickets/tickets/TicketListScreen";
import CreateTicketScreen from "./screens/dashboard/tickets/create/CreateTicketScreen";
import TicketDetailsScreen from "./screens/dashboard/tickets/conversation/TicketDetailsScreen";
import CreatePortfolioScreen from "app/screens/dashboard/portfolio/create/CreatePortfolioScreen";
import PortfolioListScreen from "app/screens/dashboard/portfolio/list/PortfolioListScreen";
import {
  ActionService,
  BaseService,
  DPAppBuilder,
  DPConfig,
  DPI18n,
  DPPlayerScreen,
  DrawerNavigatorBuilder,
  InjectedActionServiceProps,
  InjectedAuthServiceProps,
  InjectedMessagingServiceProps,
  InjectedNavigationServiceProps,
  StackNavigatorBuilder,
} from "@dp/react-native-core";
import EditPortfolioScreen from "./screens/dashboard/portfolio/edit/EditPortfolioScreen";
import ProjectCommentsScreen from "./screens/dashboard/project/comments/ProjectCommentsScreen";
import ScoreHistoryScreen from "./screens/dashboard/score-history/ScoreHistoryScreen";
import TimesScreen from "./screens/dashboard/times/TimesScreen";
import FilesScreen from "./screens/dashboard/project/files/FilesScreen";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://6331f6187e5240f2b8397f9a4d17534f@sentry.dadehpardaz.com/5",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.2,
});

class NavigateToLoginScreenOnLogoutAndHandleNotificationService extends BaseService<
  InjectedActionServiceProps &
    InjectedNavigationServiceProps &
    InjectedMessagingServiceProps &
    InjectedAuthServiceProps &
    InjectedApiServiceProps
> {
  readonly ServiceName = "";

  $onStart() {
    this.$services.$action.addListener<"auth/logout/success">(
      "auth/logout/success",
      (action) => {
        LoginScreen.resetTo(this.$services.$navigation);
      }
    );

    this.$services.$action.addListener(
      "notification/received",
      this.handleNotificationReceived
    );
    this.$services.$action.addListener(
      "messaging/received",
      this.handleNotificationReceived
    );
    // if(__DEV__){
    //     this.$services.$auth.login({accessToken:'155832996615246'})
    // }
    DPI18n.updateLocale("fa", true);
  }

  @autobind
  handleNotificationReceived(action: { payload: { notification } }) {
    if (!this.$services.$auth.isLoggedIn) return;
    this.$services.$api.dashboardApi.reload();
    if (action.payload && action.payload.notification) {
      this.$services.$messaging.showNotification(action.payload.notification);
    }
  }
}

ActionService.warnUnhandledAction = false;
const App = new DPAppBuilder(Config)
  .addService(new ApiService())
  .addService(new NavigateToLoginScreenOnLogoutAndHandleNotificationService())
  .setNavigator(
    new StackNavigatorBuilder()
      .addScreen(SplashScreen)
      .addScreen(IntroScreen)
      .addScreen(LoginScreen)
      .addScreen(VerificationScreen)
      .add(
        DrawerNavigatorBuilder.defaultRouteName,
        new DrawerNavigatorBuilder()
          .setConfig({
            drawerPosition: Platform.OS === "ios" ? "left" : "right",
          })
          .setStackConfig({
            headerMode: "none",
            mode: "card",
            navigationOptions: {
              gestureDirection: "default",
              gesturesEnabled: true,
            },
          })
          .setDrawerView(Drawer)
          .addScreen(MainScreen)
          .addScreen(ProjectListScreen)
          .addScreen(SettingScreen)
          .addScreen(ProfileScreen)
          .addScreen(ScoreHistoryScreen)
          .addScreen(TechnicalScreen)
          .addScreen(TimesScreen)
          .addScreen(OfferListScreen)
          .addScreen(ProjectDetailsScreen, {
            gestureResponseDistance: {
              horizontal: DPConfig.dimens.screen.horizontalPadding,
            },
          })
          .addScreen(NewsGalleryListScreen)
          .addScreen(NewsListScreen)
          .addScreen(NewsDetailsScreen)
          .addScreen(OfferRequestScreen)
          .addScreen(IntroScreen)
          .addScreen(TicketListScreen)
          .addScreen(CreateTicketScreen)
          .addScreen(TicketDetailsScreen)

          .addScreen(PortfolioListScreen)
          .addScreen(EditPortfolioScreen)
          .addScreen(CreatePortfolioScreen)
          .addScreen(ProjectCommentsScreen)
          .addScreen(FilesScreen)
        // $$dp-cli-merge$$
        // .addScreen(PortfolioListScreen)
      )
      .addScreen(DPPlayerScreen)
      .create()
  )
  .create();

// let x;
// if (Platform.OS !== "web") {
//   let opt = {
//     // deploymentKey: BuildConfig.CODE_PUSH_KEY,
//     checkFrequency: CodePush.CheckFrequency.ON_APP_RESUME,
//     installMode: CodePush.InstallMode.ON_NEXT_RESTART,
//   };
//   x = CodePush(opt)(App);
// } else {
// x = App;
// }

export default App;
