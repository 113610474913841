let backListeners = [];
let exit = false
window.addEventListener('load', function () {
    window.history.pushState({}, '')
});

window.addEventListener('popstate', function () {
    console.log('BackHandler', 'exit:' + exit);
    if (exit) return;
    let backHandled = !!backListeners.find(listener => (!!listener && listener()));
    console.log('BackHandler', 'back press Handled by listener:' + backHandled);
    if (backHandled) {
        window.history.pushState({}, '')
    } else {
        window.history.back();
    }
});


const BackHandler = {
    exitApp: () => {
        exit = true;
        window.history.back();
    },
    addEventListener: (name, listener) => {
        if (name === 'hardwareBackPress') {
            backListeners.unshift(listener)
        }
        return {
            remove: () => {
                if (name === 'hardwareBackPress')
                    backListeners = backListeners.filter(_ => _ !== listener)
            }
        };
    },
    removeEventListener: (name /*'hardwareBackPress'*/, listener) => {
        if (name === 'hardwareBackPress') {
            backListeners = backListeners.filter(_ => _ !== listener)
        }
    }
};

export default BackHandler