import * as React from 'react';
import {observer} from 'mobx-react';
import {StyleSheet} from 'react-native';
import {NewsGalleryItemModel} from 'app/api/Models';
import {ADIcon, ADImage, ADResponsiveUtil, ADText, Col, DPCard, Row} from '@dp/react-native-core';
import Assets from 'app/assets/Assets';

interface Props {
    item: NewsGalleryItemModel,
    onPress: (item: NewsGalleryItemModel) => void
}

@observer
export default class NewsGalleryListItem extends React.Component<Props> {
    render() {
        let {item, onPress} = this.props
        let width = (ADResponsiveUtil.width - ADResponsiveUtil.screenHorizontalPadding * 3) / 2 - 8
        let height = width * 9 / 16;
        return (
            <DPCard
                useNativeShadow
                onPress={() => {
                    onPress(item)
                }}
                style={{
                    paddingHorizontal: 4,
                    paddingTop: 8,
                    padding: 0,
                    marginStart: ADResponsiveUtil.screenHorizontalPadding,
                    marginTop: ADResponsiveUtil.screenVerticalPadding,
                    width: width + 8,
                }}
            >
                <Col>
                    <Col style={{width: width, height}}>
                        {
                            <ADImage
                                source={
                                    item.image ?
                                        {uri: item.image} :
                                        Assets.images.logo
                                }
                                resizeMode={item.image ? 'cover' : 'contain'}
                                style={{
                                    height: '100%',
                                    width: '100%'
                                }}
                            />
                        }
                        {
                            !!item.video &&
                            <Col style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0,0,0,.5)',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <ADIcon
                                    size={32}
                                    icon={'play-circle-outline'}
                                    type={'MaterialIcons'}
                                    color={'#fff'}
                                />
                            </Col>
                        }
                    </Col>
                    <Row>
                        <ADText numberOfLines={1}
                                style={styles.title}
                                title={item.title}/>
                    </Row>
                </Col>
            </DPCard>
        )
    }
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        textAlign: 'center',
        padding: 4,
        width: 0,
        flexGrow: 1,
        fontSize: ADResponsiveUtil.plus(10, 2)
    }
})