import {Linking} from 'react-native-web'

function createElement(element, children, attribute) {
    if (typeof (element) === "undefined") {
        return false;
    }
    if (typeof (children) === "undefined") {
        children = "";
    }
    var el = document.createElement(element);
    if (typeof (attribute) === 'object') {
        for (var key in attribute) {
            el.setAttribute(key, attribute[key]);
        }
    }
    if (!Array.isArray(children)) {
        children = [children];
    }
    for (var k = 0; k < children.length; k++) {
        if (children[k].tagName) {
            el.appendChild(children[k]);
        } else {
            el.appendChild(document.createTextNode(children[k]));
        }
    }
    return el;
}

Linking.openURL = function (url) {
    let id = 'dplinking_' + Math.random()
    let el = createElement('a', '', {href: url, target: '_blank', id: id, style: 'display:none;'})
    el.click();
    setTimeout(function () {
        el.parentElement &&
        el.parentElement.removeChild &&
        el.parentElement.removeChild(el);
    }, 1000)
    return Promise.resolve(true);
}

export default Linking