import * as React from 'react';
import autobind from 'autobind-decorator';
import {inject, observer} from 'mobx-react';
import {View} from 'react-native';
import {TicketItemModel} from 'app/api/Models';
import {ADResponsiveUtil, ADText, ADTouchable, Col, DPConfig, DPStyleSheet, Row} from '@dp/react-native-core';
import {i18n} from 'app/i18n/i18n';

export interface TicketItemProps {
    item: TicketItemModel
    onPress?: (item: TicketItemModel) => void
    mini?: boolean,
    footer?
}


@inject('$config')
@observer
export default class TicketListItem extends React.Component<TicketItemProps> {
    static defaultProps = {
        mini: true
    }

    render() {
        let {item, mini, onPress} = this.props;

        let x = (
            <Row style={styles.container}>
                <Col style={{
                    marginEnd: 16,
                    alignItems: 'center',
                }}>
                    <View style={[styles.circle, {borderColor: item.priority_color}]}/>
                    <Col style={{flex: 1}}>
                        <Col style={{
                            flexGrow: 1,
                            height: '100%',
                            width: 1,
                            backgroundColor: DPConfig.colors.screenBackgroundColorDark,
                        }}/>
                    </Col>
                </Col>
                <Col style={{flex: 1}}>
                    {
                        (!!item.subject || !!item.status) &&
                        <Row style={{alignItems: 'flex-start'}}>
                            <ADText style={styles.title}>
                                {item.subject}
                            </ADText>
                            {
                                !!item.status &&
                                <ADText style={[styles.category, !!item.status_color && {
                                    color: item.status_color,
                                    borderColor: item.status_color,
                                }]}>
                                    {item.status}
                                </ADText>
                            }
                        </Row>
                    }
                    <Row>
                        <ADText
                            numberOfLines={mini ? 1 : undefined}
                            style={[styles.description]}>
                            {item.last_comment || item.content}
                        </ADText>
                    </Row>
                    <Row>
                        <ADText
                            numberOfLines={1}
                            style={styles.date}>
                            {item.created_at}
                        </ADText>
                        <View style={{flex: 1}}/>
                        <ADText
                            numberOfLines={1}
                            style={styles.codeLabel}>
                            {
                                i18n.strings.screens.ticket_list.item.code
                            }
                        </ADText>
                        <ADText
                            numberOfLines={1}
                            style={styles.code}>
                            {item.code || item.id}
                        </ADText>
                    </Row>
                    <View style={{
                        width: '100%',
                        backgroundColor: DPConfig.colors.screenBackgroundColorDark,
                        height: 1
                    }}/>
                    {
                        this.props.footer
                    }
                </Col>
            </Row>
        );
        if (onPress)
            return (
                <ADTouchable onPress={() => onPress && onPress(item)}>
                    {x}
                </ADTouchable>
            );
        return x;
    }

    @autobind
    handleOnPress() {
        this.props.onPress && this.props.onPress(this.props.item);
    }
}


const styles = DPStyleSheet.create(({colors, dimens}) => ({
    container: {
        paddingHorizontal: dimens.screen.horizontalPadding,
        paddingTop: 16,
        backgroundColor: '#fff',
        alignItems: 'stretch'
    },
    description: {
        flexGrow: 1,
        width: 0,
        paddingVertical: 8,
        textAlign: 'left',
        color: '#9e9e9e',
        fontSize: ADResponsiveUtil.plus(12, 2),
    },

    title: {
        width: 0,
        flexGrow: 1,
        textAlign: 'left',
        fontSize: ADResponsiveUtil.plus(14, 2),
    },

    date: {
        fontSize: ADResponsiveUtil.plus(9, 2),
        textAlign: 'left',
        marginBottom: 16,
        color: '#9e9e9e',
    },

    codeLabel: {
        fontSize: ADResponsiveUtil.plus(9, 2),
        textAlign: 'left',
        marginBottom: 16,
        marginEnd: 4,
        color: '#9e9e9e',
    },

    code: {
        fontSize: ADResponsiveUtil.plus(9, 2),
        textAlign: 'left',
        marginBottom: 16,
        color: colors.accentColor,
    },

    category: {
        fontSize: ADResponsiveUtil.plus(10, 2),
        textAlign: 'center',
        textAlignVertical: 'center',
        paddingHorizontal: 8,
        paddingVertical: -2,
        borderRadius: 6,
        minHeight: 12,
        borderWidth: 1,
        marginTop: 4,
    },
    circle: {
        borderRadius: 8,
        height: 16,
        width: 16,
        borderWidth: 2,
        marginTop: 4,
    },
    dash: {
        height: 1,
        width: 5,
        position: 'absolute'
    }
}));
