import * as React from 'react';
import {inject, observer} from 'mobx-react';
import {Animated, I18nManager, Platform, StyleSheet, View, ViewStyle} from 'react-native';
// import {NavigationEventSubscription, NavigationInjectedProps} from 'react-navigation';
import NewsGalleryListScreen from '../news-gallery/NewsGalleryListScreen';
import Video from 'react-native-af-video-player'
import {
    ADButton,
    ADIcon,
    ADImage,
    ADRemoteView,
    ADResponsiveUtil,
    ADScreen,
    ADText,
    ADToast,
    ADTouchable,
    AlertDialog,
    Col,
    DPConfig,
    DPHtmlTextView,
    DPScrollView, DPStyleSheet,
    IDType,
    INavigationService,
    InjectedNavigationServiceProps,
    DPZoomImageModal,
    Row
} from '@dp/react-native-core';
import InjectedApiServiceProps from 'app/api/InjectedApiServiceProps';
import {NewsDetailsModel} from '../../../../api/Models';
import CommentBoxComponent from './CommentBoxComponent';
import {AppToolbar} from '../../../common/AppToolbar';
import autobind from 'autobind-decorator';
import {i18n} from '../../../../i18n/i18n';
import {Fragment} from 'react';

type Params = { newsId: IDType, onLoaded?: () => void }
type Props = InjectedApiServiceProps &
    InjectedNavigationServiceProps & Params


@inject('$navigation', '$api')
@observer
export default class NewsDetailsScreen extends React.Component<Props> {
    static RouteName = 'NewsDetailsScreen';

    static start(nav: INavigationService, params: Params) {
        nav.navigate(NewsDetailsScreen.RouteName, params)
    }

    protected readonly api = DPConfig.http.createResource<{ news_id: IDType }, NewsDetailsModel>((p) => {
        return this.props.$api.getNewsById({...p, news_id: this.props.newsId})
            .onSuccess(response => {
                this.props.onLoaded &&
                this.props.onLoaded()
            })
    });

    state = {
        videoError: undefined,
        fullScreen: false,
        imagePreviewVisible: false
    };
    player;

    private animatedScrollValue = new Animated.Value(0)

    render() {
        this.api.setRequest({news_id: this.props.newsId});

        let item = this.api.data;


        let hasVideo = !!item.main_video_url;
        let hasImage = !!item.main_image_url;
        let hasVideoError = !!this.state.videoError;
        return (
            <ADScreen
                onBackPress={this.onBackPress}
                onFocus={this.resume}
                onBlur={this.stop}
                style={{flex: 1, backgroundColor: '#fff'}}>
                <ADRemoteView api={this.api}>
                    <DPScrollView
                        api={this.api}
                        animatedScrollValue={this.animatedScrollValue}
                        // extraHeight={200}
                        contentContainerStyle={{flexGrow: 1, alignItems: 'stretch'}}
                    >
                        <Col style={{width: '100%', alignItems: 'stretch', maxHeight: 300}}>
                            {
                                (hasVideo && !hasVideoError) &&
                                <Video
                                    key={'video'}
                                    url={item.main_video_url}
                                    ref={(ref) => {
                                        this.player = ref
                                    }}
                                    lockPortraitOnFsExit={true}
                                    rotateToFullScreen={true}
                                    onFullScreen={(f) => {
                                        // alert(JSON.stringify(f))
                                        this.setState({fullScreen: f})
                                    }}
                                    style={styles.videoOrImage}
                                    placeholder={item.cover || item.main_image_url}
                                    error={false}
                                    onError={this.handleError}
                                    // web props
                                    height={'35vh'}
                                    width={'100%'}
                                />
                            }
                            <View>
                                {
                                    ((!hasVideo && hasImage) || hasVideoError) &&
                                    <ADTouchable onPress={() => this.setState({imagePreviewVisible: true})}>
                                        <ADImage
                                            style={styles.videoOrImage as any}
                                            source={{uri: item.main_image_url}}/>
                                    </ADTouchable>
                                }
                                {
                                    !!this.state.videoError &&
                                    <View style={{justifyContent: 'center', alignItems: 'center'}}>
                                        <ADTouchable onPress={() => this.setState({videoError: undefined})}>
                                            <View style={styles.errorOverlay}>
                                                <ADText style={styles.error}>{this.state.videoError}</ADText>
                                            </View>
                                        </ADTouchable>
                                    </View>
                                }
                            </View>
                            {
                                !!item.gallery &&
                                item.gallery.length > 0 &&
                                !this.state.fullScreen &&
                                <View style={[{
                                    alignSelf: 'flex-end',
                                    position: 'absolute',
                                    bottom: hasVideo ? 32 : 16,
                                    start: 16,
                                    zIndex: 1000,
                                }
                                ]}>
                                    <ADButton
                                        title={i18n.t(i18n.strings.screens.news_details.show_gallery, {count: item.gallery.length})}
                                        accent
                                        filled
                                        small
                                        before={
                                            <ADIcon
                                                style={{marginStart: 8}}
                                                icon={'photo-library'}
                                                size={18}
                                                color={DPConfig.colors.accentTextColor}/>
                                        }

                                        onPress={this.handleShowGallery}
                                    />
                                </View>
                            }
                        </Col>
                        <View style={styles.container}>
                            {
                                !this.state.fullScreen &&
                                <Col>
                                    <Row style={[
                                        styles.row,
                                        !hasImage && !hasVideo && {
                                            paddingTop: DPConfig.dimens.screenVerticalPadding
                                        }
                                    ]}>
                                        <ADText bold style={styles.title} textAlign={'start'}>{item.title}</ADText>
                                    </Row>
                                    <Row style={[styles.row, {alignItems: 'center'}]}>
                                        <Row style={{alignItems: 'center'}}>
                                            <ADIcon
                                                style={{marginEnd: 2}}
                                                icon={'date-range'}
                                                size={ADResponsiveUtil.plus(10, 2)}
                                                color={'#9e9e9e'}
                                            />
                                            <ADText style={styles.date}>{item.jalali_publish_at}</ADText>
                                            {
                                                item.likes_count !== undefined &&
                                                <ADIcon
                                                    style={{marginStart: 8, marginEnd: 2}}
                                                    icon={'thumb-up'}
                                                    size={ADResponsiveUtil.plus(10, 2)}
                                                    color={
                                                        item.liked ? DPConfig.colors.accentColor : '#9e9e9e'
                                                    }
                                                />
                                            }
                                            {
                                                item.likes_count !== undefined &&
                                                <ADText
                                                    color={item.liked ? DPConfig.colors.accentColor : '#9e9e9e'}
                                                    style={styles.likeCount}>
                                                    {item.likes_count}
                                                </ADText>
                                            }
                                            {
                                                item.comments_count !== undefined &&
                                                <ADIcon
                                                    style={{marginStart: 8, marginEnd: 2}}
                                                    icon={'comment'}
                                                    size={ADResponsiveUtil.plus(10, 2)}
                                                    color={'#9e9e9e'}
                                                />
                                            }
                                            {
                                                item.comments_count !== undefined &&
                                                <ADText style={styles.commentsCount}>
                                                    {item.comments_count}
                                                </ADText>
                                            }
                                            <ADIcon
                                                style={{marginStart: 8, marginEnd: 2}}
                                                icon={'tag'}
                                                type={'MaterialCommunityIcons'}
                                                size={ADResponsiveUtil.plus(10, 2)}
                                                color={'#9e9e9e'}
                                            />
                                            <ADText style={styles.category}>{item.category}</ADText>
                                        </Row>
                                        <View style={{flexGrow: 1}}/>
                                        <ADButton
                                            onPress={this.handleLikePress}
                                            outlined
                                            color={item.liked ? DPConfig.colors.accentColor : '#9e9e9e'}
                                            before={
                                                <ADIcon
                                                    icon={'thumb-up'}
                                                    color={item.liked ? DPConfig.colors.accentColor : '#9e9e9e'}
                                                    size={ADResponsiveUtil.plus(12, 2)}
                                                />
                                            }
                                            textStyle={{
                                                color: item.liked ? DPConfig.colors.accentColor : '#9e9e9e',
                                                fontSize: ADResponsiveUtil.plus(12, 2),
                                                paddingVertical: 2,
                                                paddingHorizontal: 2
                                            }}
                                            style={{
                                                paddingHorizontal: 4
                                            }}
                                            title={i18n.strings.screens.news_details.like}/>
                                    </Row>
                                    <Row
                                        style={[styles.row, {paddingBottom: DPConfig.dimens.screenVerticalPadding}]}>
                                        {
                                            Platform.OS !== 'web' ?
                                                <DPHtmlTextView
                                                    textStyle={styles.body}
                                                    html={item.description}/>
                                                :
                                                <div
                                                    style={StyleSheet.flatten(styles.body)}
                                                    dangerouslySetInnerHTML={{__html: item.description}}/>
                                        }
                                    </Row>

                                    <CommentBoxComponent
                                        style={{
                                            marginBottom: DPConfig.dimens.screenVerticalPadding
                                        }}
                                        isSendCommentEnabled={true}
                                        onSendComment={this.sendComment}
                                        comments={this.api.data.comments}
                                    />
                                </Col>
                            }
                        </View>

                    </DPScrollView>
                </ADRemoteView>
                {
                    !this.state.fullScreen &&
                    <View style={{position: 'absolute', top: 0, left: 0, right: 0}}>
                        <AppToolbar
                            theme={'light'}
                            onBackPress={this.onBackPress}
                            title={i18n.strings.screens.news_details.title}
                            backgroundOpacity={
                                ((hasImage || hasVideo) && this.api.isSuccess) ?
                                    (
                                        this.animatedScrollValue.interpolate({
                                            inputRange: [0, ADResponsiveUtil.width / 3],
                                            outputRange: [.2, 1]
                                        })
                                    ) : 1
                            }
                        />
                    </View>
                }

                {
                    !!item.main_image_url &&
                    <DPZoomImageModal
                        source={{uri: item.main_image_url}}
                        isVisible={this.state.imagePreviewVisible}
                        onVisibilityChanged={(imagePreviewVisible) => this.setState({imagePreviewVisible})}
                    />
                }

                <ADToast ref={(ref) => this.toast = ref}/>
            </ADScreen>
        )
    }

    toast: null | ADToast;
    stop = () => {
        if (this.player)
            this.player.pause && this.player.pause();
        // this.setState({
        //     videoError: ' '
        // })
    };
    resume = () => {
        // if (this.player)
        //     this.player.play();
        // this.setState({
        //     videoError: ''
        // })
    };

    // willBlur: NavigationEventSubscription;
    // didFocus: NavigationEventSubscription;
    // back //: NavigationEventSubscription;
    //
    // componentDidMount() {
    //     this.willBlur = this.props.navigation.addListener('willBlur', this.stop);
    //     this.didFocus = this.props.navigation.addListener('didFocus', this.resume);
    //     this.back = BackHandler.addEventListener('hardwareBackPress', this.onBackPress)
    // }

    componentWillUnmount() {
        // this.willBlur && this.willBlur.remove();
        // this.didFocus && this.didFocus.remove();
        // this.back && this.back.remove();
        this.stop();
    }


    onBackPress = () => {
        this.stop();
        setTimeout(() => {
            this.props.$navigation.back()
        }, 300);
        return true
    };
    // private handleScroll = (event?: NativeSyntheticEvent<NativeScrollEvent>) => {
    //
    //     let hideDistance = ADResponsiveUtil.width / 3;
    //     if (event) {
    //         let y = event.nativeEvent.contentOffset.y;
    //         let opacity = 1 - (1 - y / hideDistance);
    //         LayoutAnimation.configureNext(LayoutAnimation.Presets.linear);
    //         this.setState({
    //             opacity
    //         })
    //     }
    // };
    handleError = (e) => {
        try {
            if (this.player)
                this.player.BackHandler && this.player.BackHandler()
        } catch (e) {
            console.warn(e)
        }
        this.setState({
            fullScreen: false,
            videoError: i18n.strings.screens.news_details.video_error
        })
    };

    @autobind
    sendComment(comment: string, onSuccess) {
        this.props.$api.sendNewsComment({news_id: this.api.data.id, comment: comment})
            .onSuccess((response) => {
                this.api.reload();
                this.props.$api.newsListApi.loadFirstPage();
                onSuccess && onSuccess(response.message);
                this.api.onSuccess({
                    ...response,
                    data: {
                        ...this.api.data,
                        ...response.data
                    }
                }, this.api.lastCall);

            })
            .defaultError(true)
            .defaultLoading(true)
            .enqueue()
    }

    @autobind
    handleLikePress() {
        this.props.$api.likeNewsById({news_id: this.api.data.id, like: !this.api.data.liked})
            .onSuccess((response) => {
                this.api.onSuccess({...this.api.response, ...response} as any, this.api.lastCall);
                this.props.$api.newsListApi.loadFirstPage();
            })
            .defaultError(true)
            .defaultLoading(true)
            .enqueue()
    }

    @autobind
    private handleShowGallery() {
        let data = this.api.data;
        if (data.gallery && data.gallery.length > 0)
            NewsGalleryListScreen.start(this.props.$navigation, {
                items: data.gallery
            })
    }
}


const styles = DPStyleSheet.create(({fonts, dimens}) => ({
    container: {
        flexGrow: 1,
        alignItems: 'stretch',
        backgroundColor: '#fff'
    } as ViewStyle,

    videoOrImage: Platform.select({
        default: {
            width: '100%',
            height: dimens.screen.height * .35,
        },
        web: {
            width: '100%',
            height: '35vh',
            maxHeight: 300
        }
    }) as any,

    row: {
        paddingHorizontal: dimens.screen.horizontalPadding,
        paddingTop: 16
    },

    errorOverlay: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,1)'
    },

    error: {
        padding: 16,
        color: '#fff',
    },

    title: {
        fontSize: ADResponsiveUtil.plus(16, 2),
        lineHeight: ADResponsiveUtil.plus(16, 2) * 1.8,
        width: 0,
        flexGrow: 1,
        textAlign: 'left'
    },
    body: {
        fontSize: ADResponsiveUtil.plus(14, 2),
        ...Platform.select({
            default: {
                lineHeight: ADResponsiveUtil.plus(14, 2) * 1.8,
                textAlign: 'left',
            },
            web: {
                lineHeight: 2,
                textAlign: I18nManager.isRTL ? 'right' : 'left'
            }
        })
    },

    date: {
        fontSize: ADResponsiveUtil.plus(9, 2),
        color: '#9e9e9e'
    },
    category: {
        fontSize: ADResponsiveUtil.plus(9, 2),
        color: '#727272',
        marginEnd: 16,
    },
    likeCount: {
        fontSize: ADResponsiveUtil.plus(10, 2),
        textAlign: 'left'
    },
    commentsCount: {
        fontSize: ADResponsiveUtil.plus(10, 2),
        color: '#9e9e9e',
        textAlign: 'left'
    }
}));