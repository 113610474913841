import * as React from 'react';
import {observer} from 'mobx-react';
import {StyleSheet, View} from 'react-native';
import {TicketCommentItemModel} from 'app/api/Models';
import anchorme from 'anchorme';
import {ADImage, ADResponsiveUtil, ADText, Col, DPConfig, DPHtmlTextView, Row} from '@dp/react-native-core';

export interface ConversationListItemProps {
    item: TicketCommentItemModel
    index: number
}

@observer
export default class TicketCommentListItem extends React.Component<ConversationListItemProps> {
    render() {
        let {item, index} = this.props;

        if (item.is_author)
            return (
                <Col style={[styles.container, {
                    marginEnd: DPConfig.dimens.screen.horizontalPadding + DPConfig.dimens.screen.width / 10,
                    marginStart: DPConfig.dimens.screen.horizontalPadding,
                    paddingTop: 8,
                    borderBottomStartRadius: 0
                }]}>

                    <Row style={{alignItems: 'center'}}>
                        <ADImage
                            style={{marginEnd: 8, backgroundColor: '#efefef', borderRadius: 10}}
                            source={{icon: 'person'}}
                            size={20}/>
                        <ADText style={styles.username}>
                            {item.user_name}
                        </ADText>
                    </Row>
                    <Row style={{paddingVertical: 8}}>
                        <DPHtmlTextView
                            html={'<html>' + anchorme(item.comment) + '</html>'}
                            textStyle={styles.description}/>
                    </Row>
                    <Row>
                        <ADText style={styles.date}>
                            {item.created_at}
                        </ADText>
                    </Row>
                </Col>
            );
        return (
            <Col style={[styles.container, {
                marginStart: ADResponsiveUtil.screenHorizontalPadding + ADResponsiveUtil.width / 10,
                marginEnd: ADResponsiveUtil.screenHorizontalPadding,
                borderBottomEndRadius: 0
            }]}>

                <Row style={{alignItems: 'center'}}>
                    <ADText style={[styles.username, {textAlign: 'right'}]}>
                        {item.user_name}
                    </ADText>
                    <View
                        style={{
                            marginStart: 8,
                            backgroundColor: '#efefef',
                            borderRadius: 10,
                            width: 24,
                            height: 24,
                            overflow: 'hidden',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <ADImage
                            style={{borderRadius: 10}}
                            source={{icon: 'person'}}
                            size={20}/>
                    </View>
                </Row>
                <Row style={{paddingVertical: 8}}>
                    <DPHtmlTextView
                        html={'<html>' + anchorme(item.comment || '') + '</html>'}
                        textStyle={[styles.description, {textAlign: 'right'}]}/>
                </Row>
                <Row>
                    <ADText style={[styles.date, {textAlign: 'right'}]}>
                        {item.created_at}
                    </ADText>
                </Row>
            </Col>
        );
    }

}


const styles = StyleSheet.create({
    container: {
        borderColor: '#efefef',
        borderWidth: 1,
        paddingHorizontal: 8,
        // paddingTop: 16,
        backgroundColor: '#fff',
        marginVertical: 8,
        paddingBottom: 8,
        paddingTop: 8,
        borderRadius: 16
    },
    description: {
        flexGrow: 1,
        width: 0,
        textAlign: 'left',
        color: '#727272',
        fontSize: ADResponsiveUtil.plus(14, 2),
    },

    username: {
        width: 0,
        flexGrow: 1,
        textAlign: 'left',
        fontSize: ADResponsiveUtil.plus(10, 2),
        color: '#9e9e9e',
    },

    date: {
        fontSize: ADResponsiveUtil.plus(9, 2),
        textAlign: 'left',
        flexGrow: 1,
        width: 0,
        // marginBottom: 16,
        color: '#9e9e9e',
    },
});