import * as React from 'react';
import {inject, observer} from 'mobx-react';
import {BackHandler, Image, View, ViewStyle} from 'react-native';
import {
    ADButton,
    ADForm,
    ADRequiredValidator,
    ADResponsiveUtil,
    ADScreen,
    ADText,
    ADTextInput,
    Col,
    DPConfig,
    DPI18n,
    DPScrollView,
    DPStyleSheet,
    DPToolbar,
    INavigationService,
    InjectedAuthServiceProps,
    InjectedNavigationServiceProps,
    Row
} from '@dp/react-native-core';
import InjectedApiServiceProps from 'app/api/InjectedApiServiceProps';
import LoginScreenStore from './LoginScreenStore';
import Assets from 'app/assets/Assets';
import {i18n} from '../../../i18n/i18n';

``
type Params = {}
type LoginEnterMobileScreenProps = InjectedAuthServiceProps &
    InjectedNavigationServiceProps &
    InjectedApiServiceProps &
    Params

@inject('$auth', '$navigation', '$api')
@observer
export default class LoginScreen extends React.Component<LoginEnterMobileScreenProps> {
    static RouteName = 'LoginScreen'
    static defaultProps = {};

    static start(navigation: INavigationService) {
        navigation.navigate(this.RouteName)
    }

    static resetTo(navigation: INavigationService) {
        navigation.resetTo(this.RouteName)
    }

    protected store: LoginScreenStore;

    constructor(p, c) {
        super(p, c);
        this.store = new LoginScreenStore(this.props)
    }

    handleBackPress = () => {
        if (this.props.$navigation.state.canGoBack) {
            this.props.$navigation.back();
            return true;
        }
        BackHandler.exitApp();
        return false
    };

    render() {
        let trans = i18n.strings.screens.login;
        return (
            <ADScreen
                style={{backgroundColor: '#fff'}}
                backConfirmMessage={trans.exit_confirm}
                onBackPress={this.handleBackPress}>
                <DPToolbar onBackPress={this.handleBackPress} transparent={true} shadow={false}/>
                <ADForm store={this.store.form}>
                    <DPScrollView
                        contentContainerStyle={{alignItems: 'center', flexGrow: 1}}>
                        <Col style={styles.container}>

                            <Image
                                style={styles.logo}
                                resizeMode={'contain'}
                                source={Assets.images.logo}
                            />
                            <Col style={{flex: 2}}/>
                            <ADText
                                title={
                                    DPI18n.strings.$login_note
                                }
                                color={DPConfig.colors.defaultTextColor}
                                style={{alignSelf: 'flex-start'}}
                            />
                            <Row style={{flexShrink: 0, alignSelf: 'stretch'}}>
                                <ADTextInput
                                    $id={'mobile'}
                                    $validators={[new ADRequiredValidator(trans.mobile_required)]}
                                    testID={'input_mobile'}
                                    label={trans.mobile}
                                    autoFocus={false}
                                    keyboardType={'numeric'}
                                    // customTextInputProps={{style: {textAlign: 'right'}}}
                                    clearColor={DPConfig.colors.defaultTextColor}
                                    baseColor={DPConfig.colors.defaultTextColor}
                                    tintColor={DPConfig.colors.defaultTextColor}
                                    blurOnSubmit={true}
                                    onSubmitEditing={this.store.submit}
                                    textStyle={{
                                        color: DPConfig.colors.defaultTextColor,
                                    }}
                                    style={{marginVertical: 16, flex: 1}}
                                />
                            </Row>
                            <Row style={{flexShrink: 0, alignSelf: 'stretch'}}>
                                <ADButton
                                    testID={'button_continue'}
                                    onPress={this.store.submit}
                                    title={trans.btn_continue}
                                    textStyle={{
                                        fontSize: ADResponsiveUtil.plus(16, 2),
                                        textAlignVertical:'center'
                                    }}
                                    block
                                    accent
                                    filled
                                    style={{borderRadius: 32}}/>
                            </Row>

                            <View style={styles.bottomGap}/>

                        </Col>
                    </DPScrollView>
                </ADForm>
            </ADScreen>
        )
    }


}


const LOGO_WIDTH = ADResponsiveUtil.size(ADResponsiveUtil.width * .7, 100, 300);
const LOGO_HEIGHT = LOGO_WIDTH * 238 / 514
const styles = DPStyleSheet.create(({colors, dimens}) => ({
    container: {
        flexGrow: 1,
        alignItems: 'center',
        // alignSelf: 'center',
        paddingHorizontal: dimens.screenHorizontalPadding,
        paddingTop: dimens.screenVerticalPadding,
        maxWidth: 500
    } as ViewStyle,
    logo: {
        width: LOGO_WIDTH,
        height: LOGO_HEIGHT,
        flexShrink: 1,
        flexGrow: 1,
        marginVertical: dimens.screenVerticalPadding
    },
    bottomGap: {
        height: dimens.screenVerticalPadding + dimens.safeAreaInsets.bottom,
        flexGrow: 1
    }
}));