import * as React from 'react';
import {inject, observer} from 'mobx-react';
import autobind from 'autobind-decorator';
import {
    ADButton,
    ADDateInput,
    ADFlatList,
    ADForm,
    ADFormStore,
    ADImage,
    ADListResource,
    ADRemoteView,
    ADScreen,
    ADSelectInput,
    ADTextInput,
    Col,
    DPConfig,
    DPStyleSheet,
    INavigationService,
    InjectedNavigationServiceProps,
    Row
} from '@dp/react-native-core';
import ProjectListScreenItem from './ProjectListScreenItem';
import InjectedApiServiceProps from 'app/api/InjectedApiServiceProps';
import {ProjectListModel} from 'app/api/Models';
import {View} from 'react-native';
import {AppToolbar} from 'app/screens/common/AppToolbar';
import ProjectDetailsScreen from '../project-details/ProjectDetailsScreen';
import {MenuDivider, MenuItem} from 'react-native-material-menu';
import Assets from 'app/assets/Assets';
import {i18n} from '../../../../i18n/i18n';


type Params = {}

type ProjectListScreenProps =
    Params &
    InjectedNavigationServiceProps &
    InjectedApiServiceProps

type State = {}
export type ProjectFilterRequest = { page, service, form_date?: string, to_date?: string, query?: string }
@inject('$navigation', '$api')
@observer
export default class ProjectListScreen extends React.Component<ProjectListScreenProps, State> {
    static RouteName = 'ProjectListScreen'

    static start(nav: INavigationService, param?: Params) {
        nav.navigate(ProjectListScreen.RouteName, param)
    }

    protected listApi = DPConfig.http.createListResource<ProjectFilterRequest, ProjectListModel>((p) => {
        return this.props.$api.getProjectListItems(p)
    });


    protected form = new ADFormStore<ProjectFilterRequest>();

    componentDidMount(): void {
        this.props.$api.dashboardApi.data.notification_unread_count = 0;
    }

    render() {
        return (
            <ADScreen style={{backgroundColor: DPConfig.colors.accentColor}}>
                <AppToolbar
                    theme={'dark'}
                    title={i18n.strings.screens.project_list.title}
                />

                {
                    this.props.$api.newsListApi.items.length == 0 &&
                    <SearchForm
                        $api={this.props.$api}
                        $form={this.form}
                        listApi={this.listApi}
                    />
                }
                <ADFlatList
                    ListHeaderComponent={
                        this.props.$api.newsListApi.items.length == 0 ?
                            undefined :
                            <SearchForm
                                $api={this.props.$api}
                                $form={this.form}
                                listApi={this.listApi}
                            />
                    }
                    api={this.listApi}
                    color={'#fff'}
                    renderItem={this.renderItem}
                    autoLoad={'no-data'}
                    ItemSeparatorComponent={null}
                />

            </ADScreen>
        )
    }


    @autobind
    renderItem(p: { item, index }) {
        return (
            <ProjectListScreenItem
                $navigation={this.props.$navigation}
                item={p.item}
                onPress={() => this.handleItemPress(p)}
            />
        )
    }

    @autobind
    handleItemPress(p: { item: ProjectListModel, index }) {
        ProjectDetailsScreen.start(this.props.$navigation, {
            projectId: p.item.id
        })
    }

}

@inject('$api')
@observer
class SearchForm extends React.Component<{ $form: ADFormStore<ProjectFilterRequest>, listApi: ADListResource<any, any, any> } &
    InjectedApiServiceProps> {


    render() {
        return (
            <ADForm store={this.props.$form}>
                <ADRemoteView
                    style={styles.container}
                    alwaysRenderSuccess={!this.props.$api.userSettingApi.isError}
                    api={this.props.$api.userSettingApi}
                    autoLoad={'no-data'}>
                    <Col style={styles.container}>
                        <ADImage
                            style={styles.back}
                            resizeMode={'contain'}
                            source={Assets.images.project_filter_back}/>
                        <Col>
                            <Row>
                                <ADTextInput
                                    placeholder={i18n.strings.screens.project_list.filter.query}
                                    $id={'query'}
                                    style={{flex: 1}}
                                    fontSize={14}
                                />
                            </Row>
                            <Row style={{marginTop: 16}}>
                                <ADDateInput
                                    placeholder={i18n.strings.screens.project_list.filter.from_date}
                                    $id={'form_date'}
                                    style={{flex: 1}}
                                    fontSize={14}
                                />
                                <View style={{width: 16}}/>
                                <ADDateInput
                                    placeholder={i18n.strings.screens.project_list.filter.to_date}
                                    $id={'to_date'}
                                    style={{flex: 1}}
                                    fontSize={14}
                                />
                            </Row>

                            <Row style={{alignItems: 'flex-end', marginTop: 16}}>
                                <ADSelectInput
                                    $id={'service'}
                                    items={this.props.$api.userSettingApi.data.service_items}
                                    style={styles.service}
                                    textStyle={{
                                        minHeight: 32,
                                    }}
                                    // clearEnabled={false}
                                    lineWidth={0}
                                    underlineColorAndroid={'rgba(0,0,0,0)'}
                                    // placeholderTextColor={'#f1f1f1'}
                                    placeholder={i18n.strings.screens.project_list.filter.service}
                                    // textColor={'#fff'}
                                    pickerType={'dropdown'}
                                    endIconWrapperStyle={{marginTop: 8}}
                                    fontSize={14}
                                />
                                <View style={{width: 16}}/>
                                <ADButton
                                    style={{flex: 1}}
                                    accent
                                    filled
                                    title={i18n.strings.screens.project_list.filter.search}
                                    onSubmit={(data) => {
                                        this.props.listApi.mergeRequest(data);
                                        this.props.listApi.loadFirstPage()
                                    }}
                                />
                            </Row>
                        </Col>
                    </Col>
                </ADRemoteView>
            </ADForm>
        )
    }

}

const styles = DPStyleSheet.create(() => ({
    container: {
        paddingHorizontal: DPConfig.dimens.screenHorizontalPadding,
        backgroundColor: '#fff',
        paddingVertical: 16
    },
    back: {
        position: 'absolute',
        end: 0,
        bottom: 0,
        opacity: .2,
        width: DPConfig.dimens.screen.width * .4,
        height: DPConfig.dimens.screen.width * .4,
    },
    service: {
        flex: 1,
        paddingTop: 4,
        borderRadius: 2,
        borderBottomWidth: 1,
        borderBottomColor: '#d1d1d1'
    }
}));
