import * as React from 'react';
import {inject, observer} from 'mobx-react';
import {InteractionManager, KeyboardAvoidingViewComponent, Platform, View, ViewStyle, KeyboardAvoidingView} from 'react-native';
import autobind from 'autobind-decorator';
import TicketCommentListItem from './TicketCommentListItem';
import {
    ADButton,
    ADFlatList,
    ADIcon,
    ADRemoteView,
    ADResponsiveUtil,
    ADText,
    Col,
    DPConfig, DPScrollView,
    DPStyleSheet,
    DPTextInput,
    IDType,
    INavigationService,
    InjectedNavigationServiceProps,
    Row
} from '@dp/react-native-core';
import InjectedApiServiceProps from 'app/api/InjectedApiServiceProps';
import {TicketCommentItemModel, TicketDetailsModel} from 'app/api/Models';
import TicketListItem from '../tickets/TicketListItem';
import AppToolbar from 'app/screens/common/AppToolbar';
import {i18n} from 'app/i18n/i18n';
import {InjectedKeyboardServiceProps} from '@dp/react-native-core/dist/dpcoresrc/services/InjectedServiceProps';


type Params = {
    ticketId: IDType
}

type TicketDetailsScreenProps =
    Params &
    InjectedNavigationServiceProps &
    InjectedKeyboardServiceProps &
    InjectedApiServiceProps

@inject('$navigation', '$api', '$keyboard')
@observer
export default class TicketDetailsScreen extends React.Component<TicketDetailsScreenProps, { text: string }> {
    static RouteName = 'TicketDetailsScreen';

    static start(nav: INavigationService, p: Params) {
        nav.navigate(TicketDetailsScreen.RouteName, p)
    }

    state = {
        text: ''
    };

    protected conversationApi = DPConfig.http.createResource<{}, TicketDetailsModel>((p) => {
        return this.props.$api.getTicketById({ticket_id: this.props.ticketId})
    });


    render() {
        let data = this.conversationApi.data;
        let comments: Array<TicketCommentItemModel | 'header'> = data.comments || [];
        let trans = i18n.strings.screens.ticket_details;
        return (
            <Col style={styles.container}>
                <AppToolbar theme={'dark'}
                            title={trans.title}
                />
                <ADRemoteView
                    resource={this.conversationApi}
                    autoLoad={'no-data'}
                >
                    <Col style={[{flex: 1}, Platform.OS === 'web' && {height: 0}]}>
                        <ADFlatList
                            onRefresh={() => this.conversationApi.reload()}
                            refreshing={this.conversationApi.isLoading}
                            data={
                                Platform.OS == 'web' ?
                                    comments.concat(['header']).reverse() :
                                    comments.concat(['header'])
                            }
                            renderItem={this.renderItem}
                            ItemSeparatorComponent={null}
                            inverted={Platform.OS !== 'web'}
                            contentContainerStyle={{paddingBottom: 16, flexGrow: 0}}
                        />
                        {
                            this.renderInput()
                        }
                        {
                            Platform.OS === 'ios' &&
                            <View style={{minHeight: this.props.$keyboard.height}}/>
                        }
                    </Col>
                </ADRemoteView>
            </Col>
        )
    }

    protected renderInput = () => {
        let trans = i18n.strings.screens.ticket_details
        return (

            <Row
                key={'input-row'}
                style={[DPConfig.get().styles.boxShadowBottom, {
                    backgroundColor: '#fff',
                    borderTopColor: '#9e9e9e',
                    borderTopWidth: 1,
                    flexShrink: 0,
                    paddingVertical: 4,
                    paddingHorizontal: DPConfig.dimens.screen.horizontalPadding,
                }]}
            >
                <DPTextInput
                    key={'input'}
                    placeholderStyle={{
                        textAlign: 'right',
                        start: 0,
                    }}
                    multiline
                    style={{flex: 1}}
                    placeholder={trans.input.placeholder}
                    placeholderTextColor={DPConfig.colors.placeholderColor}
                    useNativePlaceholder={false}
                    textStyle={{
                        textAlign: 'right',
                        paddingVertical: 12
                    }}
                    onChangeText={(text) => {
                        this.setState({
                            text
                        })
                    }}

                    // onSubmitEditing={this.submit}
                    // returnKeyType={'send'}
                    // returnKeyLabel={trans.input.submit}
                    disableFullscreenUI
                    defaultValue={this.state.text}
                    blurOnSubmit={true}
                    underlineColor={DPConfig.colors.accentColor}
                    underlineEnabled={true}
                    after={
                        <ADButton style={{padding: 8}} onPress={this.submit}>
                            <ADIcon
                                icon={'send'}
                                mirror
                                color={DPConfig.colors.accentColor}/>
                        </ADButton>
                    }
                />
            </Row>
        );
    }

    @autobind
    protected renderHeader() {
        let data = this.conversationApi.data
        return (
            <View style={{backgroundColor: '#fff'}}>
                <TicketListItem
                    mini={false}
                    item={{
                        id: data.id,
                        code: data.code,
                        created_at: data.created_at,
                        subject: data.subject,
                        content: data.content,
                        priority_color: data.priority_color,
                        priority: data.priority,
                        status: data.status,
                        status_color: data.status_color
                    }}
                    footer={
                        !!data.ticket_status_list &&
                        <Row style={{
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            paddingVertical: 8,
                        }}>
                            <ADText fontSize={10} style={{marginEnd: 8}}>
                                {i18n.strings.screens.ticket_details.change_status_to}
                            </ADText>
                            {data.ticket_status_list.map(status => {
                                return (
                                    <ADButton
                                        key={status.id}
                                        title={status.name}
                                        outlined
                                        color={status.color}
                                        textStyle={{
                                            fontSize: ADResponsiveUtil.plus(10, 2),
                                            paddingHorizontal: 4,
                                            paddingVertical: 2
                                        }}
                                        style={{
                                            marginEnd: 8, marginVertical: 4
                                        }}
                                        onPress={() => this.handleChangeStatus(status)}
                                    />
                                )
                            })}
                        </Row>
                    }
                />

            </View>
        )
    }

    @autobind
    protected renderItem(p: { item: TicketCommentItemModel | 'header', index: number }) {
        if (p.item === 'header')
            return this.renderHeader()
        return <TicketCommentListItem item={p.item} index={p.index}/>;
    }

    @autobind
    protected handleChangeStatus(status: { name, color, id }) {
        this.props.$api.updateTicketStatus({
            ticket_id: this.props.ticketId,
            status_id: status.id
        })
            .onSuccess((response, call) => {
                this.conversationApi.onSuccess(response, call)
                this.props.$api.ticketListApi.loadFirstPage()
            })
            .defaultLoading()
            .defaultError(true)
            .enqueue()
    }

    lock = false

    @autobind
    async submit() {
        if (!this.state.text || this.lock) {
            return
        }
        this.lock = true
        InteractionManager.runAfterInteractions(() => {
            this.props.$api.sendTicketComment({
                ticket_id: this.props.ticketId,
                text: this.state.text
            })
                .onSuccess((response, call) => {
                    this.setState({
                        text: ''
                    });
                    this.conversationApi.reload();
                    this.props.$api.ticketListApi.loadFirstPage()
                })
                .onStopLoading(() => {
                    this.lock = false
                })
                .defaultError(true)
                .defaultLoading()
                .enqueue()
        })
    }
}

const styles = DPStyleSheet.create(({colors}) => ({
    container: {
        flex: 1,
        backgroundColor: colors.screenBackgroundColor
    } as ViewStyle,
}))