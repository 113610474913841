import React from 'react';
// import {View} from 'react-native';
import ReactDOM from 'react-dom';
import './index.css';
// import 'normalize.css';
import App from '../app/App';
import {hot} from 'react-hot-loader'

import FontAwesomeIconFont from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import MaterialIconsIconFont from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
import MaterialCommunityIconsIconFont from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import OcticonsFont from 'react-native-vector-icons/Fonts/Octicons.ttf';
import EntypoFont from 'react-native-vector-icons/Fonts/Entypo.ttf';
import SimpleLineIconsFont from 'react-native-vector-icons/Fonts/SimpleLineIcons.ttf';
import FeatherFont from 'react-native-vector-icons/Fonts/Feather.ttf';
import EvilIconsFont from 'react-native-vector-icons/Fonts/EvilIcons.ttf';
import ZocialFont from 'react-native-vector-icons/Fonts/Zocial.ttf';
import FoundationFont from 'react-native-vector-icons/Fonts/Foundation.ttf';
import IoniconsFont from 'react-native-vector-icons/Fonts/Ionicons.ttf';

// import {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';

function injectFont(name, url) {
// Generate required css
    const iconFontStyles = `@font-face { src: url(${url}); font-family: ${name};}`;
// Create stylesheet
    const style = document.createElement('style');
    style.type = 'text/css';
    if (style.styleSheet) {
        style.styleSheet.cssText = iconFontStyles;
    } else {
        style.appendChild(document.createTextNode(iconFontStyles));
    }
// Inject stylesheet
    document.head.appendChild(style);
}


injectFont('FontAwesome', FontAwesomeIconFont);
injectFont('Octicons', OcticonsFont);
injectFont('MaterialIcons', MaterialIconsIconFont);
injectFont('Material Icons', MaterialIconsIconFont);
injectFont('MaterialCommunityIcons', MaterialCommunityIconsIconFont);
injectFont('Material CommunityIcons', MaterialCommunityIconsIconFont);
injectFont('Entypo', EntypoFont);
injectFont('simple-line-icons', SimpleLineIconsFont);
injectFont('Feather', FeatherFont);
injectFont('EvilIcons', EvilIconsFont);
injectFont('Zocial', ZocialFont);
injectFont('Foundation', FoundationFont);
injectFont('Ionicons', IoniconsFont);

// const App = () => {
//     return <View style={{height:100,width:100,backgroundCOlor:'red'}}/>
// }

// if (process.env.NODE_ENV === 'development') {
//     const HotApp = hot(module)(App);
//     ReactDOM.render(<HotApp/>, document.getElementById('root'));
// } else {
ReactDOM.render(<App/>, document.getElementById('root'));
// }

// let targetElement = document.getElementById('root');
// disableBodyScroll(targetElement);
// enableBodyScroll(targetElement);
// targetElement = document.querySelector('body');
// disableBodyScroll(targetElement);
// enableBodyScroll(targetElement);

const contextWhiteList = ['input', 'textarea']
// disable all context menu
document.addEventListener('contextmenu', (e) => {
    if (!e) return;
    let clickedTag = e.target.tagName;
    if (clickedTag && !contextWhiteList.find(it => it === clickedTag.toLowerCase())) {
        e.preventDefault();
    }
});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
// OfflinePluginRuntime.install();
//


const offlinePluginRuntime = require('offline-plugin/runtime')
offlinePluginRuntime.install({
    onUpdateReady: () => {
        // Tells to new SW to take control immediately
        offlinePluginRuntime.applyUpdate();
    },
});
// offlinePluginRuntime.update()
