import * as RNWeb from 'react-native-web'
import RNWebDefault from 'react-native-web'
import BackHandler from "./api/BackHandler";
import Linking from "./api/Linking";

const overriddenExports = {
    BackHandler: BackHandler,
    Linking:Linking
};


let DPRNWeb = Object.assign({}, RNWeb, overriddenExports);
let DPRNWebDefault = Object.assign({}, RNWebDefault, overriddenExports);
module.exports = DPRNWeb;
module.exports.default = DPRNWebDefault;
module.default = DPRNWebDefault;
