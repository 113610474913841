export default {
  title: "Kplus",
  screens: {
    intro: {
      slide1: {
        title: "شرکت در دوره ها",
        description:
          "با شرکت در دوره های Kplus شانس خود را جهت دریافت پیشنهادات افزایش دهید.",
      },
      slide2: {
        title: "پروژه های من",
        description:
          "سوابق پروژه های خود را در بخش (پروژه های من) پیدا خواهید کرد.",
      },
      slide3: {
        title: "تنظیمات",
        description:
          "بخش تنظیمات را بروز کنید تا پروژه هایی مطابق شرایط کاری شما برایتان ارسال شود.",
      },
      slide4: {
        title: "شرح فنی",
        description:
          "قبل از اتمام هر پروژه باید فرم شرح فنی آن پروژه را تکمیل و ارسال نمایید.",
      },
      next: "بعدی",
      done: "به Kplus خوش آمدید",
    },
    login: {
      exit_confirm: "برای خروج دوباره برگشت را بزنید",
      mobile: "شماره تلفن همراه",
      mobile_required: "لطفا شماره تلفن همراه خود را وارد کنید.",
      btn_continue: "ادامه",
    },
    verification: {
      back_to_login_confirm: "برای تغییر شماره دوباره برگشت را بزنید.",
      note: "کد فعال سازی به شماره :mobile ارسال شد. لطفا کد دریافت شده را وارد کنید",
      code: "کد فعال سازی",
      btn_login: "ورود",
      code_required: "لطفا کد دریافت شده را وارد کنید",
      resend: "دریافت نکرده اید؟ ارسال مجدد",
      resend_timer: "ارسال مجدد کد فعال سازی :second ثانیه",
      press_back_again_to_change_number:
        "برای تغییر شماره دوباره برگشت را بزنید.",
    },
    dashboard: {
      title: "داشبورد",
      exit_back_confirm: "برای خروج دوباره برگشت را بزنید.",
      header: {
        received_score: "امتیازات موجود : ",
        exist_score: "امتیازات دریافتی : ",
        admission_rate: "نرخ پذیرش : ",
        project_done: "تعداد پروژه انجام شده",
        project_suggest: "تعداد پروژه پیشنهاد شده",
      },
      running: {
        id: "شناسه : ",
        address: "آدرس : ",
      },
      suggest: {
        title: "پروژه های پیشنهادی",
        user_project: "پروژه های من",
        no_suggest: "در حال حاضر پیشنهادی وجود ندارد",
        area: "متراژ : ",
        id: "شناسه : ",
        address: "آدرس : ",
      },
      offer: {
        title: "دریافت کد تخفیف",
        sub_title: "(مشاهده جزییات)",
      },
    },
    project_list: {
      title: "پروژه های من",
      filter: {
        query: "کلمه مورد جستجو",
        from_date: "از تاریخ",
        to_date: "تا تاریخ",
        service: "نام خدمت",
        search: "جستجو",
      },
      item: {
        id: "شناسه : ",
        area: "متراژ : ",
        address: "آدرس : ",
        date: "تاریخ ایجاد : ",
        status: {
          pending: "در انتظار تایید",
          running: "درحال اجرا",
          ended: "خاتمه یافته",
          canceled: "لغو شده",
          stopped: "توقف",
        },
      },
    },
    project_details: {
      title: "جزییات پروژه",
      show_details: "نمایش جزییات",
      accept: {
        title: "قبول درخواست",
        confirm: "برای قبول درخواست نگه دارید...",
      },
      start: {
        title: "فرآیند آغاز پروژه",
        confirm: "برای آغاز پروژه نگه دارید...",
      },
      technical: {
        title: "ثبت شرح فنی",
        confirm: "برای ثبت نگه دارید...",
      },
      times: {
        title: "ثبت زمانبندی",
        confirm: "برای ثبت نگه دارید...",
      },
      done: {
        title: "اتمام پروژه",
        confirm: "برای اتمام پروژه نگه دارید...",
      },
      confirm: {
        submit: "ارسال",
        cancel: "انصراف",
        input_label: "کد تایید",
        input_placeholder: "کد برای مشتری پیامک شده است.",
      },
      success: {
        ok: "تایید",
      },
    },
    news_list: {
      title: "لیست اخبار",
    },
    news_details: {
      title: "جزییات خبر",
      like: "پسندیدن",
      show_gallery: "نمایش گالری (:count)",
      video_error: "مشکل در نمایش ویدیو رخ داده است",
      comment: {
        input_label: "نظر خود را بنویسید",
        no_comments: "نظری داده نشده است.",
        submit: "ارسال",
        submit_success: {
          title: "موفق",
          message: "با موفقیت ارسال شد",
          ok: "تایید",
        },
      },
    },
    news_gallery: {
      title: "title",
    },
    profile: {
      title: "ویرایش پروفایل کاربری",
      mobile: "شماره موبایل",
      address: "نشانی",
      courses: "دوره ها",
      update: "بروزرسانی پروفایل",
      update_success: {
        title: "موفق",
        message: "با موفقیت بروزرسانی شد",
        ok: "تایید",
      },
    },
    discount_list: {
      title: "title",
      item: {
        consumed: "خرج شده",
        not_consumed: "خرج نشده",
        code: "کد تخفیف : ",
        credit: "میزان امتیاز : ",
        credit_suffix: " امتیاز",
        value: "مبلغ : ",
        operator: "عامل : ",
        date: "تاریخ : ",
      },
      share: {
        title: "کد تخفیف Kplus : ",
        message: "کد تخفیف شما در Kplus : ",
        dialogTitle: "اشتراک گذاری کد تخفیف : ",
        subject: "کد تخفیف Kplus",
      },
    },
    discount_request: {
      title: "دریافت کد تخفیف",
      your_score: "امتیاز شما : ",
      your_score_suffix: " امتیاز",
      convert: "دریافت کد تخفیف",
      show_list: "مشاهده سوابق تخفیفات دریافتی",
      confirm: {
        title: "تبدیل امتیاز",
        message: ":score امتیاز به کد تخفیف تبدیل شود؟",
        cancel: "انصراف",
        convert: "تبدیل",
        convert_success: {
          title: "موفق",
          message: "با موفقیت تبدیل شد.",
          ok: "تایید",
        },
      },
    },
    setting: {
      title: "تنظیمات",
      service: "نوع خدمت",
      area: "متراژ",
      status: {
        title: "وضعیت :",
        active: "فعال",
        inactive: "غیرفعال",
      },
      update: "ثبت تغییرات",
      update_success: {
        title: "موفق",
        message: "با موفقیت ذخیره شد",
        ok: "تایید",
      },
    },
    technical: {
      title: "ثبت شرح فنی",
      close_confirm: "برای بستن دوباره برگشت را بزنید.",
      wall_info: "اطلاعات دیوار",
      wall_type: "نوع دیوار",
      roof_info: "سقف",
      roof_type: "نوع سقف",
      inputs: {
        wall_area: "متراژ (متر مربع)",
        roof_area: "متراژ (متر مربع)",
        wall_area_required: 'لطفا اطلاعات متراژ "دیوار" را کامل کنید.',
        wall_type_required: 'لطفا اطلاعات نوع "دیوار" را کامل کنید.',
        roof_area_required: 'لطفا اطلاعات متراژ "سقف" را کامل کنید.',
        roof_type_required: 'لطفا اطلاعات نوع "سقف" را کامل کنید.',
      },
      on_time_deadline: {
        question: "آیا پروژه در زمان مقرر با مشتری به اتمام رسیده است؟",
        yes: "بله",
        no: "خیر",
        description: "توضیحات تکمیلی",
        required: 'لطفا به "سوال اتمام پروژه در زمان بند‍ی" پاسخ دهید',
      },
      operator: {
        question:
          "محصولات مورد نیاز جهت اجرا را از کدام عاملیت (های) Kplus تهیه کردید؟",
        sale_agent: "عاملیت",
        employer: "تامین جنس توسط کارفرما",
        direct: "خرید مستقیم",
        client: "تامین جنس از موجودی مجری",
        required: 'لطفا به سوال "محل تامین محصولات مورد نیاز پروژه" پاسخ دهید',
        sale_agent_factor: {
          select: {
            title: "انتخاب از فاکتورهای قبلی",
            factor: {
              title: "انتخاب فاکتور",
              required: "لطفا فاکتور را انتخاب کنید",
            },
          },
          create: {
            title: "فاکتور جدید",
            name: {
              title: "نام عامل",
              required: "لطفا نام عامل را مشخص کنید",
            },
            date: {
              title: "تاریخ فاکتور",
              required: "لطفا تاریخ فاکتور را مشخص کنید",
            },
            number: {
              title: "شماره فاکتور",
              required: "لطفا شماره فاکتور را مشخص کنید",
            },
            area: {
              title: "متراژ پنل و تایل",
              required: "لطفا متراژ پنل و تایل را مشخص کنید",
            },
            images: {
              required: "لطفا تصاویر فاکتور را اضافه کنید",
              title: "تصاویر فاکتور",
              add: "افزودن تصویر",
              picker: {
                gallery: "گالری",
                camera: "دوربین",
                title: "انتخاب تصویر فاکتور",
              },
            },
          },
        },
      },
      client: {
        question: "آیا از عملکرد مشتری رضایت دارید؟",
        yes: "رضایت دارم",
        no: "خیر",
        description: "توضیحات تکمیلی",
        required: 'لطفا به سوال "رضایتمندی از مشتری" پاسخ دهید',
      },
      images: {
        title: "تصاویر پروژه",
        picker: {
          title: "انتخاب تصویر",
          camera: "دوبین",
          gallery: "گالری",
        },
        add: "افزودن تصویر",
        input_label: "عنوان",
        required: 'لطفا "تصاویر پروژه" را اضافه کنید',
      },
      factors: {
        title: "فاکتورهای پروژه",
        picker: {
          title: "انتخاب تصویر فاکتور",
          camera: "دوبین",
          gallery: "گالری",
        },
        add: "افزودن فاکتور",
        input_label: "عنوان",
        required: 'لطفا "فاکتورهای پروژه" را اضافه کنید',
      },
      save: "ثبت شرح فنی پروژه",
      save_confirm: {
        title: "آیا از ثبت گزارش فنی اطمینان دارید؟",
        message: "گزارش پس از ثبت غیرقابل ویرایش می باشد.",
        confirm: "تایید و ثبت نهایی",
        cancel: "انصراف",
      },
      save_success: {
        title: "",
        message: "با موفقیت ذخیره شد.",
        ok: "تایید",
      },
      validation_failed: {
        title: "خطا",
        ok: "تایید",
      },
      help: {
        title: "راهنما",
        ok: "تایید",
      },
    },
    times: {
      title: "ثبت زمانبندی",
      close_confirm: "برای بستن دوباره برگشت را بزنید.",
      inputs: {
        substruction_time: "زمان شروع زیرسازی",
        paneling_time: "زمان شروع پنل گذاری",
        sealing_time: "زمان شروع درزگیری",
      },
      validation: {
        substruction_time: "لطفا زمان شروع زیرسازی را وارد کنید",
        paneling_time: "لطفا زمان شروع پنل گذاری را وارد کنید",
        sealing_time: "لطفا زمان شروع درزگیری را وارد کنید",
      },
      select_date: "انتخاب تاریخ",
      save: "ثبت زمانبندی پروژه",
      save_confirm: {
        title: "آیا از ثبت زمانبدی اطمینان دارید؟",
        message: "گزارش پس از ثبت غیرقابل ویرایش می باشد.",
        confirm: "تایید و ثبت نهایی",
        cancel: "انصراف",
      },
      save_success: {
        title: "",
        message: "با موفقیت ذخیره شد.",
        ok: "تایید",
      },
    },
    portfolio_list: {
      title: "نمونه کارها",
    },
    portfolio_add: {
      title: " افزودن نمونه کار",
      picker: {
        title: "انتخاب عکس",
        camera: "دوبین",
        gallery: "گالری",
      },
      add: "افزودن عکس جدید",
      input_label: "عنوان",
      save: "آپلود تصایر",
      add_success: {
        title: "موفق",
        message: "با موفقیت اضافه شد",
        ok: "تایید",
      },
    },
    portfolio_edit: {
      title: "ویرایش نمونه کار",
      delete_confirm: {
        title: "حذف شود؟",
        message: "آیا از حذف این نمونه کار مطمئن هستید؟",
        confirm: "حذف شود",
        cancel: "انصراف",
      },
      delete_success: {
        title: "موفق",
        message: "با موفقیت حذف شد",
        ok: "تایید",
      },
      edit_success: {
        title: "موفق",
        message: "با موفقیت بروز شد",
        ok: "تایید",
      },
      state: {
        pending: "در انتظار تایید",
        approved: "تایید شده",
        rejected: "رد شده",
      },
      update: "بروزرسانی",
    },
    ticket_list: {
      title: "تیکت ها",
      item: {
        code: "شماره :",
      },
    },
    ticket_details: {
      title: "جزییات تیکت",
      input: {
        placeholder: "پیام خود را اینجا وارد کنید.",
        // submit: "ارسال"
      },
      change_status_to: "تغییر وضعیت تیکت به :",
    },

    ticket_create: {
      title: "ایجاد تیکت",
      priority: "اولویت",
      subject: "موضوع",
      content: "شرح درخواست",
      create: "ایجاد تیکت",
      success: {
        title: "",
        message: "تیکت شما با موفقیت ایجاد شد.",
        ok: "تایید",
      },
    },
    project_comments: {
      title: "نظرات پروژه",
      input: {
        placeholder: "پیام خود را اینجا وارد کنید.",
        submit: "ارسال",
      },
    },
    project_files: {
      title: "فایل های پروژه",
      sale_files: "فایل های مشاور فروش",
      tech_files: "فایل های مشاور فنی",
    },
    performance_history: {
      title: "تاریخچه عملکرد",
      score_title: "عنوان",
      project_name: "نام پروژه",
      date: "در تاریخ",
      total_score: "مجموع عملکرد",
      next_score: "عملکرد برای مرحله بعد",
    },
  },
  drawer: {
    header: {
      received_score: "امتیازات موجود : ",
      exist_score: "امتیازات دریافتی : ",
      admission_rate: "نرخ پذیرش : ",
    },
    items: {
      user_projects: "پروژه های من",
      news: "اخبار",
      profile: "ویرایش اطلاعات شخصی",
      offer: "کد تخفیف",
      tickets: "تیکت ها",
      portfolio: "نمونه کار",
      setting: "تنظیمات",
      support: "پشتیبانی",
      exit: "خروج از حساب کرابری",
    },
    exit_confirm: {
      title: "خروج",
      message: "آیا از حساب کاربری خود خارج می شوید؟",
      confirm: "خروج",
      cancel: "انصراف",
    },
  },
  readonly: "readonly",
};
