import {
  ADText,
  DPConfig,
  DPStyleSheet,
  InjectedNavigationServiceProps,
} from "@dp/react-native-core";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { TouchableOpacity } from "react-native";
import ScoreHistoryScreen from "../score-history/ScoreHistoryScreen";

type ProfileScreenProps = Partial<InjectedNavigationServiceProps> & {
  title?: string;
  height?: number;
  clickable?: boolean;
};

type State = {};

@inject("$navigation")
@observer
export default class PerformanceLabel extends React.Component<
  ProfileScreenProps,
  State
> {
  render() {
    return (
      <TouchableOpacity
        onPress={() => {
          if (this.props.clickable && this.props.$navigation)
            ScoreHistoryScreen.start(this.props.$navigation);
        }}
        activeOpacity={0.8}
        style={[
          styles.label,
          this.props.height && {
            height: this.props.height,
            borderRadius: this.props.height / 2,
          },
        ]}
      >
        <ADText
          numberOfLines={1}
          style={{
            paddingVertical: 2,
            paddingHorizontal: 8,
            textAlignVertical: "center",
            textAlign: "center",
            lineHeight: this.props.height || 32,
          }}
          title={this.props.title || "-"}
        />
      </TouchableOpacity>
    );
  }
}

const styles = DPStyleSheet.create(({ dimens, styles, colors }) => ({
  back: {
    position: "absolute",
    end: -DPConfig.dimens.screen.width * 0.1,
    bottom: -8,
    opacity: 0.5,
    width: DPConfig.dimens.screen.width * 0.4,
    height: ((DPConfig.dimens.screen.width * 0.4) / 389) * 392,
  },
  label: {
    height: 32,
    borderRadius: 16,
    borderColor: "#fff",
    borderWidth: 1,
    justifyContent: "center",
    alignItems: "center",
    minWidth: 60,
    alignSelf: "center",
    backgroundColor: DPConfig.colors.secondaryColor,
    overflow: "hidden",
  },
}));
