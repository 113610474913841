import * as React from 'react';
import {inject, observer} from 'mobx-react';
import {
    ADIcon,
    ADListResource, ADResponsiveUtil,
    ADText,
    ADTouchable,
    Col,
    DPConfig, DPPlaceholder,
    DPSelectUtil,
    DPStyleSheet,
    InjectedNavigationServiceProps,
    Row,
} from '@dp/react-native-core';
import {View, ViewStyle} from 'react-native';
import {ProjectListModel} from 'app/api/Models';
import {i18n} from '../../../../i18n/i18n';

export type ProjectListScreenItemProps =
    InjectedNavigationServiceProps &
    {
        item: ProjectListModel
        onPress?: () => void,
        $resource?: ADListResource<any, any, any>
    }

type State = {}

@inject('$navigation', '$resource')
@observer
export default class ProjectListScreenItem extends React.Component<ProjectListScreenItemProps, State> {
    static defaultProps = {
        item: {}
    };
    state = {};

    render() {
        let {item, onPress} = this.props;
        let statusColor = item.status_color || DPSelectUtil.select(item.status, {
            pending: DPConfig.colors.secondaryColor,
            running: DPConfig.colors.primaryColor,
            ended: '#9e9e9e',
            canceled: DPConfig.colors.errorColor,
            stopped: '#9e9e9e',
        });
        let statusName = item.status_name || DPSelectUtil.select(item.status, i18n.strings.screens.project_list.item.status);
        const gap = ADResponsiveUtil.dimen(8, 12, 16)
        const iconSize=ADResponsiveUtil.dimen(14, 18, 20)
        return (
            <ADTouchable onPress={onPress}>
                <Col style={styles.container}>
                    <Row style={styles.header}>
                        <Row style={{flex: 1, marginEnd: gap / 2}}>
                            <ADText
                                title={i18n.strings.screens.project_list.item.id + (item.code || item.id)}
                                bold
                                style={DPConfig.styles.growWidth}
                                numberOfLines={1}
                                fontSize={14}/>
                        </Row>

                        <Row style={{alignItems: 'center'} as ViewStyle}>
                            <View style={[styles.dot, {backgroundColor: statusColor}]}/>
                            <ADText
                                title={statusName}
                                color={statusColor}
                                numberOfLines={1}
                                fontSize={10}
                                fontSizeInc={1}
                            />
                        </Row>
                    </Row>

                    <Row style={{alignItems: 'center', marginVertical: 4} as ViewStyle}>
                        <Row style={{flexGrow: 1}}>
                            <ADIcon icon={'ruler'}
                                    type={'MaterialCommunityIcons'}
                                    size={iconSize}
                                    style={{marginEnd: gap / 4}}
                                    color={DPConfig.colors.defaultTextColorLight}
                            />
                            <ADText fontSize={12}
                                    bold
                                    title={i18n.strings.screens.project_list.item.area}/>
                            <ADText
                                title={item.area}
                                numberOfLines={1}
                                fontSize={12}/>
                        </Row>
                        <ADText
                            title={'|'}
                            fontSize={14}
                            color={DPConfig.colors.defaultTextColorLight}
                            style={{
                                marginHorizontal: gap
                            }}
                        />
                        <Row style={{flexGrow: 1}}>
                            <ADIcon icon={'date-range'}
                                    size={iconSize}
                                    style={{marginEnd: gap / 4}}
                                    color={DPConfig.colors.defaultTextColorLight}/>
                            <ADText fontSize={12}
                                    bold
                                    title={i18n.strings.screens.project_list.item.date}/>
                            <ADText
                                title={item.create_date}
                                numberOfLines={1}
                                fontSize={12}/>
                        </Row>
                    </Row>

                    <Row>
                        <ADIcon icon={'location-on'}
                                size={iconSize}
                                style={{marginEnd: 4}}
                                color={DPConfig.colors.defaultTextColorLight}/>
                        <ADText fontSize={12}
                                bold
                                numberOfLines={1}
                                title={i18n.strings.screens.project_list.item.address}/>
                        <ADText
                            style={DPConfig.styles.growWidth}
                            title={item.address}
                            numberOfLines={2}
                            fontSize={12}/>
                    </Row>
                </Col>
            </ADTouchable>
        )
    }


}

const styles = DPStyleSheet.create(({dimens, colors, fonts, styles}) => ({
    container: {
        ...styles.card,
        paddingHorizontal: 12,
        paddingBottom: 12,
        marginHorizontal: dimens.screenHorizontalPadding,
    },
    header: {
        borderBottomColor: '#f1f1f1',
        borderBottomWidth: 1.5,
        paddingVertical: 8
    },
    dot: {
        width: 8,
        height: 8,
        borderRadius: 4,
        marginEnd: 4,
    }
}));
