import {
  ADForm,
  ADFormStore,
  ADImage,
  ADRemoteView,
  ADResponsiveUtil,
  ADScreen,
  ADText,
  Col,
  DPColorUtil,
  DPConfig,
  DPPlaceholder,
  DPScrollView,
  DPStyleSheet,
  FlatList,
  INavigationService,
  InjectedNavigationServiceProps,
  Row,
} from "@dp/react-native-core";
import DPDefaultStyles from "@dp/react-native-core/dist/dpcoresrc/config/DPDefaultStyles";
import InjectedApiServiceProps from "app/api/InjectedApiServiceProps";
import {
  ProfileModel,
  ProfilePerformanceHistoryModel,
  ProfileScoreModel,
  ProfileUpdateModel,
} from "app/api/Models";
import { AppToolbar } from "app/screens/common/AppToolbar";
import autobind from "autobind-decorator";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { Animated, LayoutChangeEvent, View } from "react-native";
import Assets from "../../../assets/Assets";
import { i18n } from "../../../i18n/i18n";
import PerformanceLabel from "../profile/PerformanceLabel";

type Params = {
  // id: IDType
};

type ScoreHistoryScreenProps = Params &
  InjectedNavigationServiceProps &
  InjectedApiServiceProps;

type State = {
  headerHeight?: number;
};

@inject("$navigation", "$api")
@observer
export default class ScoreHistoryScreen extends React.Component<
  ScoreHistoryScreenProps,
  State
> {
  static RouteName = "ScoreHistoryScreen";

  static start(nav: INavigationService, param?: Params) {
    nav.navigate(ScoreHistoryScreen.RouteName, param);
  }

  state = {
    headerHeight: ADResponsiveUtil.height / 4,
  };
  protected form = new ADFormStore<
    ProfileUpdateModel &
      ProfileScoreModel & {
        performance_history: ProfilePerformanceHistoryModel["history"];
        performance_message: string;
      }
  >();
  protected api = DPConfig.http.createResource<{ id }, ProfileModel>((p) => {
    return this.props.$api.getProfileInfo({ ...p }).onSuccess((response) => {
      console.log(response);

      this.form.clear();
      this.form.setData("mobile", response.data.mobile);
      if (response.data.address)
        this.form.setData("address", response.data.address);

      this.form.setStaticData("courses", response.data.courses_items);
      this.form.setData("courses", response.data.courses);
      this.apiProfileScore.load({});
    });
  });
  protected apiProfileScore = DPConfig.http.createResource<
    {},
    ProfileScoreModel
  >((p) => {
    return this.props.$api.getProfileScore(p).onSuccess((response) => {
      this.apiScoreHistory.load({});
    });
  });
  protected apiScoreHistory = DPConfig.http.createResource<
    {},
    ProfilePerformanceHistoryModel
  >((p) => {
    return this.props.$api
      .getProfilePerformanceHistory(p)
      .onSuccess((response) => {
        this.forceUpdate();
      });
  });
  scrollValue = new Animated.Value(0);

  render() {
    let data: ProfileModel = this.api.data;
    return (
      <ADForm store={this.form}>
        <ADScreen>
          <ADImage
            style={styles.back}
            resizeMode={"contain"}
            source={Assets.images.profile_background}
          />
          <DPScrollView
            style={{ backgroundColor: "#009EEE" }}
            api={this.api}
            animatedScrollValue={this.scrollValue}
          >
            <Col
              style={{
                flexGrow: 1,
                backgroundColor: DPConfig.colors.screenBackgroundColor,
              }}
            >
              {this.renderHeader(data)}
              <ADRemoteView api={this.api} autoLoad>
                <Col style={styles.form}>
                  {!!this.apiScoreHistory.data.message && (
                    <Row
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ADText
                        title={this.apiScoreHistory.data.message}
                        fontSize={12}
                        bold
                        style={{
                          marginBottom: 8,
                          marginTop: 12,
                        }}
                      />
                    </Row>
                  )}
                  <Row style={{ alignItems: "flex-start" }}>
                    <FlatList
                      data={this.apiScoreHistory.data.history}
                      renderItem={({ item, index }) => {
                        return (
                          <Row
                            style={{
                              alignItems: "center",
                              ...DPDefaultStyles.boxShadowBottom,
                              backgroundColor: "#fff",
                              marginHorizontal:
                                DPConfig.dimens.screenHorizontalPadding,
                              marginTop: 16,
                              padding: ADResponsiveUtil.dimen(8, 8, 16, 16),
                              borderRadius: 4,
                            }}
                          >
                            <Col style={{ alignItems: "stretch", flex: 1 }}>
                              <DPPlaceholder.Row>
                                <ADText
                                  fontSize={11}
                                  bold
                                  numberOfLines={1}
                                  title={
                                    i18n.strings.screens.performance_history
                                      .date
                                  }
                                  style={{ marginEnd: 8 }}
                                />
                                <ADText
                                  title={item.date}
                                  numberOfLines={1}
                                  fontSize={12}
                                />
                                <Col style={{ flex: 1 }} />
                                <Col
                                  style={{
                                    backgroundColor: DPColorUtil.fade(
                                      DPConfig.colors.primaryColor,
                                      0.8
                                    ),
                                    borderColor: DPConfig.colors.primaryColor,
                                    borderWidth: 1,
                                    borderRadius: 10,
                                    minHeight: 20,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    paddingHorizontal: 8,
                                  }}
                                >
                                  <ADText
                                    color={DPConfig.colors.primaryColor}
                                    fontSize={11}
                                    numberOfLines={1}
                                  >
                                    {
                                      i18n.strings.screens.performance_history
                                        .project_name
                                    }{" "}
                                    {item.project}
                                  </ADText>
                                </Col>
                              </DPPlaceholder.Row>
                              <DPPlaceholder.Row style={{ marginTop: 8 }}>
                                <ADText
                                  fontSize={11}
                                  bold
                                  numberOfLines={1}
                                  title={
                                    i18n.strings.screens.performance_history
                                      .score_title
                                  }
                                  style={{ marginEnd: 8 }}
                                />
                                <ADText
                                  fontSize={11}
                                  style={DPConfig.styles.growWidth}
                                  numberOfLines={1}
                                  title={item.title}
                                />
                                <Col style={{ flex: 1 }} />
                                <ADText
                                  fontSize={11}
                                  style={DPConfig.styles.growWidth}
                                  numberOfLines={1}
                                  title={item.score}
                                />
                              </DPPlaceholder.Row>
                            </Col>
                          </Row>
                        );
                      }}
                    ></FlatList>
                  </Row>
                </Col>
              </ADRemoteView>
            </Col>
          </DPScrollView>

          <AppToolbar
            theme={"dark"}
            title={i18n.strings.screens.performance_history.title}
            textColor={DPConfig.colors.accentTextColor}
            backgroundOpacity={this.scrollValue.interpolate({
              inputRange: [
                0,
                DPConfig.dimens.toolbarHeight +
                  DPConfig.dimens.toolbarPaddingTop,
              ],
              outputRange: [0, 1],
            })}
            shadow={false}
            style={{ position: "absolute", top: 0, left: 0, right: 0 }}
          />
        </ADScreen>
      </ADForm>
    );
  }

  @autobind
  private renderHeader(data: ProfileModel) {
    return (
      <Col
        style={{
          alignItems: "stretch",
        }}
        onLayout={(e: LayoutChangeEvent) => {
          this.setState({
            headerHeight: e.nativeEvent.layout.height,
          });
        }}
      >
        <ADImage
          resizeMode={"stretch"}
          source={Assets.images.dashboard_header_back}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        />

        <Row
          style={{
            height:
              DPConfig.dimens.toolbarHeight + DPConfig.dimens.statusBarHeight,
          }}
        />

        <Col style={{ alignItems: "center" }}>
          <Col style={{ justifyContent: "center", marginBottom: 16 }}>
            <Row>
              <ADText
                numberOfLines={1}
                title={i18n.strings.screens.performance_history.total_score}
                style={styles.totalScoreLabel}
              />
              <ADText
                numberOfLines={1}
                bold
                style={styles.performance_level}
                title={this.apiProfileScore.data.performance_score}
              />
              {this.apiProfileScore.data.performance_range &&
                this.apiProfileScore.data.performance_range.end && (
                  <Row style={{ alignSelf: "stretch" }}>
                    <Col>
                      <View style={styles.header_horizontal_separator} />
                    </Col>
                    <Row>
                      <ADText
                        numberOfLines={1}
                        title={
                          i18n.strings.screens.performance_history.next_score
                        }
                        style={styles.totalScoreLabel}
                      />
                      <ADText
                        numberOfLines={1}
                        bold
                        style={styles.performance_level}
                        title={this.apiProfileScore.data.performance_range.end}
                      />
                    </Row>
                  </Row>
                )}
            </Row>
            <Row
              style={{
                marginTop: 12,
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <PerformanceLabel
                title={this.apiProfileScore.data.performance_title}
              />
            </Row>
          </Col>
        </Col>
      </Col>
    );
  }
}

let AVATAR_SIZE = ADResponsiveUtil.dimen(60, 70, 80);

const styles = DPStyleSheet.create(({ dimens, styles, colors }) => ({
  back: {
    position: "absolute",
    end: -DPConfig.dimens.screen.width * 0.1,
    bottom: -8,
    opacity: 0.5,
    width: DPConfig.dimens.screen.width * 0.4,
    height: ((DPConfig.dimens.screen.width * 0.4) / 389) * 392,
  },
  nameBox: {
    borderRadius: 16,
    borderColor: "#fff",
    borderWidth: 1,
    justifyContent: "center",
    alignItems: "center",
    minWidth: AVATAR_SIZE,
  },
  form: {
    marginHorizontal: dimens.screenHorizontalPadding,
    flexGrow: 1,
    alignItems: "stretch",
  },
  totalScoreLabel: {
    fontSize: ADResponsiveUtil.plus(13, 2),
    color: colors.primaryTextColor,
    textAlignVertical: "center",
    marginEnd: 8,
  },
  performance_level: {
    fontSize: ADResponsiveUtil.plus(14, 2),
    color: colors.primaryTextColor,
    textAlignVertical: "center",
  },
  header_horizontal_separator: {
    flex: 1,
    width: 2,
    backgroundColor: colors.primaryTextColor,
    marginHorizontal: 16,
    marginVertical: "10%",
    borderRadius: 2,
  },
}));
