import {
  ADButton,
  ADDateInput,
  ADForm,
  ADFormStore,
  ADHttpRequestFile,
  ADIcon,
  ADImage,
  ADRemoteView,
  ADRequiredValidator,
  ADResponsiveUtil,
  ADScreen,
  ADSelectInput,
  ADText,
  ADTextInput,
  AlertDialog,
  Col,
  DPCheckBoxInput,
  DPConfig,
  DPFakeUtil,
  DPProgressBar,
  DPScrollView,
  DPStyleSheet,
  DPUploadFiles,
  IDType,
  INavigationService,
  InjectedNavigationServiceProps,
  Row,
} from "@dp/react-native-core";
import InjectedApiServiceProps from "app/api/InjectedApiServiceProps";
import autobind from "autobind-decorator";
import { inject, Observer, observer } from "mobx-react";
import * as React from "react";
import { View } from "react-native-animatable";
import { ProfileModel, TechnicalFormInfoModel } from "../../../api/Models";
import Assets from "../../../assets/Assets";
import { i18n } from "../../../i18n/i18n";
import { AppToolbar } from "../../common/AppToolbar";
import FormSection from "./FormSection";
import { SelectInputItem } from "./SelectInputItem";

type Params = {
  projectId: IDType;
  onSuccess?: () => void;
};

export type TechnicalInfoSubmitModel = {
  wall_area;
  wall_type;
  roof_area;
  roof_type;
  project_id: IDType;
  images: Array<ADHttpRequestFile>;
  factors: Array<ADHttpRequestFile>;
  // agent: Array<IDType>
  on_time_deadline?;
  on_time_deadline_description?: string;
  client_satisfied?: boolean;
  client_satisfied_description?: string;

  agent: typeof AGENT_EMPLOYER;
} & (
  | {
      agent: typeof AGENT_SALE_AGENT;
      sale_agent_factor_is_new: false;
      sale_agent_factor_id: string;

      sale_agent_factor_name?: never;
      sale_agent_factor_number?: never;
      sale_agent_factor_images?: never;
      sale_agent_factor_area: string | number;
      sale_agent_factor_date?: never;
    }
  | {
      agent: typeof AGENT_SALE_AGENT;
      sale_agent_factor_id?: never;

      sale_agent_factor_is_new: true;
      sale_agent_factor_name: string;
      sale_agent_factor_number: string | number;
      sale_agent_factor_images: Array<ADHttpRequestFile>;
      sale_agent_factor_area: string | number;
      sale_agent_factor_date: string;
    }
);
type TechnicalScreenProps = Params &
  InjectedApiServiceProps &
  InjectedNavigationServiceProps;

type State = {};

const AGENT_SALE_AGENT = 1;
const AGENT_EMPLOYER = 2;
const AGENT_DIRECT = 4;

@inject("$navigation", "$api")
@observer
export default class TechnicalScreen extends React.Component<
  TechnicalScreenProps,
  State
> {
  static RouteName = "TechnicalScreen";

  static start(nav: INavigationService, param: Params) {
    nav.navigate(TechnicalScreen.RouteName, param);
  }

  protected readonly formInfoApi = DPConfig.http.createResource<
    { project_id },
    TechnicalFormInfoModel
  >((p) => {
    return this.props.$api
      .getTechnicalFormInfo({ ...p, project_id: this.props.projectId })
      .onSuccess((response) => {
        if (response.data) {
          this.apiProfile.load({});

          this.form.setStaticData("wall_type", response.data.wall_type_items);
          this.form.setStaticData("wall_area", response.data.roof_area_items);

          this.form.setStaticData("roof_type", response.data.roof_type_items);
          this.form.setStaticData("roof_area", response.data.wall_area_items);

          this.form.setStaticData(
            "sale_agent_factor_name",
            response.data.sale_agent_factor_name_items
          );
          if (DPConfig.app.debug && !response.data.sale_agent_factor_name_items)
            this.form.setStaticData(
              "sale_agent_factor_name",
              DPFakeUtil.randomPickerItems("عامل", 50)
            );

          this.form.setStaticData(
            "sale_agent_factor_id",
            response.data.sale_agent_factor_old_items
          );
          if (!response.data.sale_agent_factor_old_items) {
            this.form.setData("sale_agent_factor_is_new", true);
          }
          if (DPConfig.app.debug && !response.data.sale_agent_factor_old_items)
            this.form.setStaticData(
              "sale_agent_factor_id",
              DPFakeUtil.randomPickerItems("فاکتور ", 50)
            );
        }
      });
  });
  protected apiProfile = DPConfig.http.createResource<{ id }, ProfileModel>(
    (p) => {
      return this.props.$api.getProfileInfo({ ...p }).onSuccess((response) => {
        this.forceUpdate();
      });
    }
  );

  protected readonly form = new ADFormStore<TechnicalInfoSubmitModel>();
  protected readonly tempProjectImageInputValidationForm = new ADFormStore();
  protected readonly tempProjectFactorInputValidationForm = new ADFormStore();

  state = {
    roofInputCount: 1,
    wallInputCount: 1,
  };

  render() {
    let trans = i18n.strings.screens.technical;
    this.form.data.get("roof_area");
    let hasOldFactors =
      (this.form.staticData.get("sale_agent_factor_id") || []).length > 0;
    let isNewFactorTabActive = !!this.form.data.get("sale_agent_factor_is_new");
    return (
      <ADScreen
        style={{ backgroundColor: "#fff" }}
        backConfirmMessage={trans.close_confirm}
        onBackConfirm={this.props.$navigation.back}
      >
        <AppToolbar theme={"light"} title={trans.title} />
        <ADRemoteView api={this.formInfoApi}>
          <ADForm store={this.form}>
            <DPScrollView
              api={this.formInfoApi}
              extraScrollHeight={ADResponsiveUtil.size(
                DPConfig.dimens.screen.height / 4,
                50,
                150
              )}
            >
              {/*<ADTextInput
                                style={styles.row}
                                label={'شماره تلفن مشتری'}
                                $id={'tel'}
                                keyboardType={'numeric'}
                                $validators={[new ADRequiredValidator()]}
                                $nextFocusId={'address'}
                            />
                            <ADTextInput
                                style={styles.row}
                                label={'آدرس مشتری'}
                                $validators={[new ADRequiredValidator()]}
                                $id={'address'}
                            />*/}
              <FormSection
                style={{ marginTop: DPConfig.dimens.screenVerticalPadding }}
                label={trans.wall_info}
                color={DPConfig.colors.secondaryColor}
                onPress={() => this.showHelp(this.formInfoApi.data.wall_help)}
              >
                <SelectInputItem
                  color={DPConfig.colors.secondaryColor}
                  $form={this.form}
                  inputId={"wall_area"}
                  selectId={"wall_type"}
                  selectLabel={trans.wall_type}
                  inputPlaceholder={trans.inputs.wall_area}
                />
              </FormSection>
              <FormSection
                style={{ marginTop: 16 }}
                label={trans.roof_info}
                color={DPConfig.colors.primaryColor}
                onPress={() => this.showHelp(this.formInfoApi.data.roof_help)}
              >
                <SelectInputItem
                  color={DPConfig.colors.primaryColor}
                  $form={this.form}
                  inputId={"roof_area"}
                  selectId={"roof_type"}
                  selectLabel={trans.roof_type}
                  inputPlaceholder={trans.inputs.roof_area}
                />
              </FormSection>
              <Col style={[styles.row, styles.question]}>
                <ADText title={trans.on_time_deadline.question} />
                <Row>
                  <DPCheckBoxInput
                    $id={"on_time_deadline"}
                    // $validators={[new ADRequiredValidator('لطفا یک گزینه را انتخاب کنید')]}
                    $groupValue={1}
                    iconOn={{
                      icon: "circle-medium",
                      type: ADIcon.Types.MaterialCommunityIcons,
                    }}
                    iconOff={{ none: true }}
                    labelStyle={{ marginStart: 0 }}
                    style={{ marginEnd: 16 }}
                    label={trans.on_time_deadline.yes}
                  />
                  <DPCheckBoxInput
                    $id={"on_time_deadline"}
                    iconOn={{
                      icon: "circle-medium",
                      type: ADIcon.Types.MaterialCommunityIcons,
                    }}
                    iconOff={{ none: true }}
                    labelStyle={{ marginStart: 0 }}
                    $groupValue={0}
                    label={trans.on_time_deadline.no}
                  />
                </Row>
                <ADTextInput
                  $id={"on_time_deadline_description"}
                  label={trans.on_time_deadline.description}
                  clearEnabled
                />
              </Col>
              <Col style={[styles.row, styles.question]}>
                <ADText title={trans.operator.question} />
                <DPCheckBoxInput
                  iconOn={{
                    icon: "circle-medium",
                    type: ADIcon.Types.MaterialCommunityIcons,
                  }}
                  iconOff={{ none: true }}
                  // labelStyleOn={{
                  //     color: DPConfig.colors.accentColor
                  // }}
                  $id={"agent"}
                  $groupValue={AGENT_EMPLOYER}
                  label={trans.operator.employer}
                />
                {!!this.apiProfile.data.direct && (
                  <DPCheckBoxInput
                    iconOn={{
                      icon: "circle-medium",
                      type: ADIcon.Types.MaterialCommunityIcons,
                    }}
                    iconOff={{ none: true }}
                    // labelStyleOn={{
                    //     color: DPConfig.colors.accentColor
                    // }}
                    // $validators={[new ADRequiredValidator('لطفا یک گزینه را انتخاب کنید')]}
                    $id={"agent"}
                    $groupValue={AGENT_DIRECT}
                    label={trans.operator.direct}
                  />
                )}
                <DPCheckBoxInput
                  iconOn={{
                    icon: "circle-medium",
                    type: ADIcon.Types.MaterialCommunityIcons,
                  }}
                  iconOff={{ none: true }}
                  // labelStyleOn={{
                  //     color: DPConfig.colors.accentColor
                  // }}
                  // $validators={[new ADRequiredValidator('لطفا یک گزینه را انتخاب کنید')]}
                  $id={"agent"}
                  $groupValue={AGENT_SALE_AGENT}
                  label={trans.operator.sale_agent}
                />
                {/*<DPCheckBoxInput*/}
                {/*iconOn={{icon: 'circle-medium', type: ADIcon.Types.MaterialCommunityIcons}}*/}
                {/*iconOff={{none: true}}*/}
                {/*// labelStyleOn={{*/}
                {/*//     color: DPConfig.colors.accentColor*/}
                {/*// }}*/}
                {/*$id={'agent'}*/}
                {/*$groupValue={3}*/}
                {/*label={trans.operator.client}/>*/}

                {this.form.data.get("agent") === AGENT_SALE_AGENT && (
                  <View
                    style={{ width: "100%", marginTop: 2 }}
                    animation={"fadeIn"}
                  >
                    {hasOldFactors && (
                      <Row>
                        <DPCheckBoxInput
                          iconOn={{
                            icon: "circle-medium",
                            type: ADIcon.Types.MaterialCommunityIcons,
                          }}
                          iconOff={{
                            icon: "circle-medium",
                            type: ADIcon.Types.MaterialCommunityIcons,
                          }}
                          labelStyleOn={{
                            color: DPConfig.colors.accentColor,
                          }}
                          style={{
                            backgroundColor: isNewFactorTabActive
                              ? "#fff"
                              : "rgba(255,255,255,.3)",
                            borderWidth: 1,
                            borderColor: isNewFactorTabActive
                              ? DPConfig.colors.accentColor
                              : "transparent",
                            borderEndColor: DPConfig.colors.accentColor,
                            borderBottomColor: !isNewFactorTabActive
                              ? DPConfig.colors.accentColor
                              : "transparent",
                          }}
                          $id={"sale_agent_factor_is_new"}
                          $groupValue={true}
                          $initValue={true}
                          label={trans.operator.sale_agent_factor.create.title}
                        />
                        <DPCheckBoxInput
                          iconOn={{
                            icon: "circle-medium",
                            type: ADIcon.Types.MaterialCommunityIcons,
                          }}
                          iconOff={{
                            icon: "circle-medium",
                            type: ADIcon.Types.MaterialCommunityIcons,
                          }}
                          labelStyleOn={{
                            color: DPConfig.colors.accentColor,
                          }}
                          style={{
                            flex: 1,
                            borderWidth: 1,
                            borderStartWidth: 0,
                            backgroundColor: !isNewFactorTabActive
                              ? "#fff"
                              : "rgba(255,255,255,.3)",
                            borderColor: !isNewFactorTabActive
                              ? DPConfig.colors.accentColor
                              : "transparent",
                            borderBottomColor: isNewFactorTabActive
                              ? DPConfig.colors.accentColor
                              : "transparent",
                          }}
                          $id={"sale_agent_factor_is_new"}
                          $groupValue={false}
                          label={trans.operator.sale_agent_factor.select.title}
                        />
                      </Row>
                    )}
                    {!isNewFactorTabActive && (
                      <Col
                        style={{
                          backgroundColor: "#fff",
                          borderColor: DPConfig.colors.accentColor,
                          borderWidth: 1,
                          borderTopWidth: 0,
                          paddingBottom: 8,
                        }}
                      >
                        <ADSelectInput
                          label={
                            trans.operator.sale_agent_factor.select.factor.title
                          }
                          $id={"sale_agent_factor_id"}
                          style={styles.sale_agent_sub_item}
                          clearEnabled
                        />
                      </Col>
                    )}
                    {isNewFactorTabActive && (
                      <Col
                        style={{
                          backgroundColor: "#fff",
                          borderColor: DPConfig.colors.accentColor,
                          borderWidth: 1,
                          borderTopWidth: 0,
                        }}
                      >
                        <ADSelectInput
                          label={
                            trans.operator.sale_agent_factor.create.name.title
                          }
                          $id={"sale_agent_factor_name"}
                          style={styles.sale_agent_sub_item}
                          clearEnabled
                        />
                        <ADDateInput
                          label={
                            trans.operator.sale_agent_factor.create.date.title
                          }
                          $id={"sale_agent_factor_date"}
                          style={styles.sale_agent_sub_item}
                          clearEnabled
                        />
                        <ADTextInput
                          label={
                            trans.operator.sale_agent_factor.create.number.title
                          }
                          keyboardType={"default"}
                          autoCapitalize={"none"}
                          autoCorrect={false}
                          $id={"sale_agent_factor_number"}
                          style={styles.sale_agent_sub_item}
                          clearEnabled
                        />
                        <ADTextInput
                          label={
                            trans.operator.sale_agent_factor.create.area.title
                          }
                          keyboardType={"numeric"}
                          $id={"sale_agent_factor_area"}
                          clearEnabled
                          style={styles.sale_agent_sub_item}
                        />
                        {/*-------------------Agency-------------------*/}
                        <DPUploadFiles
                          label={
                            trans.operator.sale_agent_factor.create.images.title
                          }
                          $id={"sale_agent_factor_images"}
                          addTitle={
                            trans.operator.sale_agent_factor.create.images.add
                          }
                          mini
                          options={{
                            title:
                              trans.operator.sale_agent_factor.create.images
                                .picker.title,
                            gallery:
                              trans.operator.sale_agent_factor.create.images
                                .picker.gallery,
                            camera:
                              trans.operator.sale_agent_factor.create.images
                                .picker.camera,
                            multiple: true,
                            options: {
                              // compressImageQuality: 0.4,
                              compressImageMaxWidth: 720,
                              compressImageMaxHeight: 720,
                              includeExif: true,
                            },
                          }}
                        />
                      </Col>
                    )}
                  </View>
                )}
              </Col>
              <Col style={[styles.row, styles.question]}>
                <ADText title={trans.client.question} />
                <Row>
                  <DPCheckBoxInput
                    $id={"client_satisfied"}
                    $groupValue={true}
                    iconOn={{
                      icon: "circle-medium",
                      type: ADIcon.Types.MaterialCommunityIcons,
                    }}
                    iconOff={{ none: true }}
                    labelStyle={{ marginStart: 0 }}
                    style={{ marginEnd: 16 }}
                    label={trans.client.yes}
                  />
                  <DPCheckBoxInput
                    $id={"client_satisfied"}
                    iconOn={{
                      icon: "circle-medium",
                      type: ADIcon.Types.MaterialCommunityIcons,
                    }}
                    iconOff={{ none: true }}
                    labelStyle={{ marginStart: 0 }}
                    $groupValue={false}
                    label={trans.client.no}
                  />
                </Row>
                <ADTextInput
                  $id={"client_satisfied_description"}
                  label={trans.client.description}
                  clearEnabled
                />
              </Col>
              <DPUploadFiles
                label={trans.images.title}
                style={styles.uploads_images}
                columnCount={4}
                containerStyle={{}}
                options={{
                  title: trans.images.picker.title,
                  gallery: trans.images.picker.gallery,
                  camera: trans.images.picker.camera,
                  multiple: true,
                  options: {
                    // compressImageQuality: 0.4,
                    compressImageMaxWidth: 720,
                    compressImageMaxHeight: 720,
                    includeExif: true,
                  },
                }}
                labelStyle={{
                  color: "#fff",
                  marginBottom: 8,
                }}
                addButtonProps={{
                  color: "#fff",
                  textColor: "#fff",
                  filled: true,
                  outlined: false,
                }}
                addTitle={trans.images.add}
                $id={"images"}
                itemProps={({ file, index }) => {
                  let captionId = "caption-" + file.uri;

                  return {
                    style: {
                      backgroundColor: "#fff",
                      borderRadius: 4,
                      padding: 16,
                    },
                    onRemovePress: () => {
                      this.tempProjectImageInputValidationForm.data.delete(
                        captionId
                      );
                      this.tempProjectImageInputValidationForm.validators.delete(
                        captionId
                      );
                    },
                    children: (
                      <ADTextInput
                        numberOfLines={1}
                        onChangeText={(text) => {
                          // change file name but keep file extension
                          let parts = file.name.split(".");
                          let ext = parts.pop();
                          file.name = encodeURIComponent(text + "." + ext);
                        }}
                        $id={captionId}
                        $form={this.tempProjectImageInputValidationForm}
                        $validators={[new ADRequiredValidator()]}
                        style={{
                          flex: 1,
                          marginHorizontal: 8,
                          borderRadius: 2,
                        }}
                        fontSize={14}
                        label={trans.images.input_label}
                      />
                    ),
                  };
                }}
                before={
                  <ADImage
                    style={{
                      position: "absolute",
                      end: DPConfig.dimens.screen.horizontalPadding,
                      bottom: 0,
                      width: 64,
                      height: 64,
                      opacity: 0.3,
                    }}
                    resizeMode={"contain"}
                    source={Assets.images.ic_upload}
                  />
                }
              />
              <DPUploadFiles
                label={trans.factors.title}
                style={styles.uploads_factors}
                columnCount={4}
                containerStyle={{}}
                options={{
                  title: trans.factors.picker.title,
                  gallery: trans.factors.picker.gallery,
                  camera: trans.factors.picker.camera,
                  multiple: true,
                  options: {
                    // compressImageQuality: 0.4,
                    compressImageMaxWidth: 720,
                    compressImageMaxHeight: 720,
                    includeExif: true,
                  },
                }}
                labelStyle={{
                  color: "#fff",
                  marginBottom: 8,
                }}
                addButtonProps={{
                  color: "#fff",
                  textColor: "#fff",
                  filled: true,
                  outlined: false,
                }}
                addTitle={trans.factors.add}
                $id={"factors"}
                itemProps={({ file, index }) => {
                  // use just for validation as we save file info in another form
                  let captionId = "caption-" + file.uri;

                  return {
                    style: {
                      backgroundColor: "#fff",
                      borderRadius: 4,
                      padding: 16,
                    },
                    onRemovePress: () => {
                      this.tempProjectFactorInputValidationForm.data.delete(
                        captionId
                      );
                      this.tempProjectFactorInputValidationForm.validators.delete(
                        captionId
                      );
                    },
                    children: (
                      <ADTextInput
                        numberOfLines={1}
                        onChangeText={(text) => {
                          // change file name but keep file extension
                          let parts = file.name.split(".");
                          let ext = parts.pop();
                          file.name = encodeURIComponent(text + "." + ext);
                        }}
                        $id={captionId}
                        $form={this.tempProjectFactorInputValidationForm}
                        $validators={[new ADRequiredValidator()]}
                        style={{
                          flex: 1,
                          marginHorizontal: 8,
                          borderRadius: 2,
                        }}
                        fontSize={14}
                        label={trans.factors.input_label}
                      />
                    ),
                  };
                }}
                before={
                  <ADImage
                    style={{
                      position: "absolute",
                      end: DPConfig.dimens.screen.horizontalPadding,
                      bottom: 0,
                      width: 64,
                      height: 64,
                      opacity: 0.3,
                    }}
                    resizeMode={"contain"}
                    source={Assets.images.ic_upload}
                  />
                }
              />
              <Row style={[styles.row, { marginVertical: 16 }]}>
                <ADButton
                  title={trans.save}
                  filled
                  block
                  accent
                  onSubmit={this.handleSubmit}
                />
              </Row>
            </DPScrollView>
          </ADForm>
        </ADRemoteView>
      </ADScreen>
    );
  }

  @autobind
  private handleSubmit(data) {
    let trans = i18n.strings.screens.technical;

    let wallValidators = [
      {
        validator: new ADRequiredValidator(trans.inputs.wall_type_required),
        data: (this.form.data.get("wall_type") || []).filter(Boolean),
      },
      {
        validator: new ADRequiredValidator(trans.inputs.wall_area_required),
        data: (this.form.data.get("wall_area") || []).filter(Boolean),
      },
    ];
    let roofValidators = [
      {
        validator: new ADRequiredValidator(trans.inputs.roof_type_required),
        data: (this.form.data.get("roof_type") || []).filter(Boolean),
      },
      {
        validator: new ADRequiredValidator(trans.inputs.roof_area_required),
        data: (this.form.data.get("roof_area") || []).filter(Boolean),
      },
    ];

    let firstFailed;

    let wallFailed = wallValidators.find((v) => {
      return !v.validator.check(v.data);
    });
    // we need at least one wall or roof
    if (wallFailed) {
      firstFailed = roofValidators.find((v) => {
        return !v.validator.check(v.data);
      });
    }

    let validators1 = [
      {
        validator: new ADRequiredValidator(trans.on_time_deadline.required),
        data: this.form.data.get("on_time_deadline") != undefined,
      },
      {
        validator: new ADRequiredValidator(trans.operator.required),
        data: this.form.data.get("agent"),
      },
    ];

    if (!firstFailed) {
      firstFailed = validators1.find((v) => {
        return !v.validator.check(v.data);
      });
    }
    //--------------------------------------

    // if (!firstFailed && data["agent"] === AGENT_SALE_AGENT) {
    //   if (data["sale_agent_factor_is_new"]) {
    //     let saleAgentValidators = [
    //       {
    //         validator: new ADRequiredValidator(
    //           trans.operator.sale_agent_factor.create.name.required
    //         ),
    //         data: this.form.data.get("sale_agent_factor_name"),
    //       },
    //       {
    //         validator: new ADRequiredValidator(
    //           trans.operator.sale_agent_factor.create.number.required
    //         ),
    //         data: this.form.data.get("sale_agent_factor_number"),
    //       },
    //       {
    //         validator: new ADRequiredValidator(
    //           trans.operator.sale_agent_factor.create.date.required
    //         ),
    //         data: this.form.data.get("sale_agent_factor_date"),
    //       },
    //       {
    //         validator: new ADRequiredValidator(
    //           trans.operator.sale_agent_factor.create.area.required
    //         ),
    //         data: this.form.data.get("sale_agent_factor_area"),
    //       },
    //       {
    //         validator: new ADRequiredValidator(
    //           trans.operator.sale_agent_factor.create.images.required
    //         ),
    //         data: this.form.data.get("sale_agent_factor_images"),
    //       },
    //     ];
    //     firstFailed = saleAgentValidators.find((v) => {
    //       return !v.validator.check(v.data);
    //     });
    //   } else {
    //     let saleAgentValidators = [
    //       {
    //         validator: new ADRequiredValidator(
    //           trans.operator.sale_agent_factor.select.factor.required
    //         ),
    //         data: this.form.data.get("sale_agent_factor_id"),
    //       },
    //     ];
    //     firstFailed = saleAgentValidators.find((v) => {
    //       return !v.validator.check(v.data);
    //     });
    //   }
    // }

    //--------------------------------------

    let validators2 = [
      {
        validator: new ADRequiredValidator(trans.client.required),
        data: this.form.data.get("client_satisfied") != undefined,
      },

      {
        validator: new ADRequiredValidator(trans.images.required),
        data: this.form.data.get("images"),
      },
      {
        validator: new ADRequiredValidator(trans.factors.required),
        data: this.form.data.get("factors"),
      },
    ];

    if (!firstFailed) {
      firstFailed = validators2.find((v) => {
        return !v.validator.check(v.data);
      });
    }

    if (firstFailed) {
      new AlertDialog.Builder()
        .setTitle(trans.validation_failed.title)
        .setMessage(firstFailed.validator.message)
        .setPositiveButton(trans.validation_failed.ok)
        .create()
        .show();

      return;
    }

    if (!this.tempProjectImageInputValidationForm.validate()) {
      return;
    }

    if (!this.tempProjectFactorInputValidationForm.validate()) {
      return;
    }

    // remove unused data before send
    if (data["agent"] !== AGENT_SALE_AGENT) {
      delete (data as TechnicalInfoSubmitModel)["sale_agent_factor_is_new"];
      delete (data as TechnicalInfoSubmitModel)["sale_agent_factor_name"];
      delete (data as TechnicalInfoSubmitModel)["sale_agent_factor_date"];
      delete (data as TechnicalInfoSubmitModel)["sale_agent_factor_number"];
      delete (data as TechnicalInfoSubmitModel)["sale_agent_factor_area"];
      delete (data as TechnicalInfoSubmitModel)["sale_agent_factor_images"];
    } else {
      if (data["sale_agent_factor_is_new"]) {
        delete (data as TechnicalInfoSubmitModel)["sale_agent_factor_id"];
      } else {
        delete (data as TechnicalInfoSubmitModel)["sale_agent_factor_name"];
        delete (data as TechnicalInfoSubmitModel)["sale_agent_factor_date"];
        delete (data as TechnicalInfoSubmitModel)["sale_agent_factor_number"];
        delete (data as TechnicalInfoSubmitModel)["sale_agent_factor_images"];
      }
    }

    let saveConfirm = trans.save_confirm;
    let saveSuccess = trans.save_success;

    let form = new ADFormStore<{ progress: number }>();
    let loading = new AlertDialog.Builder()
      .setLoadingEnabled(true)
      .setCancelable(false)
      .setContent(
        <Observer>
          {() => (
            <DPProgressBar
              value={form.data.get("progress") as number}
              style={{ backgroundColor: "#d1d1d1" }}
            />
          )}
        </Observer>
      )
      .small()
      .create();

    new AlertDialog.Builder()
      .setTitle(saveConfirm.title)
      .setMessage(saveConfirm.message)
      .setNegativeButton(saveConfirm.cancel)
      .setPositiveButton(saveConfirm.confirm, () => {
        this.props.$api
          .sendTechnicalFormInfo(
            {
              ...data,
              project_id: this.props.projectId,
            },
            (p) => {
              form.setData("progress", p.percent);
            }
          )
          .defaultError()
          .onLoading((enabled) => {
            if (enabled) loading.show();
            else loading.hide();
          })
          .onSuccess((response, call) => {
            new AlertDialog.Builder()
              .setTitle(saveSuccess.title)
              .setMessage(response.message || saveSuccess.message)
              .setPositiveButton(saveSuccess.ok)
              .setOnVisibilityChanged((isVisible) => {
                if (!isVisible) {
                  this.props.$navigation.back();
                  this.props.onSuccess && this.props.onSuccess();
                }
              })
              .setCancelable(false)
              .create()
              .show();
          })
          .enqueue();
      })
      .create()
      .show();
  }

  @autobind
  private showHelp(help: string) {
    new AlertDialog.Builder()
      .setTitle(i18n.strings.screens.technical.help.title)
      .setMessage(help)
      .setPositiveButton(i18n.strings.screens.technical.help.ok)
      .create()
      .show();
  }
}

const styles = DPStyleSheet.create(({ dimens, colors }) => ({
  row: {
    marginHorizontal: dimens.screenHorizontalPadding,
  },
  sale_agent_sub_item: {
    marginHorizontal: dimens.screenHorizontalPadding,
  },
  question: {
    backgroundColor: "rgba(0,173,239,.05)",
    padding: 8,
    borderColor: colors.accentColor,
    borderWidth: 1,
    borderRadius: 2,
    marginTop: 16,
  },
  uploads_images: {
    backgroundColor: colors.primaryColor,
    padding: 8,
    borderRadius: 2,
    marginTop: 16,
  },
  uploads_factors: {
    backgroundColor: colors.secondaryColor,
    padding: 8,
    borderRadius: 2,
    marginTop: 16,
  },
}));
