import * as React from 'react';
import {observer} from 'mobx-react';
import {
    ADButton,
    ADIcon,
    ADImage,
    ADResponsiveUtil,
    ADText,
    ADTouchable,
    Col,
    DPConfig,
    DPSelectUtil,
    DPStyleSheet,
    Row,
} from '@dp/react-native-core';
import {PortfolioItemModel} from 'app/api/Models'
import autobind from 'autobind-decorator';
import {StyleProp, View, ViewStyle} from 'react-native';

export type PortfolioListItemProps =
    {
        item: PortfolioItemModel
        onPress: (item: PortfolioItemModel) => void
        style?: StyleProp<ViewStyle>
    }

type State = {}

@observer
export default class PortfolioListItem extends React.Component<PortfolioListItemProps, State> {
    state = {};

    @autobind
    handlePress() {
        this.props.onPress &&
        this.props.onPress(this.props.item)
    }

    render() {
        let {item, style, onPress} = this.props;

        let stateColor = item.state_color || DPSelectUtil.select(item.state, {
            'pending': '#9e9e9e',
            'approved': DPConfig.colors.primaryColor,
            'rejected': DPConfig.colors.errorColor,
        });

        return (
            <ADTouchable onPress={this.handlePress}>
                <Col style={[styles.container, style]}>
                    <Row>
                        <ADImage
                            source={item.image}
                            resizeMode={'cover'}
                            style={{
                                width: '100%',
                                height: ADResponsiveUtil.dimen(72, 92, 120, 150)
                            }}
                        />
                    </Row>
                    <Row style={{paddingHorizontal: 8, paddingVertical: 4, alignItems: 'center'}}>
                        <ADIcon
                            size={16}
                            icon={'create'}
                            style={{marginEnd: 8}}
                        />
                        <ADText
                            title={item.title}
                            textAlign={'center'}
                            numberOfLines={1}
                            style={DPConfig.styles.growWidth}
                            lineBreakMode={'tail'}
                            fontSize={12}/>
                    </Row>
                    <Col style={styles.ribbonWrapper}>
                        <Row style={[styles.ribbon, {backgroundColor: stateColor}]}>

                        </Row>
                    </Col>
                </Col>
            </ADTouchable>
        )
    }

}

const styles = DPStyleSheet.create(({styles, dimens, colors, fonts}) => ({
    container: {
        ...styles.card,
        alignItems: 'stretch',
        flex: 1,
        marginStart: 16
    },
    ribbonWrapper: {
        position: 'absolute',
        top: -2,
        start: -2,
        overflow: 'hidden',
        width: 28,
        height: 28,
        borderRadius: 2,
    } as ViewStyle,
    ribbon: {
        ...styles.boxShadowBottom,
        backgroundColor: colors.primaryColor,
        transform: [
            {rotateZ: `45deg`},
            {translateY: -10},
            {translateX: 10}
        ],
        overflow: 'hidden',
        height: 8,
        width: 64,
    } as ViewStyle,

}));
