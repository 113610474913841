import ListApiResponseModel from './ListApiResponseModel';
import autobind from 'autobind-decorator';
import {ADCall, ADListResource, ListResourceParsedResponse} from '@dp/react-native-core';

@autobind
export default class DPListResource<RequestType, ItemType> extends ADListResource<RequestType, ListApiResponseModel<ItemType>, ItemType> {

    constructor(callMaker: (p: (RequestType & { page: number })) => ADCall<ListApiResponseModel<ItemType>>) {
        super(callMaker);
    }

    protected parseResponse(response: ListApiResponseModel<ItemType>): ListResourceParsedResponse<ItemType> {

        if (response && Array.isArray(response.data)) {
            return {
                items: response.data as Array<ItemType>,
                message: response.message,
                page: response['current_page'] || 1,
                isFinished: !response['next_page_url'],
            };
        }

        let items: Array<ItemType> = [];
        let isFinished = true;
        let page = 1;
        // @ts-ignore
        if (response && response.data && response.data.data)
        // @ts-ignore
            items = response.data.data;
        // @ts-ignore
        if (response && response.data && response.data.next_page_url)
            isFinished = false;
        // @ts-ignore
        if (response && response.data && response.data.current_page)
        // @ts-ignore
            page = response.data.current_page;

        return {
            items: items,
            message: response.message,
            page: page,
            isFinished: isFinished
        };
    }

    clone() {
        return new DPListResource(super._callMaker)
    }

}