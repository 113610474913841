import * as React from 'react';
import {ADText, ADTouchable, Col, DPColorUtil, DPStyleSheet, Row} from '@dp/react-native-core';
import {StyleProp, View, ViewStyle} from 'react-native';

export interface SectionHeaderProps {
    label: string
    onPress: () => void
    color: string
    style?: StyleProp<ViewStyle>
    children?
}

export default function FormSection(props: SectionHeaderProps) {
    return (
        <Col style={[styles.container, {borderStartColor: props.color}, props.style]}>
            <Row>
                <ADText
                    fontSize={14}
                    style={styles.label}
                    title={props.label}
                />
                <View style={{borderRadius: 10, overflow: 'hidden'}}>
                    <ADTouchable onPress={props.onPress}>
                        <View style={[styles.helpBox, {
                            backgroundColor: DPColorUtil.fade(props.color, .8),
                            borderColor: props.color,
                        }]}>
                            <ADText
                                fontSize={12}
                                color={props.color}
                                title={'راهنما'}
                            />
                        </View>
                    </ADTouchable>
                </View>
            </Row>
            {props.children}
        </Col>
    )
}

const styles = DPStyleSheet.create(({styles, dimens, colors}) => ({
    container: {
        borderStartWidth: 4,
        paddingHorizontal: dimens.screenHorizontalPadding,
        paddingVertical: 8,
        backgroundColor: colors.screenBackgroundColorDark
    },
    label: {
        ...styles.growWidth,
        flex: 1
    },
    helpBox: {
        borderWidth: 1,
        borderRadius: 10,
        height: 24,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 8,
    }
}));