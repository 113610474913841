import {DPI18n} from '@dp/react-native-core';
import Fa from './locales/fa';

export const i18n = DPI18n.extends({
    fa: {
        translations: Fa,
        isRTL: true,
    },
    en: {
        translations: Fa,
        isRTL: true,
    }
});