import autobind from 'autobind-decorator';
import {action} from 'mobx'
import VerificationScreen from '../verification/VerificationScreen';
import {ADFormStore, AndroidSmsReceiverUtil, InjectedAuthServiceProps, InjectedNavigationServiceProps} from '@dp/react-native-core';
import InjectedApiServiceProps from 'app/api/InjectedApiServiceProps';
import RNOtpVerify from 'react-native-otp-verify';

type Services = InjectedNavigationServiceProps & InjectedAuthServiceProps & InjectedApiServiceProps

@autobind
export default class LoginScreenStore {
    private $: Services;
    public readonly form = new ADFormStore<{ mobile: string }>()

    constructor(s: Services) {
        this.$ = s;
    }


    @action
    checkMobileValidation() {
        return this.form.validate();
    }

    @action
    submit() {
        let mobile = this.form.data.get('mobile') || '';
        if (!this.checkMobileValidation())
            return;

        AndroidSmsReceiverUtil.preferOtp = false;
        AndroidSmsReceiverUtil.requestRequiredPermission()
            .then(_ => {
                this.requestSmsToken(mobile)
            })

    }


    protected requestSmsToken(mobile: string) {

        this.$.$api.requestSmsToken({mobile: mobile, otp_support: AndroidSmsReceiverUtil.isOtpSupported()})
            .defaultError(true)
            .defaultLoading(true)
            .onSuccess((r) => {
                VerificationScreen.start(this.$.$navigation, mobile)
            })
            .enqueue()
    }

}