import * as React from 'react';
import {inject, observer} from 'mobx-react';
import autobind from 'autobind-decorator';
import NewsListItem from './NewsListItem';
import NewsDetailsScreen from '../news-details/NewsDetailsScreen';
import InjectedApiServiceProps from 'app/api/InjectedApiServiceProps';
import {
    ADFlatList,
    ADResponsiveUtil,
    ADScreen, DPConfig,
    DPGallery,
    DPStyleSheet,
    INavigationService,
    InjectedNavigationServiceProps,
    Row
} from '@dp/react-native-core';
import {AppToolbar} from 'app/screens/common/AppToolbar';
import {NewsItemModel} from 'app/api/Models';
import {Platform} from 'react-native'
import {i18n} from '../../../../i18n/i18n';

@inject('$api', '$navigation')
@observer
export default class NewsListScreen extends React.Component<InjectedApiServiceProps & InjectedNavigationServiceProps> {
    static RouteName = 'NewsListScreen'

    static start(nav: INavigationService) {
        nav.navigate(NewsListScreen.RouteName)
    }

    get galleryItems(): Array<NewsItemModel & { image, title }> {
        if (Platform.OS === 'web') return [];
        return this.props.$api.newsListApi.items
            .slice(0, 50)
            .filter(_ => _.featured && _.main_image_url)
            .map(_ => ({..._, image: _.main_image_url, title: _.title}))
    }

    get newsItems(): Array<NewsItemModel> {
        if (Platform.OS === 'web')
            return this.props.$api.newsListApi.items
        return this.props.$api.newsListApi.items
            .filter(_ => !_.featured || (_.featured && !_.main_image_url))
    }

    render() {
        return (
            <ADScreen>
                <AppToolbar
                    theme={'light'}
                    title={i18n.strings.screens.news_list.title}/>
                <ADFlatList
                    ListHeaderComponent={
                        this.galleryItems.length > 0 ?
                            <Row style={{marginBottom: 16}}>
                                <DPGallery
                                    style={styles.gallery}
                                    initWidth={DPConfig.dimens.screen.width}
                                    initHeight={
                                        this.newsItems.length === 0
                                            ? Math.min(
                                            DPConfig.dimens.screen.width,
                                            (
                                                DPConfig.dimens.screen.height * .9
                                                - DPConfig.dimens.toolbarHeight
                                                - DPConfig.dimens.screenVerticalPadding * 2
                                            )
                                            )
                                            : DPConfig.dimens.screen.height / 3
                                    }
                                    onItemPress={({item}) => this.handleItemPress(item)}
                                    items={
                                        this.galleryItems
                                    }
                                />
                            </Row>
                            :
                            undefined
                    }
                    resource={this.props.$api.newsListApi}
                    data={this.newsItems}
                    renderItem={this.renderItem}
                    // force rerender item if state changed
                    keyExtractor={(item, index) => (
                        item.id + String(item.read) + String(item.liked) + String(item.likes_count) + String(item.view_count)
                    )}
                    autoLoad={'no-data'}
                    ItemSeparatorComponent={null}
                />
            </ADScreen>
        )
    }

    @autobind
    protected renderItem(p: { item: NewsItemModel, index: number }) {
        return <NewsListItem item={p.item} onPress={this.handleItemPress}/>
    }

    @autobind
    protected handleItemPress(item: NewsItemModel) {
        if (item.id)
            NewsDetailsScreen.start(this.props.$navigation, {
                newsId: item.id,
                onLoaded: () => {
                    item.read = true;
                    // rerender
                    this.forceUpdate()
                }
            })
    }
}

const styles = DPStyleSheet.create(({dimens}) => ({
    itemTitle: {
        textAlign: 'center',
        padding: 4,
        width: 0,
        flexGrow: 1,
        fontSize: ADResponsiveUtil.plus(10, 2)
    },
    gallery: {
        backgroundColor: '#fff',
        paddingVertical: dimens.screenVerticalPadding,
        paddingHorizontal: dimens.screenHorizontalPadding,
        // height: ADResponsiveUtil.height / 3
    }
}));