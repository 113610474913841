import {
  ADResponsiveUtil,
  DeepPartialType,
  DPColorUtil,
  DPDefaultConfig,
} from "@dp/react-native-core";
import DPResource from "../app/api/DPResource";
import DPListResource from "../app/api/DPListResource";
import FakeApi from "./api/fake/FakeApi";
import Assets from "./assets/Assets";
import { Platform } from "react-native";
import AppCenterAnalytics from "appcenter-analytics";
import Firebase from "react-native-firebase";
// import { API_HOST as API_HOST_ENV } from "react-native-dotenv";

let API_HOST =
  // __DEV__ ?
  // "sctest.kplus.ir";
  //  :
  "service.kplus.ir";

if (Platform.OS === "web" && !__DEV__) {
  //@ts-ignore
  API_HOST = window.API_HOST;
}

console.disableYellowBox = true;

const Config: DeepPartialType<typeof DPDefaultConfig> = {
  colors: {
    accentColor: "#00aeef",
    selectionColor: DPColorUtil.fade("#00aeef", 0.5),
    primaryColor: "#00aeef",
    secondaryColor: "#83b8d0",
    secondaryTextColor: "#000",
    defaultTextColorLight: "#B5B5B5",
    defaultTextColor: "#727272",
    screenBackgroundColorDark: "#F7F8FA",
  },
  dimens: {
    screenVerticalPadding: ADResponsiveUtil.dimen(16, 16, 32, 48),
  },
  http: {
    createResource: <RequestType, ResponseType, DataType>(callMaker) => {
      return new DPResource<RequestType, DataType>(callMaker);
    },
    createListResource: <RequestType, ResponseType, ItemType>(callMaker) => {
      return new DPListResource<RequestType, ItemType>(callMaker);
    },

    fakeApi: FakeApi,
    baseUrl: Platform.select({
      default: `https://${API_HOST}/api/v1/`,
      android:
        Platform.Version < 21
          ? `http://${API_HOST}/api/v1/`
          : `https://${API_HOST}/api/v1/`,
      web: __DEV__
        ? `https://cors-anywhere.herokuapp.com/https://${API_HOST}/api/v1/`
        : `https://${API_HOST}/api/v1/`,
    }),
  },
  images: {
    logo: Assets.images.logo,
  },
  app: {
    supportedDirection: "",
  },
  components: {
    map: {
      type: Platform.OS === "ios" ? "react-native-maps" : "webview",
      provider: null,
    },
    text: {
      reverseAlign: true,
    },
    textInput: {
      reverseAlign: Platform.OS === "web",
    },
    scrollView: {
      keyboardAwareAndroidEnabled: false,
    },
  },
  // services: {
  // }
  // messaging: DPDefaultMessagingConfig,
  // navigation: DPDefaultNavigationConfig,
  // auth: DPDefaultAuthConfig,
  // dimen: DefaultDimenConfig
  utils: {
    analytics: {
      trackEvent: (name: string, props: { [key: string]: string } = {}) => {
        if (Platform.OS === "web") return;
        if (AppCenterAnalytics) {
          AppCenterAnalytics && AppCenterAnalytics.trackEvent(name, props);
        }
        try {
          if (Firebase) {
            if (
              Firebase.analytics &&
              Firebase.analytics() &&
              Firebase.analytics().logEvent
            )
              Firebase.analytics().logEvent(name, props);
          }
        } catch (e) {
          console.log("Firebase.analytics().logEvent(name, props) error");
        }
      },

      log: (message: string) => {
        if (Platform.OS === "web") return;
        if (Firebase && Firebase.crashlytics() && Firebase.crashlytics().log)
          Firebase.crashlytics().log(message);
      },
    },
  },
};

export default Config;
