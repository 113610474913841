import * as React from "react";
import { observer } from "mobx-react";
import {
  ADFlatListEmpty,
  ADIcon,
  ADImage,
  ADImageSourceType,
  ADRemoteView,
  ADResponsiveUtil,
  ADScreen,
  ADText,
  ADTouchable,
  Col,
  DPColorUtil,
  DPConfig,
  DPInject,
  DPPlaceholder,
  DPScrollView,
  DPSelectUtil,
  DPStyleSheet,
  INavigationService,
  InjectedActionServiceProps,
  InjectedAuthServiceProps,
  InjectedMessagingServiceProps,
  InjectedNavigationServiceProps,
  ResponsiveUtil,
  Row,
} from "@dp/react-native-core";
import ProfileScreen from "../profile/ProfileScreen";
import InjectedApiServiceProps from "app/api/InjectedApiServiceProps";
import {
  Animated,
  LayoutChangeEvent,
  Platform,
  ScrollView,
  StyleProp,
  View,
  ViewStyle,
} from "react-native";
import { View as AnimatedView } from "react-native-animatable";
import autobind, { boundMethod } from "autobind-decorator";
import { AppToolbar } from "../../common/AppToolbar";
import OfferRequestScreen from "../offer/offer-request/OfferRequestScreen";
import {
  DashboardModel,
  ProjectListModel,
  ProfileScoreModel,
} from "app/api/Models";
import ProjectListScreen from "../project/project-list/ProjectListScreen";
import ProjectDetailsScreen from "../project/project-details/ProjectDetailsScreen";
import EventSlider from "./EventSlider";
import Assets from "app/assets/Assets";
import RNExit from "react-native-exit-app";
import { i18n } from "../../../i18n/i18n";
import PerformanceLabel from "../profile/PerformanceLabel";

type Params = {};

type MainScreenProps = Params &
  InjectedAuthServiceProps &
  InjectedMessagingServiceProps &
  InjectedNavigationServiceProps &
  InjectedActionServiceProps &
  InjectedApiServiceProps;

type State = {
  headerHeight: number;
  headerOverlapHeight: number;
};

@DPInject("$navigation", "$api", "$auth", "$action", "$messaging", "$dialog")
@observer
export default class MainScreen extends React.Component<
  MainScreenProps,
  State
> {
  static RouteName = "MainScreen";

  static start(nav: INavigationService, param: Params = {}) {
    nav.navigate(MainScreen.RouteName, param);
  }

  static resetToDashboardNavigator(nav: INavigationService) {
    nav.resetTo("dashboard");
  }

  state: State = {
    headerOverlapHeight: 100,
    headerHeight: DPConfig.dimens.screen.height * 0.3,
  };

  protected apiProfileScore = DPConfig.http.createResource<
    {},
    ProfileScoreModel
  >((p) => {
    return this.props.$api.getProfileScore(p).onSuccess((response) => {
      this.forceUpdate();
    });
  });
  protected animatedScrollValue = new Animated.Value(0);

  componentDidMount(): void {
    this.apiProfileScore.load({});
    this.props.$action.addListener(
      "notification/opened",
      this.handleNotificationOpen
    );
    this.props.$messaging &&
      this.props.$messaging.notifyOpenFormNotificationIfExist();
  }

  @autobind
  handleNotificationOpen(action: { payload: { notification: { data? } } }) {
    if (!this.props.$auth.isLoggedIn) return;
    if (
      action.payload &&
      action.payload.notification &&
      action.payload.notification.data
    ) {
      let data = action.payload.notification.data;
      let projectId = data.project_id;
      if (projectId)
        ProjectDetailsScreen.start(this.props.$navigation, {
          projectId,
        });
    }
  }

  lastLoadTime = 0;

  constructor(p) {
    super(p);
    this.props.$api.dashboardApi.addListener((api) => {
      if (api.isSuccess) {
        this.lastLoadTime = new Date().getTime();
      }
    });
  }

  @boundMethod
  reloadIfExpired() {
    // reload only if last loaded data expired (at l30 sec)
    let now = new Date().getTime();
    if (
      now - this.lastLoadTime > 30 * 1000 &&
      !this.props.$api.dashboardApi.isLoading
    ) {
      this.props.$api.dashboardApi.reload();
    }
  }

  render() {
    let data = this.props.$api.dashboardApi.data;
    console.log("data", data);

    return (
      <ADScreen
        onFocus={this.reloadIfExpired}
        backConfirmMessage={i18n.strings.screens.dashboard.exit_back_confirm}
        // RNExit.exitApp prevent crash on next start because of undefined injected variables
        onBackConfirm={RNExit.exitApp}
      >
        <DPScrollView
          style={{ backgroundColor: "#009EEE" }}
          animatedScrollValue={this.animatedScrollValue}
          api={this.props.$api.dashboardApi}
        >
          <Col
            style={{
              flexGrow: 1,
              backgroundColor: DPConfig.colors.screenBackgroundColor,
            }}
          >
            {this.renderHeader(data)}
            <ADRemoteView
              resource={this.props.$api.dashboardApi}
              autoLoad={false}
              alwaysRenderSuccess={
                this.props.$api.dashboardApi.isSuccess ||
                this.props.$api.dashboardApi.isLoading
              }
              placeholder
            >
              <Col
                style={{
                  flex: 1,
                  marginTop: -this.state.headerOverlapHeight * 0.8,
                }}
              >
                <Col style={{ flex: 1 }}>
                  <Row>
                    <ScrollView
                      horizontal
                      contentContainerStyle={{
                        paddingHorizontal:
                          DPConfig.dimens.screenHorizontalPadding,
                      }}
                      alwaysBounceHorizontal={false}
                      showsHorizontalScrollIndicator={false}
                    >
                      {!!data.running_items &&
                        data.running_items.map((item, index) => {
                          let width =
                            DPConfig.dimens.screen.width -
                            DPConfig.dimens.screenHorizontalPadding * 2;
                          return this.renderRunningItem({
                            index: index,
                            item: item,
                            style: {
                              marginHorizontal: 0,
                              marginStart:
                                index > 0
                                  ? DPConfig.dimens.screenHorizontalPadding
                                  : 0,
                              width:
                                width -
                                (data.running_items &&
                                data.running_items.length > 1
                                  ? width * 0.3
                                  : 0),
                            },
                          });
                        })}
                    </ScrollView>
                  </Row>
                  {this.renderSuggestItem(data)}
                  {!!data.events && <EventSlider events={data.events} />}
                  {this.renderOffer(data)}
                </Col>
              </Col>
            </ADRemoteView>
          </Col>
        </DPScrollView>

        <AppToolbar
          style={{ position: "absolute", top: 0, width: "100%" }}
          theme={"dark"}
          homeEnabled={false}
          center
          title={i18n.strings.screens.dashboard.title}
          shadow={false}
          backgroundOpacity={this.animatedScrollValue.interpolate({
            inputRange: [
              0,
              DPConfig.dimens.toolbarHeight +
                DPConfig.dimens.toolbarPaddingTop +
                DPConfig.dimens.screenVerticalPadding,
              this.state.headerHeight,
            ],
            outputRange: [0, 0, 1],
          })}
        />
      </ADScreen>
    );
  }

  @autobind
  private renderUserInfo(data: DashboardModel) {
    let avatarSize = ADResponsiveUtil.dimen(60, 70, 80);
    return (
      <AnimatedView
        animation={
          this.props.$api.dashboardApi.isLoading ? "fadeOut" : "fadeIn"
        }
        delay={this.props.$api.dashboardApi.isLoading ? 300 : 1500}
        useNativeDriver
        style={styles.userInfoRow}
      >
        <Row>
          <Col>
            <AnimatedAvatar
              info={this.props.$api.dashboardApi.data}
              size={avatarSize}
              scale={this.animatedScrollValue.interpolate({
                inputRange: [Number.MIN_SAFE_INTEGER, 0, 100],
                outputRange: [1, 1, 24 / avatarSize],
              })}
              image={data.user_image}
            />
          </Col>
          <Animated.View
            style={{
              opacity: this.animatedScrollValue.interpolate({
                inputRange: [0, 100],
                outputRange: [1, 0],
              }),
            }}
          >
            <AnimatedView
              animation={"fadeIn"}
              delay={1500}
              useNativeDriver
              style={{
                justifyContent: "space-around",
                alignItems: "stretch",
                flex: 1,
              }}
            >
              <Row>
                <PerformanceLabel
                  title={this.apiProfileScore.data.performance_title}
                  height={26}
                  clickable
                />
                <ADText numberOfLines={2} bold style={styles.userInfoUserName}>
                  {/*{data.user_name}*/}
                </ADText>
              </Row>
              <View
                style={{
                  height: 6,
                  alignSelf: "stretch",
                }}
              />
              <Row>
                <Row>
                  <ADText
                    numberOfLines={1}
                    title={i18n.strings.screens.dashboard.header.exist_score}
                    style={styles.userInfoLabel}
                  />

                  <ADText
                    numberOfLines={1}
                    bold
                    style={styles.userInfoText}
                    title={data.score_exist_count}
                  />
                </Row>
                <Col>
                  <View style={styles.header_horizontal_separator} />
                </Col>
                <Row>
                  <ADText
                    numberOfLines={1}
                    title={i18n.strings.screens.dashboard.header.received_score}
                    style={styles.userInfoLabel}
                  />
                  <ADText
                    numberOfLines={1}
                    bold
                    style={styles.userInfoText}
                    title={data.score_receive_count}
                  />
                </Row>
              </Row>
              <Row>
                <ADText
                  numberOfLines={1}
                  title={i18n.strings.screens.dashboard.header.admission_rate}
                  style={styles.userInfoLabel}
                />
                <ADText
                  numberOfLines={1}
                  bold
                  style={styles.userInfoText}
                  title={data.admission_rate}
                />
              </Row>
            </AnimatedView>
          </Animated.View>
        </Row>
        <Animated.View
          style={{
            opacity: this.animatedScrollValue.interpolate({
              inputRange: [0, 150],
              outputRange: [1, 0],
            }),
          }}
        >
          <AnimatedView
            animation={"fadeIn"}
            delay={1500}
            useNativeDriver
            style={{
              flexDirection: "row",
              marginTop: 16,
              marginHorizontal: DPConfig.dimens.screenHorizontalPadding,
            }}
          >
            <Col style={{ flex: 1, alignItems: "flex-start" }}>
              <ADText
                numberOfLines={1}
                title={i18n.strings.screens.dashboard.header.project_done}
                style={styles.projectInfoLabel}
              />
              <ADText
                bold
                numberOfLines={1}
                title={data.project_done_count}
                style={styles.projectInfoText}
              />
            </Col>
            <Col>
              <View style={styles.header_horizontal_separator} />
            </Col>
            <Col style={{ flex: 1, alignItems: "flex-start" }}>
              <ADText
                numberOfLines={1}
                style={styles.projectInfoLabel}
                title={i18n.strings.screens.dashboard.header.project_suggest}
              />
              <ADText
                bold
                numberOfLines={1}
                title={data.project_suggest_count}
                style={styles.projectInfoText}
              />
            </Col>
          </AnimatedView>
        </Animated.View>
      </AnimatedView>
    );
  }

  @autobind
  private renderOffer(data: DashboardModel) {
    return (
      <ADTouchable
        onPress={() => {
          OfferRequestScreen.start(this.props.$navigation);
        }}
      >
        <Row
          style={[
            styles.card,
            {
              alignItems: "center",
              paddingVertical: 8,
              backgroundColor: DPConfig.colors.primaryColor,
            },
          ]}
        >
          <ADImage size={48} color={"#fff"} source={Assets.images.ic_offer} />
          <VerticalLine color={"#fff"} style={{ marginVertical: 8 }} />
          <ADText
            color={"#fff"}
            fontSize={14}
            bold
            title={i18n.strings.screens.dashboard.offer.title}
            style={{ marginEnd: 8 }}
          />
          <ADText
            title={i18n.strings.screens.dashboard.offer.sub_title}
            color={"#efefef"}
            fontSize={12}
          />
        </Row>
      </ADTouchable>
    );
  }

  @autobind
  private renderHeader(data: DashboardModel) {
    return (
      <Col
        onLayout={(e: LayoutChangeEvent) => {
          this.setState({
            headerHeight: e.nativeEvent.layout.height,
          });
        }}
      >
        <ADImage
          resizeMode={"stretch"}
          source={Assets.images.dashboard_header_back}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        />

        <Row
          style={{
            height:
              DPConfig.dimens.toolbarHeight + DPConfig.dimens.statusBarHeight,
          }}
        />

        <ADTouchable
          withoutFeedback
          onPress={() => {
            if (this.props.$api.dashboardApi.isSuccess)
              ProfileScreen.start(this.props.$navigation);
          }}
        >
          <Col style={{ flexGrow: ADResponsiveUtil.dimen(0, 0, 1) }}>
            {this.renderUserInfo(data)}
          </Col>
        </ADTouchable>
        <View style={{ height: this.state.headerOverlapHeight / 2 + 32 }} />
      </Col>
    );
  }

  @autobind
  private renderRunningItem({
    index,
    item,
    style,
  }: {
    index;
    item: ProjectListModel;
    style;
  }) {
    return (
      <ADTouchable
        key={item.id || index}
        onPress={() =>
          ProjectDetailsScreen.start(this.props.$navigation, {
            projectId: item.id,
          })
        }
      >
        <AnimatedView
          animation={"fadeInLeft"}
          delay={1500 + index * 300}
          useNativeDriver
          style={[styles.card, style]}
        >
          <Row style={{ alignItems: "center" }}>
            <Col style={{ alignItems: "stretch", flex: 1 }}>
              <DPPlaceholder.Row>
                <ADIcon
                  icon={"date-range"}
                  style={{ marginEnd: 8 }}
                  size={iconSize}
                />
                <ADText
                  title={item.create_date}
                  numberOfLines={1}
                  fontSize={12}
                />
                <Col style={{ flex: 1 }} />
                <Col style={styles.runningProjectIdBox}>
                  <ADText
                    color={DPConfig.colors.primaryColor}
                    fontSize={11}
                    numberOfLines={1}
                  >
                    {i18n.strings.screens.dashboard.running.id}
                    {item.code}
                  </ADText>
                </Col>
              </DPPlaceholder.Row>
              <DPPlaceholder.Row style={{ marginTop: 8 }}>
                <ADIcon
                  icon={"location-on"}
                  style={{ marginEnd: 8 }}
                  size={iconSize}
                />
                <ADText
                  fontSize={11}
                  bold
                  numberOfLines={1}
                  title={i18n.strings.screens.dashboard.running.address}
                />
                <ADText
                  fontSize={11}
                  style={DPConfig.styles.growWidth}
                  numberOfLines={1}
                  title={item.address}
                />
              </DPPlaceholder.Row>
            </Col>
          </Row>
        </AnimatedView>
      </ADTouchable>
    );
  }

  @autobind
  private renderSuggestItem(data: DashboardModel) {
    return (
      <AnimatedView
        animation={"fadeIn"}
        delay={1700}
        useNativeDriver
        style={[styles.card, { flexGrow: 1, overflow: "hidden" }]}
      >
        <ADImage
          style={{
            position: "absolute",
            end: -8,
            bottom: -8,
            width: DPConfig.dimens.screen.width * 0.2,
            height: ((DPConfig.dimens.screen.width * 0.2) / 156) * 186,
          }}
          resizeMode={"contain"}
          source={Assets.images.dashboard_project_back}
        />

        <ADTouchable
          withoutFeedback
          onPress={() => ProjectListScreen.start(this.props.$navigation)}
        >
          <Row
            style={{
              borderBottomColor: "#EDEDED",
              borderBottomWidth: 2.5,
              paddingVertical: 4,
            }}
          >
            <DPPlaceholder.ADText
              title={i18n.strings.screens.dashboard.suggest.title}
            />
            <View style={{ flexGrow: 1 }} />
            <DPPlaceholder.ADButton
              filled
              small
              accent
              onPress={() => ProjectListScreen.start(this.props.$navigation)}
              title={i18n.strings.screens.dashboard.suggest.user_project}
            />
          </Row>
        </ADTouchable>
        {(!data.suggest_items || data.suggest_items.length === 0) && (
          <DPPlaceholder.Col>
            <ADFlatListEmpty
              message={i18n.strings.screens.dashboard.suggest.no_suggest}
            />
          </DPPlaceholder.Col>
        )}
        {!!data.suggest_items &&
          data.suggest_items.map((project, index) => {
            let statusColor =
              project.status_color ||
              DPSelectUtil.select(project.status, {
                pending: DPConfig.colors.secondaryColor,
                running: DPConfig.colors.primaryColor,
                ended: "#9e9e9e",
                canceled: DPConfig.colors.errorColor,
                stopped: "#9e9e9e",
              });
            return (
              <ADTouchable
                key={index}
                onPress={() => {
                  ProjectDetailsScreen.start(this.props.$navigation, {
                    projectId: project.id,
                  });
                }}
              >
                <AnimatedView
                  animation={"fadeIn"}
                  delay={1700 + index * 170}
                  useNativeDriver
                  style={{
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#EDEDED",
                    paddingVertical: 4,
                  }}
                >
                  <DPPlaceholder.Row>
                    <ADIcon
                      icon={"ruler"}
                      type={"MaterialCommunityIcons"}
                      style={{ marginEnd: 8 }}
                      size={iconSize}
                    />
                    <ADText
                      fontSize={12}
                      numberOfLines={1}
                      title={i18n.strings.screens.dashboard.suggest.area}
                    />
                    <ADText
                      fontSize={12}
                      numberOfLines={1}
                      title={project.area}
                    />
                    <Col style={{ flexGrow: 1 }} />

                    <Row
                      style={{
                        minWidth: ADResponsiveUtil.width / 5,
                        borderStartColor: "#EDEDED",
                        borderStartWidth: 2.5,
                        paddingStart: 8,
                      }}
                    >
                      <ADText fontSize={12} color={statusColor}>
                        {project.create_date}
                      </ADText>
                    </Row>
                  </DPPlaceholder.Row>
                  <DPPlaceholder.Row style={{ marginVertical: 4 }}>
                    <ADIcon
                      icon={"hashtag"}
                      type={"FontAwesome"}
                      style={{ marginEnd: 8 }}
                      size={16}
                    />
                    <ADText
                      fontSize={12}
                      numberOfLines={1}
                      title={i18n.strings.screens.dashboard.suggest.id}
                    />
                    <ADText
                      fontSize={12}
                      numberOfLines={1}
                      title={project.code}
                    />
                    <Col style={{ flexGrow: 1 }} />
                  </DPPlaceholder.Row>
                  <DPPlaceholder.Row>
                    <ADIcon
                      icon={"location-on"}
                      style={{ marginEnd: 8 }}
                      size={iconSize}
                    />
                    <ADText
                      fontSize={12}
                      bold
                      numberOfLines={1}
                      title={i18n.strings.screens.dashboard.suggest.address}
                    />
                    <ADText
                      fontSize={12}
                      style={DPConfig.styles.growWidth}
                      numberOfLines={1}
                      title={project.address}
                    />
                  </DPPlaceholder.Row>
                </AnimatedView>
              </ADTouchable>
            );
          })}
      </AnimatedView>
    );
  }
}
const VerticalLine = ({
  size = 3,
  color = "#EDEDED",
  borderRadius = size / 2,
  style,
}: {
  size?;
  color?;
  borderRadius?;
  style?;
}) => (
  <Col style={[{ marginHorizontal: 16 }, style]}>
    <View style={{ width: size, backgroundColor: color, flex: 1 }} />
  </Col>
);

type P = {
  image?: ADImageSourceType;
  size: number;
  scale: Animated.AnimatedInterpolation;
  style?: StyleProp<ViewStyle>;
  info: DashboardModel;
};
const AnimatedAvatar = ({ image, size, scale, style, info }: P) => {
  let badgedSize = ResponsiveUtil.size(size / 4, 16, 48);
  let color =
    info.user_avatar_border_color ||
    DPColorUtil.lighten(DPConfig.colors.accentColor, 0.6);
  return (
    <Col style={{ marginEnd: 16 }}>
      <Animated.View
        useNativeDriver={true}
        style={[
          {
            borderWidth: 4,
            borderColor: color,
            borderRadius: size / 2,
            overflow: "hidden",
            width: size,
            height: size,
            backgroundColor: "#fff",
            opacity: scale.interpolate({
              inputRange: [0, 1, Number.MAX_SAFE_INTEGER],
              outputRange: [0, 1, 1],
            }),
            transform: Platform.select({
              default: [
                { scale: scale },
                {
                  translateX: scale.interpolate({
                    inputRange: [0, 1],
                    outputRange: [
                      -DPConfig.dimens.screenHorizontalPadding * 2 - 24,
                      0,
                    ],
                  }),
                },
              ],
              web: [],
            }),
          },
          style,
        ]}
      >
        <ADImage
          size={size - 8}
          resizeMode={"cover"}
          style={{
            borderRadius: (size - 8) / 2,
          }}
          source={!!image ? image : DPConfig.get().images.ic_profile}
        />
      </Animated.View>
      {!!info.user_avatar_badged_icon && (
        <View
          style={{
            width: badgedSize + 4,
            height: badgedSize + 4,
            borderRadius: badgedSize / 2 + 2,
            position: "absolute",
            bottom: 0,
            end: 0,
            borderWidth: 1,
            borderColor: color,
            backgroundColor: "#fff",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ADImage
            resizeMode={"contain"}
            source={info.user_avatar_badged_icon}
            size={badgedSize - 2}
          />
        </View>
      )}
    </Col>
  );
};
const iconSize = ADResponsiveUtil.dimen(14, 18, 20);
const styles = DPStyleSheet.create(({ colors, dimens, styles }) => ({
  container: {
    // backgroundColor: colors.screenBackgroundColor,
    padding: 8,
    marginHorizontal: dimens.screenHorizontalPadding,
    marginVertical: dimens.screenVerticalPadding,
    borderColor: colors.screenBackgroundColorDark,
    borderWidth: 1,
    borderRadius: 3,
    flex: 1,
  },

  userInfoUserName: {
    fontSize: ADResponsiveUtil.plus(16, 2),
    color: colors.primaryTextColor,
    textAlignVertical: "center",
  },
  userInfoLabel: {
    fontSize: ADResponsiveUtil.plus(11, 2),
    color: colors.primaryTextColor,
    textAlignVertical: "center",
  },
  userInfoText: {
    fontSize: ADResponsiveUtil.plus(14, 2),
    color: colors.primaryTextColor,
    textAlignVertical: "center",
  },
  projectInfoText: {
    fontSize: ADResponsiveUtil.plus(20, 2),
    color: colors.primaryTextColor,
    textAlignVertical: "center",
  },
  projectInfoLabel: {
    fontSize: ADResponsiveUtil.plus(12, 2),
    color: colors.primaryTextColor,
    textAlignVertical: "center",
  },
  userInfoRow: {
    paddingHorizontal: dimens.screenHorizontalPadding,
    // marginBottom: 32,
    flexGrow: ADResponsiveUtil.dimen(0, 0, 1),
  },

  header_horizontal_separator: {
    flex: 1,
    width: 2,
    backgroundColor: colors.primaryTextColor,
    marginHorizontal: 16,
    marginVertical: "10%",
    borderRadius: 2,
  },
  card: {
    ...styles.boxShadowBottom,
    backgroundColor: "#fff",
    marginHorizontal: dimens.screenHorizontalPadding,
    marginVertical: 16,
    padding: ADResponsiveUtil.dimen(8, 8, 16, 16),
    borderRadius: 4,
  },
  runningProjectIdBox: {
    backgroundColor: DPColorUtil.fade(colors.primaryColor, 0.8),
    borderColor: colors.primaryColor,
    borderWidth: 1,
    borderRadius: 10,
    minHeight: 20,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 8,
  },
  // projectStatusDot: {
  //     width: 8,
  //     height: 8,
  //     borderRadius: 4,
  //     marginEnd: 12,
  //     marginStart: 6,
  //     alignSelf: 'center'
  // }
}));
