import * as React from 'react';
import {inject, observer} from 'mobx-react';
import autobind from 'autobind-decorator';
import {
    ADButton, ADForm,
    ADFormStore,
    ADImage,
    ADRequiredValidator,
    ADScreen,
    ADText,
    ADTextInput,
    ADTouchable,
    AlertDialog,
    Col,
    DPConfig,
    DPScrollView,
    DPSelectUtil,
    DPStyleSheet,
    DPZoomImageModal,
    INavigationService,
    InjectedNavigationServiceProps,
    Row
} from '@dp/react-native-core';
import {i18n} from 'app/i18n/i18n';
import {AppToolbar} from 'app/screens/common/AppToolbar';
import InjectedApiServiceProps from '../../../../api/InjectedApiServiceProps';
import {PortfolioItemModel} from '../../../../api/Models';
import {Animated} from 'react-native';
import {View} from 'react-native-animatable';
import PortfolioListScreen from '../list/PortfolioListScreen';

type Params = {
    portfolio: PortfolioItemModel
}

type EditScreenProps =
    Params &
    InjectedNavigationServiceProps &
    InjectedApiServiceProps

type State = {}

@inject('$navigation', '$api')
@observer
export default class EditPortfolioScreen extends React.Component<EditScreenProps, State> {
    static RouteName = 'EditPortfolioScreen';

    static start(nav: INavigationService, param: Params) {
        nav.navigate(EditPortfolioScreen.RouteName, param)
    }

    form = new ADFormStore<{ title: string }>()
    state = {
        isPreviewVisible: false
    }

    animatedScrollValue = new Animated.Value(0)

    render() {
        let item = this.props.portfolio;
        let trans = i18n.strings.screens.portfolio_edit
        let stateName = item.state_name || DPSelectUtil.select(item.state, {
            'pending': trans.state.pending,
            'approved': trans.state.approved,
            'rejected': trans.state.rejected,
        });

        let stateColor = item.state_color || DPSelectUtil.select(item.state, {
            'pending': '#9e9e9e',
            'approved': DPConfig.colors.primaryColor,
            'rejected': DPConfig.colors.errorColor,
        });

        return (
            <ADForm store={this.form}>
                <ADScreen>
                    <AppToolbar
                        theme={'dark'}
                        title={i18n.strings.screens.portfolio_edit.title}
                        menuEnabled={false}
                        actions={[
                            {
                                Icon: 'delete',
                                onPress: this.handleRemovePress
                            }
                        ]}
                    />
                    <DPScrollView
                        animatedScrollValue={this.animatedScrollValue}
                        extraHeight={50}>
                        <ADTouchable onPress={() => this.setState({isPreviewVisible: true})}>
                            <Col>
                                <ADImage
                                    style={styles.image}
                                    source={item.image}
                                />
                                <Animated.View
                                    style={{
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: DPConfig.colors.accentColor,
                                        opacity: this.animatedScrollValue.interpolate({
                                            inputRange: [0, DPConfig.dimens.screen.height / 3],
                                            outputRange: [0, 1],
                                        })
                                    }}>
                                </Animated.View>
                            </Col>
                        </ADTouchable>
                        <View style={styles.card}>
                            <Row>
                                <ADText
                                    style={{marginEnd: 8}}
                                    label={'وضعیت : '}
                                    textAlign={'start'}
                                />
                                <ADText
                                    style={DPConfig.styles.growWidth}
                                    textAlign={'start'}
                                    color={stateColor}
                                    bold
                                    label={stateName}
                                />
                            </Row>
                            <Row>
                                <ADTextInput
                                    style={{flex: 1}}
                                    $id={'title'}
                                    label={'عنوان : '}
                                    multiline
                                    $validators={[new ADRequiredValidator()]}
                                    $initValue={this.props.portfolio.title}
                                    maxLength={255}
                                />
                            </Row>
                        </View>
                        <Row style={styles.submitRow}>
                            <ADButton
                                title={trans.update}
                                filled
                                block
                                accent
                                onSubmit={this.handleEditPress}
                            />
                        </Row>
                    </DPScrollView>

                    <DPZoomImageModal
                        isVisible={this.state.isPreviewVisible}
                        onVisibilityChanged={(isPreviewVisible) => this.setState({isPreviewVisible})}
                        source={{uri: item.image}}
                    />
                </ADScreen>
            </ADForm>
        )
    }


    @autobind
    handleRemovePress() {
        let trans = i18n.strings.screens.portfolio_edit.delete_confirm
        let deleteSuccessTrans = i18n.strings.screens.portfolio_edit.delete_success
        new AlertDialog.Builder()
            .setTitle(trans.title)
            .setMessage(trans.message)
            .setPositiveButton(trans.confirm, () => {
                this.props.$api.removePortfolio({portfolio_id: this.props.portfolio.id})
                    .defaultLoading(true)
                    .defaultError(true)
                    .onSuccess(response => {
                        this.props.$api.portfolioListApi.loadFirstPage();
                        new AlertDialog.Builder()
                            .setTitle(deleteSuccessTrans.title)
                            .setMessage(response.message || deleteSuccessTrans.message)
                            .setPositiveButton(deleteSuccessTrans.ok)
                            .setOnVisibilityChanged(visible => {
                                if (!visible)
                                    PortfolioListScreen.start(this.props.$navigation)
                            })
                            .setCancelable(true)
                            .create()
                            .show()
                    })
                    .enqueue()
            })
            .setNegativeButton(trans.cancel)
            .create()
            .show()
    }

    @autobind
    handleEditPress() {
        let title = this.form.data.get('title');
        if (!title) return;
        let trans = i18n.strings.screens.portfolio_edit.edit_success

        this.props.$api.editPortfolios({
            portfolio_id: this.props.portfolio.id,
            title: title
        })
            .defaultError()
            .defaultLoading()
            .onSuccess(response => {
                this.props.$api.portfolioListApi.loadFirstPage();
                new AlertDialog.Builder()
                    .setTitle(trans.title)
                    .setMessage(response.message || trans.message)
                    .setPositiveButton(trans.ok)
                    .setOnVisibilityChanged(visible => {
                        if (!visible)
                            PortfolioListScreen.start(this.props.$navigation)
                    })
                    .setCancelable(true)
                    .create()
                    .show()
            })
            .enqueue()
    }
}


const styles = DPStyleSheet.create(({dimens, styles}) => ({
    submitRow: {
        marginVertical: 16,
        marginHorizontal: dimens.screenHorizontalPadding,
    },
    image: {
        width: '100%',
        minHeight: dimens.screen.height / 2
    },
    card: {
        ...styles.card,
        marginHorizontal: dimens.screen.horizontalPadding,
        paddingHorizontal: dimens.screen.horizontalPadding,
        paddingVertical: 16,
        marginTop: -32,
        marginBottom: 16,
        flexGrow: 1,
    }
}));
