import * as React from "react";
import { observer } from "mobx-react";
import {
  ADForm,
  ADFormStore,
  ADRequiredValidator,
  ADTextInput,
  DPAlertDialog,
  Row,
} from "@dp/react-native-core";
import autobind from "autobind-decorator";
import ProjectStore from "./ProjectStore";
import { i18n } from "../../../../i18n/i18n";
import { Platform } from "react-native";

export interface RequestConfirmModalProps {
  store: ProjectStore;
}

@observer
export class ProjectConfirmModal extends React.Component<RequestConfirmModalProps> {
  form: ADFormStore<{ code?: string }>;

  constructor(p, c) {
    super(p, c);
    this.form = new ADFormStore();
  }

  render() {
    let { store } = this.props;
    let info = store.confirmFormInfo;
    return (
      <DPAlertDialog
        isVisible={store.isConfirmFormVisible}
        onVisibilityChanged={(isVisible) => {
          if (!isVisible) {
            this.form.clear();
            store.hideConfirmModal();
          }
        }}
        cancelable={false}
        title={info.title}
        message={info.message}
        positiveButton={{
          title:
            info.title || i18n.strings.screens.project_details.confirm.submit,
          onClick: this.handleSubmit,
        }}
        negativeButton={{
          title: i18n.strings.screens.project_details.confirm.cancel,
          onClick: this.handleCancel,
        }}
        avoidKeyboardAndroid
        avoidKeyboard={Platform.OS !== "web"}
      >
        <ADForm store={this.form}>
          <Row style={{ alignItems: "center", marginTop: 20 }}>
            <ADTextInput
              keyboardType={"numeric"}
              style={{ flex: 1 }}
              label={i18n.strings.screens.project_details.confirm.input_label}
              help={
                info.help ||
                i18n.strings.screens.project_details.confirm.input_placeholder
              }
              $validators={[new ADRequiredValidator()]}
              $id={"code"}
            />
          </Row>
        </ADForm>
      </DPAlertDialog>
    );
  }

  @autobind
  handleSubmit() {
    if (!this.form.validate()) return true; //prevent modal from closing
    let code = this.form.data.get("code");
    if (code) this.props.store.handleSubmitConfirm(code);
    return true; //prevent close
  }

  @autobind
  handleCancel() {
    this.props.store.hideConfirmModal();
  }
}

//
// const styles = DPStyleSheet.create(({dimens}) => ({
//     container: {
//         backgroundColor: '#fff',
//         maxWidth: '90%',
//         minWidth: 300,
//         alignSelf: 'center',
//         borderRadius: 4,
//         marginVertical: dimens.screen.verticalPadding,
//         marginHorizontal: dimens.screen.horizontalPadding,
//         paddingHorizontal: 8
//     },
//     header: {
//         color: '#000'
//     }
// }))
