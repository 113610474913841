import * as React from 'react';
import {inject, observer} from 'mobx-react';
import autobind from 'autobind-decorator';
import {ADFab, ADFlatList, ADScreen, DPConfig, DPStyleSheet, INavigationService, InjectedNavigationServiceProps} from '@dp/react-native-core';
import OfferListScreenItem from './OfferListScreenItem';
import InjectedApiServiceProps from 'app/api/InjectedApiServiceProps';
import {AppToolbar} from 'app/screens/common/AppToolbar';
import OfferRequestScreen from '../offer-request/OfferRequestScreen';
import Assets from '../../../../assets/Assets';
import {Share} from 'react-native';
import {OfferListModel} from '../../../../api/Models';
import {i18n} from '../../../../i18n/i18n';


type Params = {}

type OfferListScreenProps =
    Params &
    InjectedNavigationServiceProps &
    InjectedApiServiceProps

type State = {}

@inject('$navigation', '$api')
@observer
export default class OfferListScreen extends React.Component<OfferListScreenProps, State> {
    static RouteName = 'OfferListScreen'

    static start(nav: INavigationService, param: Params = {}) {
        nav.navigate(OfferListScreen.RouteName, param)
    }


    render() {
        return (
            <ADScreen>
                <AppToolbar
                    theme={'light'}
                    title={i18n.strings.screens.discount_list.title}/>
                <ADFlatList
                    api={this.props.$api.offerListApi}
                    renderItem={this.renderItem}
                    autoLoad={'no-data'}
                />
                <ADFab
                    icon={Assets.images.ic_offer}
                    iconStyle={{marginStart: 4, marginBottom: 4}}
                    onPress={this.handleFabPress}
                />
            </ADScreen>
        )
    }

    @autobind
    renderItem(p: { item, index }) {
        return (
            <OfferListScreenItem
                item={p.item}
                $navigation={this.props.$navigation}
                onPress={() => this.handleItemPress(p.item)}
            />
        )
    }


    @autobind
    handleItemPress(item: OfferListModel) {
        let trans = i18n.strings.screens.discount_list.share;
        Share.share({
            message: trans.message + item.code,
            title: trans.title
        }, {
            dialogTitle: trans.dialogTitle,
            subject: trans.subject,
            tintColor: DPConfig.colors.accentColor
        })
    }

    @autobind
    handleFabPress() {
        OfferRequestScreen.start(this.props.$navigation)
    }


}


const styles = DPStyleSheet.create(() => ({}));
