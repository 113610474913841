import * as React from "react";
import {
  ADImage,
  ADResponsiveUtil,
  Col,
  DPColorUtil,
  DPConfig,
  DPStyleSheet,
  ResponsiveUtil,
  ADText,
  Row,
} from "@dp/react-native-core";
import { StyleProp, View, ViewStyle } from "react-native";
import { inject, observer } from "mobx-react";
import InjectedApiServiceProps from "../../../api/InjectedApiServiceProps";
import Assets from "../../../assets/Assets";
import { ProfileScoreModel } from "app/api/Models";
import { observable } from "mobx";

type Props = InjectedApiServiceProps & {
  style?: StyleProp<ViewStyle>;
  avatarSize?: number;
  borderColor?: string;
};

@inject("$api")
@observer
export default class Avatar extends React.Component<Props> {
  static defaultProps = {
    avatarSize: ADResponsiveUtil.dimen(50, 60, 70),
  };

  render() {
    let info = this.props.$api.dashboardApi.data;
    let avatarSize = this.props.avatarSize || Avatar.defaultProps.avatarSize; // to fix null check type warning
    let badgedSize = ResponsiveUtil.size(avatarSize / 4, 16, 48);
    let color =
      info.user_avatar_border_color ||
      DPColorUtil.lighten(DPConfig.colors.accentColor, 0.6);
    if (__DEV__ && !info.user_avatar_badged_icon) {
      info.user_avatar_badged_icon = Assets.images.logo;
    }

    return (
      <View
        style={[
          {
            width: avatarSize + 8,
            height: avatarSize + 8,
            // backgroundColor: colors.primaryColorDark,// DPColorUtil.lighten(colors.primaryColor, .5),
            borderWidth: 4,
            borderColor: color,
            borderRadius: (avatarSize + 8) / 2,
            alignItems: "center",
            justifyContent: "center",
          },
          this.props.style,
        ]}
      >
        <View
          style={{
            backgroundColor: "#fff",
            width: avatarSize,
            height: avatarSize,
            borderRadius: avatarSize / 2,
          }}
        >
          <View style={[{ alignItems: "center" }]}>
            <ADImage
              size={avatarSize}
              borderRadius={avatarSize / 2}
              style={{
                borderRadius: avatarSize / 2,
              }}
              resizeMode={"cover"}
              source={
                !!info.user_image
                  ? info.user_image
                  : DPConfig.get().images.ic_profile
              }
            />
          </View>
        </View>
        {!!info.user_avatar_badged_icon && (
          <View
            style={{
              width: badgedSize + 4,
              height: badgedSize + 4,
              borderRadius: badgedSize / 2 + 2,
              position: "absolute",
              bottom: -2,
              end: -2,
              borderWidth: 1,
              borderColor: color,
              backgroundColor: "#fff",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ADImage
              resizeMode={"contain"}
              source={info.user_avatar_badged_icon}
              size={badgedSize - 2}
            />
          </View>
        )}
      </View>
    );
  }
}

const styles = DPStyleSheet.create(({ styles, dimens, colors }) => ({}));
