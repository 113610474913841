import {ADHttpRequest, DPConfig, DPFakeUtil, DPSelectUtil} from '@dp/react-native-core';
import {
    DashboardModel,
    OfferListModel,
    OfferRequestScreenInfoModel,
    PortfolioItemModel,
    ProfileModel,
    ProjectListModel,
    ProjectSearchFormInfoModel,
    ProjectUpdateStateDataModel,
    SettingModel,
    TechnicalFormInfoModel
} from '../Models';

let newsItems = [
    {
        'id': 2,
        'title': 'Restraining connectivity crisis in the North East of Tehran',
        'order': '0',
        'type': '1',
        'category_id': '2',
        'description': "Connectivity crisis in the North East of Tehran was restrained by timely presence of NAK technical team. A few minutes after Azan in the morning of June 28, the sound of explosions   launched Shahran area residents into the alley and street. Within moments after, it was found that gas pipeline explosion has destroyed everything and there is no connection possibility in the area. Fixed and mobile phones were discontinued. Of course, this disconnection was not limited to Shahran area and thousands of subscribers in the North West of Tehran could not use Iran Mobile Communication line. Hours after the incident and the presence of General Director of Mobile Communications in Tehran in the area, the order of the formation of crisis teams were issued and 500 thousand subscribers who did not have access to MCI network were promised through the media that their telephone connection would be restored quickly and by the end of the night. Engineer Mehdi Abbas Khani announcing the details, informed about the new capacities of mobile line by noon that day and said:\" Connection of more than 350 thousand subscribers has been connected in the area. Of course with the efforts of all partners, the CEO promise was practiced and subscribers benefited from the country's largest operator services as before.\" ",
        'excerpt': 'Took place by timely presence of NAK technical team: Restraining connectivity crisis in the North East of Tehra',
        'featured': 0,
        'active': '1',
        'notify': '0',
        'file': null,
        'main_image': 'news\/1518070255.jpg',
        'cover': null,
        'video': null,
        'started_at': '2018-01-22 00:00:00',
        'expired_at': '2018-02-19 00:00:00',
        'date_at': '2018-02-07 00:00:00',
        'created_at': '2018-01-31 11:23:01',
        'updated_at': '2018-02-08 06:10:55',
        'deleted_at': null,
        'language': 'en',
        'author': null,
        'main_image_url': 'http:\/\/mobileapp.nak-mci.ir\/storage\/news\/1518070255.jpg',
        'category': 'News',
        'comments_count': 2,
        'likes_count': 1,
        'news_cat': {
            'id': 2,
            'title': 'News',
            'description': null,
            'deleted_at': null,
            'created_at': null,
            'updated_at': null,
            'language': 'en',
            'jalali_created_at': null,
            'jalali_updated_at': null
        },
        'jalali_started_at': '96\/11\/02',
        'jalali_expired_at': '96\/11\/30',
        'jalali_date_at': '96\/11\/18',
        'jalali_deleted_at': null,
        'jalali_created_at': '96\/11\/11',
        'jalali_updated_at': '96\/11\/19'
    },
    {
        'id': 3,
        'title': 'راه اندازی جدیدترین سامانه های اطلاعاتی',
        'order': '2',
        'type': '1',
        'category_id': '1',
        'description': 'متن عنوان برای تست اخبار فناوری اطلاعات به کار می رود. متن عنوان برای تست اخبار فناوری اطلاعات به کار می رود. متن عنوان برای تست اخبار فناوری اطلاعات به کار می رود. متن عنوان برای تست اخبار فناوری اطلاعات به کار می رود. متن عنوان برای تست اخبار فناوری اطلاعات به کار می رود. متن عنوان برای تست اخبار فناوری اطلاعات به کار می رود. متن عنوان برای تست اخبار فناوری اطلاعات به کار می رود. متن عنوان برای تست اخبار فناوری اطلاعات به کار می رود. متن عنوان برای تست اخبار فناوری اطلاعات به کار می رود. متن عنوان برای تست اخبار فناوری اطلاعات به کار می رود. متن عنوان برای تست اخبار فناوری اطلاعات به کار می رود. \r\nمتن عنوان برای تست اخبار فناوری اطلاعات به کار می رود. متن عنوان برای تست اخبار فناوری اطلاعات به کار می رود. متن عنوان برای تست اخبار فناوری اطلاعات به کار می رود. \r\nمتن عنوان برای تست اخبار فناوری اطلاعات به کار می رود. متن عنوان برای تست اخبار فناوری اطلاعات به کار می رود.',
        'excerpt': 'متن عنوان برای تست اخبار فناوری اطلاعات به کار می رود. متن عنوان برای تست اخبار فناوری اطلاعات به کار می رود.',
        'featured': 0,
        'active': '1',
        'notify': '0',
        'file': null,
        'main_image': 'news\/1518256358.jpg',
        'cover': null,
        'video': null,
        'started_at': '2018-02-10 00:00:00',
        'expired_at': '2018-02-19 00:00:00',
        'date_at': '2018-02-10 00:00:00',
        'created_at': '2018-02-10 09:52:38',
        'updated_at': '2018-07-18 14:48:15',
        'deleted_at': null,
        'language': 'fa',
        'author': null,
        'main_image_url': 'http:\/\/mobileapp.nak-mci.ir\/storage\/news\/1518256358.jpg',
        'category': 'اخبار',
        'comments_count': 1,
        'likes_count': 3,
        'news_cat': {
            'id': 1,
            'title': 'اخبار',
            'description': '<p>شرح اخبار ناک<\/p>',
            'deleted_at': null,
            'created_at': '2018-01-31 11:01:49',
            'updated_at': '2018-01-31 11:01:49',
            'language': 'fa',
            'jalali_created_at': '96\/11\/11',
            'jalali_updated_at': '96\/11\/11'
        },
        'jalali_started_at': '96\/11\/21',
        'jalali_expired_at': '96\/11\/30',
        'jalali_date_at': '96\/11\/21',
        'jalali_deleted_at': null,
        'jalali_created_at': '96\/11\/21',
        'jalali_updated_at': '97\/04\/27'
    },
    {
        'id': 1,
        'title': 'سازمان 3GPP استانداردهای 5G را تعیین کرد',
        'order': '2',
        'type': '1',
        'category_id': '1',
        'description': 'به گزارش "تلکام نیوز" ؛ 3GPP سازمانی است که مسئولیت تعریف استانداردهای صنعتی برای فناوری‌های بعد از 4G را بر عهده دارد. روز گذشته این سازمان مشخصات لازم برای تجهیزات 5G را در خصوص دکل‌ها و گوشی‌های هوشمند تعیین کرد.\r\n\r\nبنابراین استانداردهای اینترنت نسل پنجم تعیین شده است و مهندسان شبکه و امواج می‌توانند همگی روی یک پلتفرم واحد برای تمام دستگاه‌های 5G کار کنند. قابلیت‌ها، عملکردها، خدمات ‌و استانداردها برای اپراتورها و سازندگان تلفن‌ همراه و تجهیزات رادیویی تعیین شده است و اکنون آن‌ها می‌توانند بر این اساس تجهیزات و سرویس‌های نسل پنجم خود را برای استفاده در شبکه‌های دارای این قابلیت در سراسر دنیا عرضه کنند.\r\nشرکت‌های زیادی برای استفاده از 5G در محصولات آینده‌ی خود ابراز تمایل کرده‌اند که از معروف‌ترین آن‌ها می‌توان به کوالکام، تی‌موبایل، سامسونگ، زدتی‌ای، ورایزن، سونی، نوکیا، مدیاتک و هواوی اشاره کرد.',
        'excerpt': 'سازمان 3GPP استانداردهای مربوط به گیرنده‌ها و فرستنده‌های نسل پنجم اینترنت مویابل را تعیین کرد که برای دستگاه‌های مبتنی بر شبکه‌ی 5G در نظر گرفته شده است.',
        'featured': '1',
        'active': '1',
        'notify': '0',
        'file': null,
        'main_image': 'news\/1518070018.jpg',
        'cover': null,
        'video': null,
        'started_at': '2018-02-08 00:00:00',
        'expired_at': '2018-03-20 00:00:00',
        'date_at': '2018-02-08 00:00:00',
        'created_at': '2018-01-31 11:03:08',
        'updated_at': '2018-07-18 14:45:55',
        'deleted_at': null,
        'language': 'fa',
        'author': null,
        'main_image_url': 'http:\/\/mobileapp.nak-mci.ir\/storage\/news\/1518070018.jpg',
        'category': 'اخبار',
        'comments_count': 2,
        'likes_count': 1,
        'news_cat': {
            'id': 1,
            'title': 'اخبار',
            'description': '<p>شرح اخبار ناک<\/p>',
            'deleted_at': null,
            'created_at': '2018-01-31 11:01:49',
            'updated_at': '2018-01-31 11:01:49',
            'language': 'fa',
            'jalali_created_at': '96\/11\/11',
            'jalali_updated_at': '96\/11\/11'
        },
        'jalali_started_at': '96\/11\/19',
        'jalali_expired_at': '96\/12\/29',
        'jalali_date_at': '96\/11\/19',
        'jalali_deleted_at': null,
        'jalali_created_at': '96\/11\/11',
        'jalali_updated_at': '97\/04\/27'
    }
];

let newsDetails = {
    'id': 1,
    'title': 'سازمان 3GPP استانداردهای 5G را تعیین کرد',
    'description': 'به گزارش "تلکام نیوز" ؛ 3GPP سازمانی است که مسئولیت تعریف استانداردهای صنعتی برای فناوری‌های بعد از 4G را بر عهده دارد. روز گذشته این سازمان مشخصات لازم برای تجهیزات 5G را در خصوص دکل‌ها و گوشی‌های هوشمند تعیین کرد.\r\n\r\nبنابراین استانداردهای اینترنت نسل پنجم تعیین شده است و مهندسان شبکه و امواج می‌توانند همگی روی یک پلتفرم واحد برای تمام دستگاه‌های 5G کار کنند. قابلیت‌ها، عملکردها، خدمات ‌و استانداردها برای اپراتورها و سازندگان تلفن‌ همراه و تجهیزات رادیویی تعیین شده است و اکنون آن‌ها می‌توانند بر این اساس تجهیزات و سرویس‌های نسل پنجم خود را برای استفاده در شبکه‌های دارای این قابلیت در سراسر دنیا عرضه کنند.\r\nشرکت‌های زیادی برای استفاده از 5G در محصولات آینده‌ی خود ابراز تمایل کرده‌اند که از معروف‌ترین آن‌ها می‌توان به کوالکام، تی‌موبایل، سامسونگ، زدتی‌ای، ورایزن، سونی، نوکیا، مدیاتک و هواوی اشاره کرد.',
    'excerpt': 'سازمان 3GPP استانداردهای مربوط به گیرنده‌ها و فرستنده‌های نسل پنجم اینترنت مویابل را تعیین کرد که برای دستگاه‌های مبتنی بر شبکه‌ی 5G در نظر گرفته شده است.',
    'author': null,
    'main_image_url': 'http://mobileapp.nak-mci.ir/storage/news/1518070018.jpg',
    'category': 'اخبار',
    'comments_count': 2,
    'likes_count': 1,
    'comments': [
        {
            'id': 31,
            'user_id': '2',
            'news_id': '1',
            'name': null,
            'family': null,
            'description': 'test',
            'active': '1',
            'created_at': '2018-10-27 14:54:13',
            'updated_at': '2018-10-27 14:54:13',
            'user_name': 'کیمیایی',
            'jalali_created_at': '97\/08\/05',
            'jalali_updated_at': '97\/08\/05'
        },
        {
            'id': 32,
            'user_id': '2',
            'news_id': '1',
            'name': null,
            'family': null,
            'description': 'test',
            'active': '1',
            'created_at': '2018-10-27 15:06:50',
            'updated_at': '2018-10-27 15:06:50',
            'user_name': 'کیمیایی',
            'jalali_created_at': '97\/08\/05',
            'jalali_updated_at': '97\/08\/05'
        }
    ],
    'jalali_date_at': '96\/11\/19'
}
const createProfile = () => {
    let items = DPFakeUtil.randomPickerItems('دوره', 5)
    return {
        id: DPFakeUtil.randomNumber(),
        address: DPFakeUtil.randomText(6),
        courses_items: items,
        courses: items.map(_ => _.id).filter(_ => Math.random() > .5),
        mobile: DPFakeUtil.randomMobileNumber(),
        name: DPFakeUtil.randomText(2),
        image: DPFakeUtil.randomImageUrl()
    } as ProfileModel
};

const createSetting = () => {
    let items = DPFakeUtil.randomPickerItems('آیتم', 5)
    return {
        active: DPFakeUtil.randomBoolean(),
        area_items: items,
        area: items.map(_ => _.id),
        service_items: items,
        service: items.map(_ => _.id),
    } as SettingModel
};

let projectItems = DPFakeUtil.randomItems(_ => ({
    id: DPFakeUtil.randomNumber(10000, 99999),
    code: 'kf-' + DPFakeUtil.randomNumber(10000, 99999),
    address: DPFakeUtil.randomText(8),
    area: DPFakeUtil.randomText(2),
    create_date: DPFakeUtil.randomDate(),
    state: DPFakeUtil.randomEnum(['accepted', 'pending', 'done', 'started']),
} as ProjectListModel), 10);

export default {
    'request': () => ({
        status: true,
        message: DPFakeUtil.randomText(),
        data: null
    }),
    'auth/login': () => ({
        status: true,
        message: DPFakeUtil.randomText(),
        data: 'test_token'
    }),
//  ---------------------------------------------------------------------------------------------------

    'project/items': (req) => {

        return ({
            status: true,
            message: null,

            next_page_url: 'ss',
            current_page: req.data.page,

            data: projectItems
        })
    },
//  ---------------------------------------------------------------------------------------------------

    'news': (req) => ({
        status: true,
        message: null,
        data: [...newsItems, ...newsItems, ...newsItems, ...newsItems].map(_ => ({
            ..._,
            id: Math.random() + '',
            main_image_url: DPFakeUtil.randomImageUrl(),
            read: DPFakeUtil.randomBoolean(),
            view_count: DPFakeUtil.randomNumber(0, 10000),
        }))
    }),
    'news/like/': () => ({
        status: true,
        message: null,
        data: newsDetails
    }),
    //  ---------------------------------------------------------------------------------------------------
    'news/comment/': () => ({
        status: true,
        message: null,
        data: newsDetails
    }),
    'news/details': () => ({
        status: true,
        message: null,
        data: newsDetails
    }),
//  ---------------------------------------------------------------------------------------------------

    'dashboard': () => ({
        status: true,
        message: null,
        action: {
            type: 'dialog',
            payload: {
                title: 'Force Update',
                message: 'test',
                cancelable: false,
                positiveButton: {
                    title: 'Update',
                    onPress: [
                        {
                            type: 'link/open',
                            payload: {
                                url: 'https://www.google.com',
                            },
                        },
                        {
                            type: 'app/exit',
                            payload: {},
                        }
                    ]
                },
                negativeButton: {
                    title: 'Exit',
                    onPress: {
                        type: 'app/exit',
                        payload: {},
                    }
                }
            }
        },
        data: {
            user_name: 'محمدرضا علیزاده',
            user_image: DPFakeUtil.randomImageUrl(),
            project_done_count: DPFakeUtil.randomNumber(),
            project_suggest_count: DPFakeUtil.randomNumber(),
            score_exist_count: DPFakeUtil.randomNumber(),
            score_receive_count: DPFakeUtil.randomNumber(),
            events: DPFakeUtil.randomItems(index => ({
                jalali_date_at: DPFakeUtil.randomDate(),
                description: DPFakeUtil.randomText(),
                main_image_url: DPFakeUtil.randomImageUrl(),
                title: DPFakeUtil.randomText(5),
                url: 'https://www.google.com'
            }), 5),
            socials: DPFakeUtil.randomItems(index => ({
                icon: [
                    {icon: 'telegram', type: 'MaterialCommunityIcons'},
                    {icon: 'facebook-box', type: 'MaterialCommunityIcons'},
                    {icon: 'instagram', type: 'MaterialCommunityIcons'},
                    {icon: 'google-plus', type: 'MaterialCommunityIcons'},
                ][index],
                url: 'https://www.google.com'
            }), 4),
            suggest_items: projectItems,
            running_items: projectItems,
            notification_unread_count: DPFakeUtil.randomNumber(1, 10)
        } as DashboardModel
    }),

    //  ---------------------------------------------------------------------------------------------------

    'project/details': (req: ADHttpRequest) => ({
        status: true,
        message: null,
        data: {
            id: DPFakeUtil.randomNumber(),
            code: 'kf-' + DPFakeUtil.randomNumber(),
            state: req.data && req.data['new_state'] || 'pending',
            location: {
                latitude: 35.754690,
                longitude: 51.408406,
            },
            project_info: [
                {label: 'نشانی', value: 'ونک - تقاطع توانیر و برزیل - کوچه امید'},
                {label: 'نوع خدمت', value: 'نصب دیوار', icon: 'create', url: 'tel:09379564336'},
                {label: 'متراژ', value: '۱۰۰ الی ۲۰۰ متر'},
            ],
            customer_info: [
                {label: 'نشانی', value: 'ونک - تقاطع توانیر و برزیل - کوچه امید'},
                {label: 'نوع خدمت', value: 'نصب دیوار'},
                {label: 'متراژ', value: '۱۰۰ الی ۲۰۰ متر'},
            ],
            confirm: {
                title: DPFakeUtil.randomText(5),
                message: DPFakeUtil.randomText(20),
            }
        } as ProjectUpdateStateDataModel
    }),
    //  ---------------------------------------------------------------------------------------------------

    'project/form': (req: ADHttpRequest) => ({
        status: true,
        message: null,
        data: {
            service_items: DPFakeUtil.randomPickerItems(undefined, 5),
        } as ProjectSearchFormInfoModel
    }),
//  ---------------------------------------------------------------------------------------------------
    'technical/info': (req: ADHttpRequest) => ({
        status: true,
        message: null,
        data: {
            wall_help: DPFakeUtil.randomText(),
            roof_help: DPFakeUtil.randomText(),
            roof_area_items: DPFakeUtil.randomPickerItems(),
            roof_type_items: DPFakeUtil.randomPickerItems(),
            wall_area_items: DPFakeUtil.randomPickerItems(),
            wall_type_items: DPFakeUtil.randomPickerItems(),
        } as TechnicalFormInfoModel
    }),
    //  ---------------------------------------------------------------------------------------------------
    'projects/technical': (req: ADHttpRequest) => ({
        status: true,
        message: 'با موفقیت ثبت شد',
        data: null
    }),
//  ---------------------------------------------------------------------------------------------------
    'offer-list-screen/items': () => ({
        status: true,
        message: null,
        data: DPFakeUtil.randomItems(index => ({
            id: index,
            consumed: DPFakeUtil.randomBoolean(),
            date: DPFakeUtil.randomDate(),
            score: DPFakeUtil.randomNumber(),
            code: DPFakeUtil.randomNumber(100000, 999999),
            store: DPFakeUtil.randomText(2),
            value: DPFakeUtil.randomNumber() * 1000
        } as OfferListModel))
    }),

    //  ---------------------------------------------------------------------------------------------------
    'user/profile': () => ({
        status: true,
        message: null,
        data: createProfile()
    }),
    //  ---------------------------------------------------------------------------------------------------
    'offer/info': () => ({
        status: true,
        message: null,
        data: {
            note: DPFakeUtil.randomText(30),
            enabled: DPFakeUtil.randomBoolean(),
            total_score: DPFakeUtil.randomNumber(1000, 99999)
        } as OfferRequestScreenInfoModel
    }),
    //  ---------------------------------------------------------------------------------------------------
    'offer/convert': () => ({
        status: true,
        message: null,
        data: {
            note: DPFakeUtil.randomText(30),
            enabled: DPFakeUtil.randomBoolean(),
            total_score: DPFakeUtil.randomNumber(1000, 99999)
        } as OfferRequestScreenInfoModel
    }),

    //  ---------------------------------------------------------------------------------------------------
    'profile/update': (req: ADHttpRequest) => ({
        status: true,
        message: JSON.stringify(req.data, null, '\t'),
        data: createProfile()
    }),

    //  ---------------------------------------------------------------------------------------------------
    'setting/info': () => ({
        status: true,
        message: null,
        data: createSetting()
    }),
    //  ---------------------------------------------------------------------------------------------------
    'setting/update': (req: ADHttpRequest) => ({
        status: true,
        message: JSON.stringify(req.data, null, '\t'),
        data: createSetting()
    }),
//  ---------------------------------------------------------------------------------------------------

    'portfolio': () => ({
        status: true,
        message: null,
        data: DPFakeUtil.randomItems(index => {
            let state = DPFakeUtil.randomEnum(['pending', 'approved', 'rejected'])
            return {
                id: index,
                image: DPFakeUtil.randomImageUrl(),
                state: state,
                // state_name: DPSelectUtil.select(state, {
                //     'pending': 'Pending',
                //     'approved': 'Approved',
                //     'rejected': 'Rejected',
                // }),
                // state_color: DPSelectUtil.select(state, {
                //     'pending': '#9e9e9e',
                //     'approved': DPConfig.colors.primaryColor,
                //     'rejected': DPConfig.colors.errorColor,
                // }),
                title: DPFakeUtil.randomText(),
                create_at: DPFakeUtil.randomDate(),
                update_at: DPFakeUtil.randomDate()
            } as PortfolioItemModel
        })
    }),

    // $$dp-cli-merge$$
}

