import * as React from 'react';
import {inject, observer} from 'mobx-react';
import {InteractionManager, Platform, View, ViewStyle} from 'react-native';
import autobind from 'autobind-decorator';
import {
    ADButton,
    ADFlatList,
    ADIcon,
    ADResource,
    Col,
    DPConfig,
    DPStyleSheet,
    DPTextInput,
    IDType,
    INavigationService,
    InjectedNavigationServiceProps,
    Row
} from '@dp/react-native-core';
import InjectedApiServiceProps from 'app/api/InjectedApiServiceProps';
import {ProjectCommentItemModel, ProjectDetailsModel} from 'app/api/Models';
import AppToolbar from 'app/screens/common/AppToolbar';
import {i18n} from 'app/i18n/i18n';
import {InjectedKeyboardServiceProps} from '@dp/react-native-core/dist/dpcoresrc/services/InjectedServiceProps';
import ProjectCommentListItem from './ProjectCommentListItem';


type Params = {
    projectDetailsApi: ADResource<any, any, ProjectDetailsModel>
    projectId: IDType
}

type TicketDetailsScreenProps =
    Params &
    InjectedNavigationServiceProps &
    InjectedKeyboardServiceProps &
    InjectedApiServiceProps

@inject('$navigation', '$api', '$keyboard')
@observer
export default class ProjectCommentsScreen extends React.Component<TicketDetailsScreenProps, { text: string }> {
    static RouteName = 'ProjectCommentsScreen';

    static start(nav: INavigationService, p: Params) {
        nav.navigate(ProjectCommentsScreen.RouteName, p)
    }

    state = {
        text: ''
    };

    render() {
        let trans = i18n.strings.screens.project_comments;
        let projectDetailsApi = this.props.projectDetailsApi;
        let comments = projectDetailsApi.data.comments || [];
        return (
            <Col style={styles.container}>
                <AppToolbar theme={'dark'}
                            title={trans.title}
                />

                <Col style={[{flex: 1}, Platform.OS === 'web' && {height: 0}]}>
                    <ADFlatList
                        onRefresh={() => projectDetailsApi.reload()}
                        refreshing={projectDetailsApi.isLoading}
                        data={
                            Platform.OS == 'web' ?
                                comments.reverse() :
                                comments
                        }
                        renderItem={this.renderItem}
                        ItemSeparatorComponent={null}
                        inverted={Platform.OS !== 'web'}
                        contentContainerStyle={{paddingBottom: 16, flexGrow: 0}}
                    />
                    {
                        !!projectDetailsApi.data.can_store_comment &&
                        this.renderInput()
                    }
                    {
                        Platform.OS === 'ios' &&
                        <View style={{minHeight: this.props.$keyboard.height}}/>
                    }
                </Col>
            </Col>
        )
    }

    protected renderInput = () => {
        let trans = i18n.strings.screens.project_comments
        return (

            <Row
                key={'input-row'}
                style={[DPConfig.get().styles.boxShadowBottom, {
                    backgroundColor: '#fff',
                    borderTopColor: '#9e9e9e',
                    borderTopWidth: 1,
                    flexShrink: 0,
                    paddingVertical: 4,
                    paddingHorizontal: DPConfig.dimens.screen.horizontalPadding,
                }]}
            >
                <DPTextInput
                    key={'input'}
                    placeholderStyle={{
                        textAlign: 'right',
                        start: 0,
                    }}
                    multiline
                    style={{flex: 1}}
                    placeholder={trans.input.placeholder}
                    placeholderTextColor={DPConfig.colors.placeholderColor}
                    useNativePlaceholder={false}
                    textStyle={{
                        textAlign: 'right',
                        paddingVertical: 12
                    }}
                    onChangeText={(text) => {
                        this.setState({
                            text
                        })
                    }}

                    // onSubmitEditing={this.submit}
                    // returnKeyType={'send'}
                    // returnKeyLabel={trans.input.submit}
                    disableFullscreenUI
                    defaultValue={this.state.text}
                    blurOnSubmit={true}
                    underlineColor={DPConfig.colors.accentColor}
                    underlineEnabled={true}
                    after={
                        <ADButton style={{padding: 8}} onPress={this.submit}>
                            <ADIcon
                                icon={'send'}
                                mirror
                                color={DPConfig.colors.accentColor}/>
                        </ADButton>
                    }
                />
            </Row>
        );
    }


    @autobind
    protected renderItem(p: { item: ProjectCommentItemModel, index: number }) {
        return <ProjectCommentListItem item={p.item}/>;
    }


    lock = false

    @autobind
    async submit() {
        if (!this.state.text || this.lock) {
            return
        }
        this.lock = true;
        InteractionManager.runAfterInteractions(() => {
            this.props.$api.addCommentToProject({
                project_id: this.props.projectId,
                comment: this.state.text
            })
                .onSuccess((response) => {
                    this.setState({
                        text: ''
                    });
                    this.props.projectDetailsApi.onSuccess(response, this.props.projectDetailsApi.lastCall);
                })
                .onStopLoading(() => {
                    this.lock = false
                })
                .defaultError(true)
                .defaultLoading()
                .enqueue()
        })
    }
}

const styles = DPStyleSheet.create(({colors}) => ({
    container: {
        flex: 1,
        backgroundColor: colors.screenBackgroundColor
    } as ViewStyle,
}))