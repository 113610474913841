import * as React from 'react';
import {observer} from 'mobx-react';
import {NewsItemModel} from 'app/api/Models';
import {ADIcon, ADImage, ADResponsiveUtil, ADText, ADTouchable, Col, DPConfig, DPStyleSheet, Row} from '@dp/react-native-core';
import Assets from 'app/assets/Assets';
import moment from 'jalali-moment';

interface Props {
    item: NewsItemModel,
    onPress: (item: NewsItemModel) => void
}

@observer
export default class NewsListItem extends React.Component<Props> {
    render() {
        let item = this.props.item;
        let date = moment(item.jalali_publish_at, 'jYYYY/jMM/jDD').locale('fa');

        const THUMB_SIZE = ADResponsiveUtil.size(ADResponsiveUtil.width / 5, 40, 72);

        return (
            <ADTouchable onPress={() => this.props.onPress(item)}>
                <Row style={[styles.container, {
                    borderStartWidth: 3,
                    borderStartColor: item.read ?
                        '#9e9e9e' :
                        DPConfig.colors.secondaryColor
                }]}>
                    <ADImage
                        source={!!item.main_image_url ? {uri: item.main_image_url} : Assets.images.ic_news}
                        resizeMode={'cover'}
                        borderRadius={5}
                        style={{
                            borderRadius: THUMB_SIZE / 2,
                            backgroundColor: '#fff',
                            height: THUMB_SIZE,
                            width: THUMB_SIZE,
                            marginEnd: 16
                        }}
                    />
                    <Col style={{flex: 1}}>
                        <Row>
                            <ADText
                                bold
                                numberOfLines={2}
                                style={styles.title}>
                                {item.title}
                            </ADText>
                        </Row>


                        <Row style={{alignItems: 'center', marginTop: 8}}>
                            {
                                item.view_count !== undefined &&
                                <ADIcon
                                    style={{marginEnd: 2}}
                                    icon={'remove-red-eye'}
                                    size={ADResponsiveUtil.plus(12, 2)}
                                    color={'#9e9e9e'}
                                />
                            }
                            {
                                item.view_count !== undefined &&
                                <ADText style={styles.viewCount}>
                                    {item.view_count}
                                </ADText>
                            }
                            {
                                item.likes_count !== undefined &&
                                <ADIcon
                                    style={{marginEnd: 2, marginStart: 8}}
                                    icon={'thumb-up'}
                                    size={ADResponsiveUtil.plus(10, 2)}
                                    color={item.liked ? DPConfig.colors.accentColor : '#9e9e9e'}
                                />
                            }
                            {
                                item.likes_count !== undefined &&
                                <ADText style={[styles.likeCount, {color: item.liked ? DPConfig.colors.accentColor : '#9e9e9e'}]}>
                                    {item.likes_count}
                                </ADText>
                            }
                            {
                                item.comments_count !== undefined &&
                                <ADIcon
                                    style={{marginStart: 8, marginEnd: 2}}
                                    icon={'comment'}
                                    size={ADResponsiveUtil.plus(10, 2)}
                                    color={'#9e9e9e'}
                                />
                            }
                            {
                                item.comments_count !== undefined &&
                                <ADText style={styles.commentsCount}>
                                    {item.comments_count}
                                </ADText>
                            }
                        </Row>
                    </Col>
                    <Col style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginStart: 16,
                        minWidth: 56
                    }}>
                        <Row>
                            <ADText
                                fontSize={12}
                                color={'#9e9e9e'}
                                title={date.isValid() ? date.format('MMMM') : ''}/>
                        </Row>
                        <Row style={styles.dateCircle}>
                            <ADText
                                style={{
                                    fontSize: 12,
                                    lineHeight: 30,
                                    marginTop: 2,
                                    textAlign: 'center',
                                    textVerticalAlign: 'center'
                                }}
                                color={
                                    item.read ?
                                        '#9e9e9e' :
                                        DPConfig.colors.primaryColor
                                }
                                title={date.isValid() ? date.format('DD') : undefined}/>
                        </Row>
                    </Col>
                </Row>
            </ADTouchable>
        )
    }
}

const styles = DPStyleSheet.create(({styles, dimens}) => ({
    container: {
        ...styles.card,
        flex: 1,
        padding: 16,
        marginTop: 8,
        marginHorizontal: dimens.screenHorizontalPadding
    },

    title: {
        fontSize: ADResponsiveUtil.plus(14, 2),
        lineHeight: ADResponsiveUtil.plus(14, 2) * 1.8,
        textAlign: 'left',
        ...styles.growWidth
    },
    description: {
        fontSize: ADResponsiveUtil.plus(12, 2),
        lineHeight: ADResponsiveUtil.plus(12, 2) * 1.8,
        color: '#9e9e9e',
        textAlign: 'left',
        ...styles.growWidth
    },
    date: {
        fontSize: ADResponsiveUtil.plus(10, 2),
        color: '#9e9e9e',
        textAlign: 'left',
        flexGrow: 1
    },
    likeCount: {
        fontSize: ADResponsiveUtil.plus(10, 2),
        color: '#9e9e9e',
        textAlign: 'left'
    },
    commentsCount: {
        fontSize: ADResponsiveUtil.plus(10, 2),
        color: '#9e9e9e',
        textAlign: 'left'
    },
    viewCount: {
        fontSize: ADResponsiveUtil.plus(10, 2),
        color: '#9e9e9e',
        textAlign: 'left'
    },
    dateCircle: {
        width: 32,
        height: 32,
        backgroundColor: '#f1f1f1',
        borderRadius: 16,
        marginTop: 8,
        alignItems: 'center',
        justifyContent: 'center'
    }
}))