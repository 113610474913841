import * as React from 'react';
import {inject, observer} from 'mobx-react';
import autobind from 'autobind-decorator';
import {
    DPAnimatedSwitchInput,
    ADButton,
    ADForm,
    ADFormStore,
    ADIcon, ADImage,
    ADRemoteView,
    ADScreen,
    ADSelectInput,
    ADText,
    AlertDialog,
    Col,
    DPCheckBoxInput,
    DPConfig,
    DPScrollView,
    DPStyleSheet,
    INavigationService,
    InjectedNavigationServiceProps,
    Row
} from '@dp/react-native-core';
import InjectedApiServiceProps from 'app/api/InjectedApiServiceProps';
import {SettingModel, UpdateSettingModel} from 'app/api/Models';
import {AppToolbar} from '../../common/AppToolbar';
import {LayoutAnimation, Platform, TouchableWithoutFeedback, View} from 'react-native';
import {View as AnimatedView} from 'react-native-animatable';
import Assets from '../../../assets/Assets';
import {i18n} from '../../../i18n/i18n';

type Params = {
    // id: IDType
}

type SettingScreenProps =
    Params &
    InjectedNavigationServiceProps &
    InjectedApiServiceProps

type State = {}

@inject('$navigation', '$api')
@observer
export default class SettingScreen extends React.Component<SettingScreenProps, State> {
    static RouteName = 'SettingScreen'

    static start(nav: INavigationService, param?: Params) {
        nav.navigate(SettingScreen.RouteName, param)
    }

    protected form = new ADFormStore<UpdateSettingModel>();
    protected api = DPConfig.http.createResource<{}, SettingModel>((p) => {
        return this.props.$api.getSettingInfo({...p})
            .onSuccess((response) => {
                this.form.clear();
                this.form.setStaticData('service', response.data.service_items);
                this.form.setData('service', response.data.service);

                this.form.setStaticData('area', response.data.area_items);
                this.form.setData('area', response.data.area);

                this.form.setData('active', response.data.active);
            })
    });

    render() {
        let data: SettingModel = this.api.data;
        let trans = i18n.strings.screens.setting
        return (
            <ADScreen>
                <ADImage
                    style={styles.back}
                    resizeMode={'contain'}
                    source={Assets.images.setting_background}/>
                <AppToolbar
                    transparent
                    theme={'light'}
                    title={trans.title}/>
                <ADForm store={this.form}>
                    <ADRemoteView api={this.api} autoLoad>
                        <DPScrollView api={this.api}>
                            <Col style={styles.form}>
                                <Row style={styles.section}>
                                    <ADSelectInput
                                        label={trans.service}
                                        $id={'service'}
                                        pickerType={'dropdown'}
                                        clearEnabled={false}
                                        style={{flex: 1}}
                                    />
                                </Row>
                                {
                                    !!data.area_items &&
                                    <Col style={[styles.section, styles.card]}>
                                        <Row style={styles.sectionHeader}>
                                            <ADIcon
                                                style={{marginEnd: 8}}
                                                icon={'radio-button-checked'}
                                                color={DPConfig.colors.secondaryColor}
                                                size={18}
                                            />

                                            <ADText
                                                fontSize={16}
                                                title={trans.area}
                                            />
                                            <AnimatedView
                                                animation={'zoomIn'}
                                                useNativeDriver
                                                duration={170}
                                                style={styles.sectionHeaderUnderLine}/>
                                        </Row>

                                        {
                                            (data.area_items || []).map((area, index) => (
                                                <AnimatedView
                                                    animation={'zoomIn'}
                                                    duration={300}
                                                    useNativeDriver
                                                    delay={index * 150}
                                                >
                                                    <DPCheckBoxInput
                                                        key={index}
                                                        $id={'area'}
                                                        $groupValue={area.id}
                                                        multiSelect
                                                        labelStyle={Platform.OS === 'ios' && {
                                                            paddingTop: 4
                                                        }}
                                                        label={area.title}
                                                        iconOn={Platform.OS === 'ios' ? 'check' : {
                                                            icon: 'check-box-outline',
                                                            type: ADIcon.Types.MaterialCommunityIcons
                                                        }}
                                                        labelStyleOn={{
                                                            color: DPConfig.colors.accentColor
                                                        }}
                                                    />
                                                </AnimatedView>
                                            ))
                                        }
                                    </Col>
                                }
                                <TouchableWithoutFeedback onPress={() => {
                                    LayoutAnimation.configureNext(LayoutAnimation.Presets.spring)
                                    this.form.setData('active', !this.form.data.get('active'))
                                }}>
                                    <Row style={[
                                        styles.section,
                                        styles.card,
                                        {alignItems: 'center'}
                                    ]}>
                                        <ADIcon
                                            style={{marginEnd: 8}}
                                            icon={'radio-button-checked'}
                                            color={DPConfig.colors.secondaryColor}
                                            size={18}
                                        />
                                        <ADText
                                            fontSize={16}
                                            title={trans.status.title}
                                        />
                                        <View style={{flexGrow: 1}}/>
                                        <DPAnimatedSwitchInput
                                            $id={'active'}
                                            labelOn={trans.status.active}
                                            labelOff={trans.status.inactive}
                                            thumbStyleOn={{
                                                backgroundColor: DPConfig.colors.secondaryColor
                                            }}
                                            style={{marginStart: 8, borderWidth: 1, borderColor: '#d1d1d1'}}
                                        />
                                    </Row>
                                </TouchableWithoutFeedback>
                            </Col>
                            <Row style={styles.updateBox}>
                                <ADButton
                                    secondary
                                    filled
                                    block
                                    medium
                                    onSubmit={this.handleSubmit}
                                    title={trans.update}
                                />
                            </Row>
                        </DPScrollView>
                    </ADRemoteView>
                </ADForm>
            </ADScreen>
        )
    }

    @autobind
    handleSubmit(data) {
        let trans = i18n.strings.screens.setting.update_success;
        this.props.$api.updateSetting(data)
            .onSuccess((response) => {
                this.api.onSuccess(response, this.api.lastCall);
                new AlertDialog.Builder()
                    .setTitle(trans.title)
                    .setMessage(response.message || trans.message)
                    .setPositiveButton(trans.ok)
                    .create()
                    .show()
            })
            .defaultLoading()
            .defaultError()
            .enqueue()
    }
}

const styles = DPStyleSheet.create(({dimens, styles}) => ({
    back: {
        position: 'absolute',
        end: -DPConfig.dimens.screen.width * .1,
        bottom: -8,
        opacity: .2,
        width: DPConfig.dimens.screen.width * .5,
        height: DPConfig.dimens.screen.width * .5 / 389 * 392,
    },
    sectionHeader: {
        alignItems: 'center',
        borderBottomWidth: 2,
        borderBottomColor: '#dedede',
        paddingBottom: 8,
    },
    form: {
        paddingVertical: dimens.screenVerticalPadding,
        flexGrow: 1,
        alignItems: 'stretch',
    },
    section: {
        paddingHorizontal: dimens.screenHorizontalPadding,
        paddingVertical: 16,
        backgroundColor: '#fff',
        marginBottom: 16
    },

    card: {
        ...styles.boxShadowBottom,
        borderRadius: 3,
        marginHorizontal: dimens.screenHorizontalPadding
    },

    sectionHeaderUnderLine: {
        position: 'absolute',
        start: 0,
        bottom: -2,
        width: 75,
        height: 3,
        backgroundColor: DPConfig.colors.accentColor
    },
    updateBox: {
        marginHorizontal: DPConfig.dimens.screenHorizontalPadding,
        marginTop: 16,
        marginBottom: DPConfig.dimens.safeAreaInsets.bottom +
            DPConfig.dimens.screenVerticalPadding
    }
}));
