import * as React from 'react';
import {EventModel} from 'app/api/Models';
import {
    ADImage,
    ADResponsiveUtil,
    ADText,
    Col,
    DPColorUtil,
    DPConfig,
    DPStyleSheet,
    Row,
    DPPlaceholder, DPInject, InjectedNavigationServiceProps, TextUtil, ADTouchable
} from '@dp/react-native-core';
import Carousel, {Pagination} from 'react-native-snap-carousel';
import {I18nManager, Linking, Platform, TouchableWithoutFeedback, View} from 'react-native';
import autobind from 'autobind-decorator';
import Assets from 'app/assets/Assets';
import NewsDetailsScreen from '../news/news-details/NewsDetailsScreen';

function Dot(p: { index?, carouselRef }) {
    return (
        <ADTouchable
            circle
            onPress={() => {
                p.carouselRef&&
                p.carouselRef._snapToItem(p.carouselRef._getPositionIndex(p.index));
            }}
            hitSlop={{top: 8, bottom: 8, left: 4, right: 4}}>
            <View style={{
                width: 24,
                height: 12,
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <View style={{
                    backgroundColor: DPConfig.colors.secondaryColor,
                    width: 4,
                    height: 4,
                    borderRadius: 2
                }}/>
            </View>
        </ADTouchable>
    )
}

@DPInject('$navigation')
export default class EventSlider extends React.Component <{ events?: Array<EventModel> } & Partial<InjectedNavigationServiceProps>> {
    state = {
        activeIndex: undefined
    };
    eventSliderRef;
    lineHeight = ADResponsiveUtil.plus(10, 2) * 2.2;
    numberOfLines = ADResponsiveUtil.dimen(1, 2, 3, 3);

    get height() {
        return this.lineHeight * (this.numberOfLines + 1) + 32
    }

    render() {
        let events: Array<EventModel> = this.props.events || [];

        if (events.length === 0) return false;
        let currentActiveIndex = this.state.activeIndex
        return (
            <Col>
                <Carousel
                    layout={'default'}
                    autoplay
                    loop={false}
                    autoplayInterval={2000}
                    autoplayDelay={1000}
                    ref={(ref) => this.eventSliderRef = ref}
                    useScrollView={true}
                    sliderHeight={this.height}
                    firstItem={events.length - 1}
                    sliderWidth={DPConfig.dimens.screen.width}
                    itemWidth={DPConfig.dimens.screen.width}
                    onBeforeSnapToItem={activeIndex => this.setState({activeIndex: activeIndex})}
                    data={events}
                    enableMomentum={true}
                    renderItem={this.renderItem}
                />

                <Pagination
                    dotsLength={events.length}
                    activeDotIndex={
                        Platform.select({
                            android: currentActiveIndex !== undefined ? events.length - 1 - currentActiveIndex : 0,
                            default: currentActiveIndex !== undefined ? currentActiveIndex : (events.length - 1)
                        })
                    }
                    carouselRef={this.eventSliderRef}
                    containerStyle={{
                        paddingVertical: 8,
                    }}
                    inactiveDotElement={<Dot carouselRef={this.eventSliderRef}/>}
                    dotElement={
                        <View style={{
                            borderRadius: 6,
                            borderWidth: 1,
                            borderColor: DPConfig.colors.secondaryColor,
                            width: 12,
                            height: 12,
                            marginHorizontal: 6,
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <View style={{
                                backgroundColor: DPConfig.colors.secondaryColor,
                                width: 4,
                                height: 4,
                                borderRadius: 2
                            }}/>
                        </View>
                    }
                    inactiveDotOpacity={0.4}
                    inactiveDotScale={0.6}
                />
            </Col>
        )
    }

    @autobind
    protected handleOnPress(item: EventModel) {
        if (item.url)
            Linking.openURL(item.url)
                .catch(console.warn)
        else if (item.id && this.props.$navigation) {
            NewsDetailsScreen.start(this.props.$navigation, {newsId: item.id})
        }
    }

    @autobind
    renderItem({item, index}: { index: number, item: EventModel }) {
        return (
            <TouchableWithoutFeedback
                key={index}
                onPress={() => this.handleOnPress(item)}>
                <Row style={{
                    width: ADResponsiveUtil.width,
                    // flex: 1,
                    alignItems: 'stretch',
                    direction: 'rtl',
                    paddingHorizontal: ADResponsiveUtil.screenHorizontalPadding,
                }}
                >
                    <Col style={{justifyContent: 'center', marginEnd: 16}}>
                        <DPPlaceholder.ADImage
                            source={
                                !!item.main_image_url ?
                                    {uri: item.main_image_url} :
                                    Assets.images.logo
                            }
                            style={{
                                borderRadius: 3,
                            }}
                            size={Math.min(this.height, 64)}/>

                    </Col>
                    <Col style={{flex: 1, alignItems: 'stretch'}}>
                        <DPPlaceholder.Row>
                            <Row style={{marginEnd: 8}}>
                                <ADText
                                    color={'#727272'}
                                    title={item.title}
                                    fontSize={12}
                                    textAlign={'start'}
                                    bold
                                    numberOfLines={1}
                                    style={{
                                        lineHeight: this.lineHeight,
                                        flexGrow: 1,
                                        // width: '100%',
                                    }}
                                />
                            </Row>
                            <Row style={sliderStyles.sliderDateBox}>
                                <ADText
                                    numberOfLines={1}
                                    fontSize={10}
                                    color={'#727272'}
                                    title={item.jalali_date_at}/>
                            </Row>
                        </DPPlaceholder.Row>
                        <DPPlaceholder.Row style={{marginTop: 8}}>
                            <ADText
                                color={'#727272'}
                                title={TextUtil.stripHtmlTags(item.description)}
                                fontSize={12}
                                textAlign={'start'}
                                numberOfLines={this.numberOfLines}
                                style={{
                                    lineHeight: this.lineHeight,
                                    maxHeight: this.lineHeight * this.numberOfLines,
                                    width: '100%',
                                }}
                            />
                        </DPPlaceholder.Row>
                    </Col>
                </Row>
            </TouchableWithoutFeedback>
        )
    }
}

const sliderStyles = DPStyleSheet.create(({colors}) => ({

        sliderDateBox: {
            backgroundColor: DPColorUtil.fade(colors.secondaryColor, .8),
            borderColor: colors.secondaryColor,
            borderWidth: 1,
            borderRadius: 10,
            minHeight: 20,
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: 8,
        }
    }))
;