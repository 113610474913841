import * as React from 'react';
import {observer} from 'mobx-react';
import {Platform, ViewStyle} from 'react-native';
import autobind from 'autobind-decorator';
import TicketListItem from './TicketListItem';
import TicketDetailsScreen from '../conversation/TicketDetailsScreen';
import CreateTicketScreen from '../create/CreateTicketScreen';
import {ADFab, ADFlatList, Col, DPInject, DPStyleSheet, INavigationService, InjectedNavigationServiceProps} from '@dp/react-native-core';
import InjectedApiServiceProps from 'app/api/InjectedApiServiceProps';
import {TicketItemModel} from 'app/api/Models';
import AppToolbar from 'app/screens/common/AppToolbar';
import {i18n} from 'app/i18n/i18n';


type Params = {}

type TicketListScreenProps =
    Params &
    InjectedNavigationServiceProps &
    InjectedApiServiceProps

@DPInject('$navigation', '$api')
@observer
export default class TicketListScreen extends React.Component<TicketListScreenProps> {
    static RouteName = 'TicketListScreen'

    static start(nav: INavigationService) {
        nav.navigate(TicketListScreen.RouteName)
    }


    render() {
        return (
            <Col style={styles.container}>
                <AppToolbar theme={'dark'} title={i18n.strings.screens.ticket_list.title}/>
                <Col style={[{flex: 1}, Platform.OS === 'web' && {height: 0}]}>
                    <ADFlatList
                        resource={this.props.$api.ticketListApi}
                        autoLoad={true}
                        renderItem={this.renderItem}
                        ItemSeparatorComponent={null}
                        contentContainerStyle={{paddingBottom: 16}}
                    />
                </Col>
                <ADFab icon={'add'} onPress={this.handleCreatePress}/>
            </Col>
        )
    }

    @autobind
    protected renderItem(p: { item: TicketItemModel, index: number }) {
        return <TicketListItem item={p.item} onPress={this.handleItemPress}/>;
    }


    @autobind
    protected handleItemPress(item: TicketItemModel) {
        TicketDetailsScreen.start(this.props.$navigation, {
            ticketId: item.id
        })

    }

    @autobind
    protected handleCreatePress() {
        CreateTicketScreen.start(this.props.$navigation)
    }
}

const styles = DPStyleSheet.create(({colors}) => ({
    container: {
        flexGrow: 1,
        backgroundColor: colors.screenBackgroundColor
    } as ViewStyle,
}))