import {
  ADButton,
  ADFlatList,
  ADIcon,
  ADResource,
  Col,
  DPConfig,
  DPStyleSheet,
  DPTextInput,
  IDType,
  INavigationService,
  InjectedNavigationServiceProps,
  Row,
  ADText,
  ADResponsiveUtil,
} from "@dp/react-native-core";
import { InjectedKeyboardServiceProps } from "@dp/react-native-core/dist/dpcoresrc/services/InjectedServiceProps";
import InjectedApiServiceProps from "app/api/InjectedApiServiceProps";
import {
  ProjectCommentItemModel,
  ProjectDetailsModel,
  AdviceFile,
} from "app/api/Models";
import { i18n } from "app/i18n/i18n";
import AppToolbar from "app/screens/common/AppToolbar";
import autobind from "autobind-decorator";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { InteractionManager, Platform, View, ViewStyle } from "react-native";
import FilesItem from "./FilesItem";
import DPDefaultStyles from "@dp/react-native-core/dist/dpcoresrc/config/DPDefaultStyles";

type Params = {
  projectDetailsApi: ADResource<any, any, ProjectDetailsModel>;
  projectId: IDType;
};

type TicketDetailsScreenProps = Params &
  InjectedNavigationServiceProps &
  InjectedKeyboardServiceProps;

@inject("$navigation", "$keyboard")
@observer
export default class FilesScreen extends React.Component<
  TicketDetailsScreenProps,
  { text: string }
> {
  static RouteName = "FilesScreen";

  static start(nav: INavigationService, p: Params) {
    nav.navigate(FilesScreen.RouteName, p);
  }

  render() {
    let trans = i18n.strings.screens.project_files;
    let projectDetailsApi = this.props.projectDetailsApi;
    let tech_files: AdviceFile[] = [];
    let sale_files: AdviceFile[] = [];
    if (projectDetailsApi.data.advice) {
      const advice = projectDetailsApi.data.advice;
      tech_files = advice.tech.files || [];
      sale_files = advice.sale.files || [];
    }
    return (
      <Col style={styles.container}>
        <AppToolbar theme={"dark"} title={trans.title} />

        <Col style={[{ flex: 1 }, Platform.OS === "web" && { height: 0 }]}>
          {tech_files.length > 0 && (
            <>
              <ADText style={styles.filesHeader} bold>
                {trans.tech_files}
              </ADText>
              <Row>
                <ADFlatList
                  onRefresh={() => projectDetailsApi.reload()}
                  refreshing={projectDetailsApi.isLoading}
                  data={
                    Platform.OS == "web" ? tech_files.reverse() : tech_files
                  }
                  renderItem={this.renderItem}
                  ItemSeparatorComponent={null}
                  inverted={Platform.OS !== "web"}
                  contentContainerStyle={{ paddingBottom: 16, flexGrow: 0 }}
                />
                {Platform.OS === "ios" && (
                  <View style={{ minHeight: this.props.$keyboard.height }} />
                )}
              </Row>
            </>
          )}
          {sale_files.length > 0 && (
            <>
              <ADText style={styles.filesHeader} bold>
                {trans.sale_files}
              </ADText>
              <Row>
                <ADFlatList
                  onRefresh={() => projectDetailsApi.reload()}
                  refreshing={projectDetailsApi.isLoading}
                  data={
                    Platform.OS == "web" ? sale_files.reverse() : sale_files
                  }
                  renderItem={this.renderItem}
                  ItemSeparatorComponent={null}
                  inverted={Platform.OS !== "web"}
                  contentContainerStyle={{ paddingBottom: 16, flexGrow: 0 }}
                />
                {Platform.OS === "ios" && (
                  <View style={{ minHeight: this.props.$keyboard.height }} />
                )}
              </Row>
            </>
          )}
        </Col>
      </Col>
    );
  }

  @autobind
  protected renderItem(p: { item: AdviceFile; index: number }) {
    return <FilesItem item={p.item} />;
  }
}

const styles = DPStyleSheet.create(({ colors }) => ({
  container: {
    flex: 1,
    backgroundColor: colors.screenBackgroundColor,
  } as ViewStyle,
  filesHeader: {
    marginHorizontal: DPConfig.dimens.screenHorizontalPadding,
    marginTop: 8,
    fontSize: ADResponsiveUtil.plus(14, 2),
  },
}));
